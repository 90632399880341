import A1_CONSTANTS from './_const'

const checkStatusEqualOrMoreThan = ({
  statusCurrent,
  statusCheck,
}: {
  statusCurrent: string
  statusCheck: string
}) => {
  const arrStatus = Object.values(A1_CONSTANTS.OPTIONS_JOURNEY_STATUS).flatMap(
    (status) => status.value
  )
  const foundIndex = arrStatus.indexOf(statusCheck)
  if (foundIndex === -1) return false

  return arrStatus.slice(foundIndex).includes(statusCurrent)
}

export const A1Helpers = {
  checkStatusEqualOrMoreThan,
}
