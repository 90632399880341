import clsx from 'clsx'
import {getIn, useFormik} from 'formik'
import {cloneDeep, compact, find, isEmpty, isNull, omit, size} from 'lodash'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {OPTION_COUNTRIES, SERVICES} from '../../../../../_gori/constants'
import {convertUnit, convertUserTimeZone, KTSVG} from '../../../../../_gori/helpers'
import {useStatesGlobal} from '../../../../../_gori/helpers/components/StatesGlobalProvider'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../../_gori/hooks/UseYupValidate'
import {
  Button,
  InputTextFormik,
  SelectFormik,
  SelectMultiLevelFormik,
  ValidationErrorModal,
} from '../../../../../_gori/partials/widgets'
import displayConfig from '../../../../../displayconfig.json'
import {useAuth} from '../../../auth'
import {FromOrToModal, PackageType} from '../../../orders'
import {
  OPTION_DIMENSION_UNIT,
  OPTION_PACKAGE_TYPE,
  OPTION_WEIGHT_UNIT,
  OPTIONS_CONTENT_TYPE,
  OPTIONS_CONTENT_TYPE_UPS,
  OrdersConfig,
  OrderStatus,
} from '../../../orders/core/_const'
import A1BatchService from '../../core/_requests'

type Props = {
  show: boolean
  data: any
  handleClose: () => void
  handleSuccess: () => void
}

const EditOrderInfo: React.FC<Props> = ({show, data, handleClose, handleSuccess}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {isCancel, newCancelToken} = useCancelToken()
  const {orderYup, stringYup, numberYup, regexYup, infoYup} = UseYupValidate()
  const {statesGlobal, getPackages} = useStatesGlobal()
  const carriers = statesGlobal?.carriers
  const carriersPackage = statesGlobal?.carriersPackage
  const packages = statesGlobal.packages
  const row = useRef({parcels: 0, order_items: -1})
  const [fromToModal, setFormToModal] = useState<{
    show: boolean
    name: 'from' | 'to'
    data: any
    validate: any
  }>({
    show: false,
    name: 'to',
    data: {},
    validate: {},
  })
  const [loading, setLoading] = useState<{update: boolean}>({update: false})
  const [validationErrors, setValidationErrors] = useState<any>()

  const isDisabled = useMemo(() => {
    return data?.status !== OrderStatus.OPEN
  }, [data?.status])

  const validateAddress = useCallback(
    (name) => {
      const validate = {
        [`${name}_company`]: Yup.string().when([`${name}_first_name`], {
          is: (first_name) => !first_name || first_name.trim() === '',
          then: orderYup.companyFirstName('COMPANY'),
          otherwise: stringYup(255, 'COMPANY', false),
        }),
        [`${name}_first_name`]: Yup.string().when(`${name}_company`, {
          is: (company) => !company || company.trim() === '',
          then: orderYup.companyFirstName('FIRST_NAME'),
          otherwise: stringYup(255, 'FIRST_NAME', false),
        }),
        [`${name}_street1`]: stringYup(255, 'ADDRESS'),
        [`${name}_city`]: stringYup(255, 'CITY'),
        [`${name}_state`]: Yup.string().when(`${name}_country`, {
          is: (val) => val === 'US',
          then: regexYup.state(),
          otherwise: stringYup(255, 'STATE'),
        }),
        [`${name}_country`]: regexYup.country(),
        [`${name}_zip`]: Yup.string().when(`${name}_country`, {
          is: (val) => val === 'US',
          then: regexYup.zipCode(),
          otherwise: stringYup(255, 'POSTAL_CODE'),
        }),
        [`${name}_last_name`]: stringYup(255, 'LAST_NAME', false),
        [`${name}_street2`]: stringYup(255, 'ADDRESS_2', false),
        [`${name}_phone`]: infoYup.phone('PHONE', false),
        [`${name}_email`]: infoYup.email('EMAIL', false),
      }
      return validate
    },
    [orderYup, regexYup, stringYup, infoYup]
  )

  const orderItemsValidation = useMemo(() => {
    return Yup.object().shape({
      content: orderYup.orderA1.content,
      description: orderYup.orderA1.description,
      hs_tariff_number: orderYup.orderA1.htsCode(),
      country: orderYup.orderA1.country,
      weight: orderYup.orderA1.weight,
      quantity: orderYup.orderA1.quantity,
      value: orderYup.orderA1.value,
    })
  }, [orderYup.orderA1])

  const initialValues = useMemo(() => {
    return {
      to: {
        to_company: data?.to?.company || null,
        to_first_name: data?.to?.first_name || null,
        to_last_name: data?.to?.last_name || null,
        to_street1: data?.to?.street1 || null,
        to_street2: data?.to?.street2 || null,
        to_city: data?.to?.city || null,
        to_state: data?.to?.state || null,
        to_zip: data?.to?.zip || null,
        to_country: data?.to?.country || null,
        to_phone: data?.to?.phone || null,
        to_email: data?.to?.email || null,
        to_is_residential: data?.to?.is_residential || false,
      },
      service: data?.carrier || data?.service ? `${data?.carrier}_${data?.service}` : null,
      parcels: {
        0: {
          package_type: data?.parcels?.[0]?.package_type || '',
          package_id: data?.parcels?.[0]?.package_id || null,
          weight: data?.parcels?.[0]?.weight?.value || null,
          weight_unit: data?.parcels?.[0]?.weight?.unit || null,
          length: data?.parcels?.[0]?.dimensions?.length || null,
          width: data?.parcels?.[0]?.dimensions?.width || null,
          height: data?.parcels?.[0]?.dimensions?.height || null,
          dimension_unit: data?.parcels?.[0]?.dimensions?.unit || null,
        },
      },
      check_weight: false,
      container_id: data?.a1?.container_id || null,
      reference1: data?.reference_1 || null,
      reference2: data?.reference_2 || null,
      order_items: {},
    }
  }, [data])

  const initValidateSchema = useMemo(
    () => ({
      to: Yup.object().shape(validateAddress('to'), [['to_first_name', 'to_company']]),
      service: stringYup(255, 'SERVICE'),
      parcels: Yup.object().shape({
        [row.current.parcels]: Yup.object().shape(
          OrdersConfig.PARCEL.reduce((initParcel, currentName) => {
            switch (currentName) {
              case 'length':
              case 'width':
              case 'height':
              case 'weight':
                initParcel[currentName] = numberYup.parcel(currentName)
                break
              default:
                initParcel[currentName] = stringYup(50, 'UNIT')
                break
            }
            return initParcel
          }, {})
        ),
      }),
      check_weight: orderYup.checkIntlWeight,
      container_id: orderYup.orderA1.containerId(false),
      reference1: orderYup.reference('REF_1'),
      reference2: orderYup.reference('REF_2'),
      order_items: Yup.object(),
    }),
    [numberYup, orderYup, stringYup, validateAddress]
  )

  const [validateSchema, setValidateSchema] = useState<any>(initValidateSchema)

  const formik = useFormik({
    initialValues,
    enableReinitialize: false,
    validationSchema: Yup.object().shape(validateSchema),
    onSubmit: async (values) => {
      try {
        setLoading((prev) => ({...prev, update: true}))
        const config = {cancelToken: newCancelToken()}
        const payload = {
          ...omit(values, ['to', 'parcels', 'order_items', 'check_weight']),
          ...values?.to,
          ...values?.parcels?.[0],
          order_items: Object.values(values.order_items),
        }
        const res = await A1BatchService.updateOrderA1Batch(data?.id, payload, config)
        if (res) {
          toast.success(intl.formatMessage({id: res.message}))
          handleSuccess()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, update: false}))
      }
    },
  })

  const handleCloseFromToModal = () => {
    setFormToModal({
      show: false,
      name: 'to',
      data: {},
      validate: {},
    })
  }

  const handleSaveModal = (name, values) => {
    formik.setFieldValue(name, values)
    handleCloseFromToModal()
  }

  // BEGIN: Action Order Items
  useEffect(() => {
    if (isEmpty(data?.items)) return

    const initOrderItems = async () => {
      const orderItems = {}
      const orderItemsSchema = {}

      data?.items.forEach((item) => {
        row.current.order_items++
        orderItems[row.current.order_items] = {
          id: item?.id || null,
          content: item?.a1?.content || null,
          description: item?.a1?.description || null,
          hs_tariff_number: item?.a1?.hs_tariff_number || null,
          country: item?.a1?.country || null,
          weight: item?.a1?.weight || null,
          quantity: item?.a1?.quantity || null,
          value: Number(item?.a1?.value) || null,
        }
        orderItemsSchema[row.current.order_items] = orderItemsValidation
      })

      await formik.setFieldValue('order_items', {
        ...formik.values.order_items,
        ...orderItems,
      })

      await setValidateSchema((prev) => ({
        ...prev,
        order_items: Yup.object().shape({
          ...prev?.order_items?.fields,
          ...orderItemsSchema,
        }),
      }))
    }

    initOrderItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.initialValues])

  const handleAddOrderItem = async () => {
    row.current.order_items++
    const newItem = {
      id: null,
      content: '',
      description: '',
      hs_tariff_number: '',
      country: '',
      weight: '',
      quantity: '',
      value: '',
    }

    await formik.setFieldValue('order_items', {
      ...formik.values.order_items,
      [row.current.order_items]: newItem,
    })

    await setValidateSchema((prev) => {
      return {
        ...prev,
        order_items: Yup.object().shape({
          ...prev?.order_items?.fields,
          [row.current.order_items]: orderItemsValidation,
        }),
      }
    })
  }

  const handleDeleteOrderItem = async (key: string) => {
    await setValidateSchema((prev) => {
      const updatedFields = {...prev?.order_items?.fields}
      delete updatedFields[key]
      return {
        ...prev,
        order_items: Yup.object().shape(updatedFields),
      }
    })

    const updatedOrderItems = {...formik.values.order_items}
    delete updatedOrderItems[key]
    await formik.setFieldValue('order_items', updatedOrderItems)
  }
  // END: Action Order Items

  // BEGIN: Options content
  const contentTypeOptions = useMemo(() => {
    const {service, order_items} = formik.values

    if (service === SERVICES.UPS_MI) {
      const orderItems = Object.values(order_items).reduce((acc: any, item: any, idx) => {
        const found = find(OPTIONS_CONTENT_TYPE_UPS, {value: item.content})
        acc[idx] = {
          ...item,
          content: found?.value || null,
        }
        return acc
      }, {})

      if (!isEmpty(formik.values.order_items)) {
        formik.setFieldValue('order_items', orderItems)
      }

      return OPTIONS_CONTENT_TYPE_UPS
    }

    return OPTIONS_CONTENT_TYPE
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.service])

  // END: Options content

  // BEGIN: Ship To
  const shipToShow = useMemo(() => {
    const {to_street1, to_street2, to_city, to_state, to_zip} = formik.values.to
    const addressTo = compact([
      to_street1,
      to_street2,
      to_city,
      to_state && to_zip ? `${to_state} ${to_zip}` : to_state || to_zip,
    ]).join(', ')
    return addressTo
  }, [formik.values.to])

  const checkValidateFailed = useMemo(() => {
    if (!formik.values.to.to_company && !formik.values.to.to_first_name) {
      return true
    }
    const validate = ['to_city', 'to_state', 'to_country', 'to_zip', 'to_street1']
    return validate.some((item) => !formik.values.to[item])
  }, [formik.values])
  // END: Ship To

  // BEGIN: Options service
  const optionsService = useMemo(() => {
    if (isEmpty(carriers)) return
    const domesticCarriers = Object.values(cloneDeep(carriers)).filter(
      (carrier: any) => !carrier?.international && carrier?.status
    )

    return [
      {
        label: intl.formatMessage({id: 'DOMESTIC'}),
        options: domesticCarriers.map((carrier: any) => ({
          label: carrier?.visuals?.display_name,
          value: carrier?.carrier_service,
        })),
      },
    ]
  }, [carriers, intl])
  // END: Options service

  // BEGIN: Options package
  useEffect(() => {
    getPackages()

    return () => {}
  }, [getPackages])

  const optionsCustomPackage = useMemo(() => {
    if (!packages) return [OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE]

    const customPackages = packages.map((item) => ({
      label: item.name,
      value: {
        length: item.length,
        width: item.width,
        height: item.height,
        dimension_unit: item.dimension_unit,
        package_id: item.id,
        package_type: '',
      },
    }))

    return {
      label: OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE.label,
      options: customPackages,
    }
  }, [packages])

  const optionsPackageTypes = useMemo(() => {
    if (
      isEmpty(carriers) ||
      isEmpty(carriersPackage) ||
      isNull(formik.values?.service) ||
      isEmpty(carriers?.[formik.values.service]?.package_types)
    ) {
      if (!isEmpty(formik.values?.parcels?.[0]?.package_type)) {
        formik.setFieldValue('parcels.0.package_type', '')
      }
      return []
    }

    return carriersPackage?.map((parent) => ({
      label: parent?.label,
      options: parent?.value?.map((item: any) => ({
        label: item.label,
        value: {
          length: item.parcel.length,
          width: item.parcel.width,
          height: item.parcel.height,
          dimension_unit: item.parcel.dimension_unit,
          weight_limit: item.parcel.weight_limit,
          package_type: item.value,
          package_id: null,
        },
      })),
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.service])

  const optionPackages = useMemo(() => {
    return [
      OPTION_PACKAGE_TYPE.INPUT_DIMENSION,
      ...cloneDeep(optionsPackageTypes),
      optionsCustomPackage,
    ].filter((val) => !isEmpty(val))
  }, [optionsCustomPackage, optionsPackageTypes])
  // END: Options service and package

  // BEGIN: validate Total Weight
  const totalWeightPackage = useMemo(
    () =>
      Object.values(formik.values.parcels).reduce(
        (sum: number, parcel: any) => sum + (parseFloat(parcel?.weight) || 0),
        0
      ),
    [formik.values.parcels]
  )

  const totalWeightCustoms = useMemo(
    () =>
      Object.values(formik.values.order_items).reduce(
        (sum: number, item: any) => sum + (parseFloat(item?.weight) || 0),
        0
      ),
    [formik.values.order_items]
  )

  useEffect(() => {
    if (!formik.values.order_items) return

    const weightExceeded =
      convertUnit(
        {unit: 'kg', value: totalWeightCustoms},
        {unit: formik.values.parcels?.[0]?.weight_unit}
      ) > totalWeightPackage

    formik.setFieldValue('check_weight', weightExceeded)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.order_items,
    formik.values.parcels,
    totalWeightCustoms,
    totalWeightPackage,
    convertUnit,
  ])
  // END: validate Total Weight

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      {fromToModal.show && fromToModal.name === 'to' && (
        <FromOrToModal
          data={fromToModal}
          handleSave={handleSaveModal}
          handleClose={handleCloseFromToModal}
          labelModal={intl.formatMessage({id: 'SHIPPING_ADDRESS'})}
          disabled={isDisabled}
        />
      )}
      <Modal
        id='gori_modal_edit_order_info'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-800px h-auto'
        show={show && !fromToModal.show}
        backdrop='static'
        onHide={handleClose}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'EDIT_ORDER_INFORMATION'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={clsx('vh-75 scroll-y', {'cursor-no-drop': isDisabled})}>
            <div className='d-flex flex-column gap-4 px-10 px-md-4'>
              <div className='d-flex flex-column flex-md-row col-12'>
                <div className='col-12 col-md-6'>
                  <label className='form-label col-3 col-md-6'>
                    {intl.formatMessage({id: 'DATE'})}
                  </label>
                  <span className='col-8 text-dark fw-bolder'>
                    {convertUserTimeZone(
                      data?.created_at,
                      currentUser,
                      process.env.REACT_APP_DATE_FORMAT
                    )}
                  </span>
                </div>
                <div className='col-12 col-md-6'>
                  <label className='form-label col-3'>{intl.formatMessage({id: 'BAE_ID'})}</label>
                  <span className='col-8 text-dark fw-bolder'>{data?.bae_id}</span>
                </div>
              </div>
              <div className='d-flex flex-column'>
                <div className='d-flex'>
                  <label className='form-label col-form-label required col-3'>
                    {intl.formatMessage({id: 'SHIP_TO'})}
                  </label>
                  <div className='input-custom col-6'>
                    <input
                      value={shipToShow}
                      className={clsx('form-control input-custom__input cursor-pointer', {
                        'is-invalid': getIn(formik.touched, 'to') && checkValidateFailed,
                        'is-valid': getIn(formik.touched, 'to') && !checkValidateFailed,
                      })}
                      onClick={() => {
                        setFormToModal({
                          show: true,
                          name: 'to',
                          data: formik.getFieldProps('to').value,
                          validate: validateAddress('to'),
                        })
                      }}
                    />
                  </div>
                  <div className='flex-fill d-flex align-items-center justify-content-center'>
                    <span
                      className='text-decoration-underline fw-bold text-primary cursor-pointer'
                      onClick={() => {
                        setFormToModal({
                          show: true,
                          name: 'to',
                          data: formik.getFieldProps('to').value,
                          validate: validateAddress('to'),
                        })
                      }}
                    >
                      {intl.formatMessage({id: 'EDIT'})}
                    </span>
                  </div>
                </div>
                <div className='d-flex mt-1'>
                  <div className='col-3' />
                  {checkValidateFailed && getIn(formik.touched, 'to') && (
                    <span className='text-danger fs-7'>
                      {intl.formatMessage(
                        {id: 'INPUT_IS_REQUIRED'},
                        {input: intl.formatMessage({id: 'SHIP_TO'})}
                      )}
                    </span>
                  )}
                </div>
              </div>
              <div className={clsx('d-flex flex-column gap-4', {'pe-none': isDisabled})}>
                <div className='d-flex col-12'>
                  <SelectMultiLevelFormik
                    className='col-6'
                    labelClassName='col-3 col-form-label'
                    label={intl.formatMessage({id: 'SERVICE'})}
                    options={optionsService || [{label: '', options: []}]}
                    formik={formik}
                    name='service'
                    required
                    emptyDefault={false}
                  />
                </div>
                <div className='d-flex flex-column flex-md-row col-12'>
                  <PackageType
                    data={optionPackages}
                    className={'col-6'}
                    labelClassName='col-12 col-md-3 col-form-label'
                    textLabel={intl.formatMessage({id: 'PACKAGE_INFO'})}
                    formik={formik}
                    name='parcels.0.package_type'
                    placeholder={intl.formatMessage({id: 'PACKAGE_TYPE'})}
                    required
                  />
                </div>
                <div className='d-flex col-12'>
                  <div className='col-0 col-md-3' />
                  <div className='flex-fill'>
                    {Object.entries(formik.getFieldProps('parcels').value).map(
                      ([keyItem, valueItem]: any, index) => {
                        const packageType = formik.values.parcels[0].package_type

                        return (
                          <div key={index}>
                            <div className='row'>
                              <div className='col-12 d-flex flex-wrap'>
                                {!isEmpty(packageType) ? (
                                  <div className='fs-5 fw-bold text-muted'>
                                    {intl.formatMessage({id: 'DIMENSIONS'})}:{' '}
                                    {`${valueItem?.length} x ${valueItem?.width} x ${valueItem?.height} ${valueItem?.dimension_unit}`}
                                  </div>
                                ) : (
                                  <>
                                    {['length', 'width', 'height'].map((item, index) => {
                                      return (
                                        <div className='col-6 col-sm-3' key={index}>
                                          <InputTextFormik
                                            type='number'
                                            min={0}
                                            className={'me-2'}
                                            labelClassName={'text-muted'}
                                            label={item.charAt(0).toUpperCase()}
                                            formik={formik}
                                            name={`parcels.${keyItem}.${item}`}
                                          />
                                        </div>
                                      )
                                    })}
                                    <div className='col-6 col-sm-3'>
                                      <SelectFormik
                                        placeholder=''
                                        label={intl.formatMessage({id: 'UNIT'})}
                                        labelClassName={'text-muted'}
                                        options={OPTION_DIMENSION_UNIT}
                                        name={`parcels.${keyItem}.dimension_unit`}
                                        formik={formik}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className='col-12 d-flex flex-wrap mt-3'>
                                <div className='col-6 col-sm-3' key={index}>
                                  <InputTextFormik
                                    type='number'
                                    min={0}
                                    className={'me-2'}
                                    labelClassName={'text-muted'}
                                    label={intl.formatMessage({id: 'WEIGHT'})}
                                    formik={formik}
                                    name={`parcels.${keyItem}.weight`}
                                  />
                                </div>
                                <div className='col-6 col-sm-3'>
                                  <SelectFormik
                                    label={intl.formatMessage({id: 'UNIT'})}
                                    placeholder=''
                                    labelClassName={'text-muted'}
                                    options={OPTION_WEIGHT_UNIT}
                                    name={`parcels.${keyItem}.weight_unit`}
                                    formik={formik}
                                  />
                                </div>
                                <div className='col-12 col-md-6 d-flex flex-column justify-content-end align-items-end'>
                                  <div className='d-flex align-items-center text-muted mt-3'>
                                    {intl.formatMessage({id: 'TOTAL_WEIGHT'})}:
                                    <span className='text-dark fs-1 mx-2'>
                                      {totalWeightPackage}
                                    </span>{' '}
                                    {formik?.values?.parcels[0]?.weight_unit?.toUpperCase()}
                                  </div>
                                  {formik.errors?.check_weight && (
                                    <div className='text-danger d-flex justify-content-end ps-10'>
                                      {formik.errors?.check_weight}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    )}
                  </div>
                </div>
                <div className='d-flex col-12 col-md-6'>
                  <InputTextFormik
                    className='col-6'
                    labelClassName='col-6 col-form-label'
                    label={intl.formatMessage({id: 'CONTAINER_ID'})}
                    formik={formik}
                    name={'container_id'}
                  />
                </div>
                <div className='d-flex flex-column flex-md-row col-12 gap-4'>
                  <div className='d-flex col-12 col-md-6'>
                    <InputTextFormik
                      labelClassName='col-6 col-form-label'
                      label={intl.formatMessage({id: 'REF_1'})}
                      formik={formik}
                      name={'reference1'}
                    />
                  </div>
                  <div className='d-flex col-12 col-md-6'>
                    <InputTextFormik
                      labelClassName='col-6 col-form-label text-start text-md-center'
                      label={intl.formatMessage({id: 'REF_2'})}
                      formik={formik}
                      name={'reference2'}
                    />
                  </div>
                </div>
                <hr className='text-gray-700' />
                <div>
                  {!isEmpty(formik?.values?.order_items) &&
                    Object.entries(formik?.values?.order_items).map(([key, value], index) => (
                      <div
                        className='bg-light rounded-2 p-5 mb-6 d-flex flex-column gap-4'
                        key={index}
                      >
                        <div className='d-flex justify-content-between align-items-center fs-5 fw-bolder'>
                          {intl.formatMessage({id: 'ITEM'})} {index + 1}
                          {displayConfig.a1.addDeleteItem && (
                            <div
                              className={clsx('text-hover-danger cursor-pointer', {
                                'd-none': size(formik.values.order_items) <= 1,
                              })}
                              onClick={() => handleDeleteOrderItem(key)}
                            >
                              <KTSVG path='/media/gori/orders/empty.svg' small={false} />
                            </div>
                          )}
                        </div>
                        <div className='d-flex'>
                          <SelectFormik
                            className='col-6'
                            labelClassName='col-6 col-md-3 col-form-label'
                            label={intl.formatMessage({id: 'CONTENT'})}
                            formik={formik}
                            options={contentTypeOptions}
                            required
                            name={`order_items.${key}.content`}
                            hasUseIntl={true}
                            emptyDefault={false}
                          />
                        </div>
                        <div className='d-flex'>
                          <InputTextFormik
                            className='col-6'
                            labelClassName='col-6 col-md-3 col-form-label'
                            label={intl.formatMessage({id: 'DESCRIPTION'})}
                            formik={formik}
                            required
                            name={`order_items.${key}.description`}
                          />
                        </div>
                        <div className='d-flex'>
                          <InputTextFormik
                            className='col-6'
                            labelClassName='col-6 col-md-3 col-form-label'
                            label={intl.formatMessage({id: 'HTS_CODE'})}
                            formik={formik}
                            required
                            name={`order_items.${key}.hs_tariff_number`}
                          />
                        </div>
                        <div className='d-flex'>
                          <SelectFormik
                            className='col-6'
                            labelClassName='col-6 col-md-3 col-form-label'
                            label={intl.formatMessage({id: 'COUNTRY_OF_ORIGIN'})}
                            formik={formik}
                            options={OPTION_COUNTRIES}
                            required
                            name={`order_items.${key}.country`}
                            emptyDefault={false}
                          />
                        </div>
                        <div className='d-flex flex-column flex-md-row gap-4 gap-md-0'>
                          <div className='d-flex col-12 col-md-6'>
                            <InputTextFormik
                              className='col-6'
                              labelClassName='col-6 col-form-label'
                              label={intl.formatMessage({id: 'QUANTITY'})}
                              formik={formik}
                              required
                              type='number'
                              isInteger
                              name={`order_items.${key}.quantity`}
                            />
                          </div>
                          <div className='d-flex col-12 col-md-6'>
                            <InputTextFormik
                              className='col-6'
                              labelClassName='col-6 col-form-label text-start text-md-center'
                              label={`${intl.formatMessage({id: 'VALUE'})} ($)`}
                              formik={formik}
                              required
                              type='number'
                              name={`order_items.${key}.value`}
                            />
                          </div>
                        </div>
                        <div className='d-flex'>
                          <InputTextFormik
                            className='col-6 col-md-3'
                            labelClassName='col-6 col-md-3 col-form-label'
                            label={`${intl.formatMessage({id: 'WEIGHT'})} (Kg)`}
                            formik={formik}
                            required
                            name={`order_items.${key}.weight`}
                          />
                        </div>
                      </div>
                    ))}

                  {displayConfig.a1.addDeleteItem && (
                    <div
                      className='bg-light rounded-pill p-5 px-8 fs-5 fw-bold text-gray-700 text-hover-primary cursor-pointer'
                      onClick={handleAddOrderItem}
                    >
                      + {intl.formatMessage({id: 'ADD_ITEM'})}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleClose}
              />
              {data?.status === OrderStatus.OPEN && (
                <Button
                  className='btn btn-primary'
                  loading={loading.update}
                  label={intl.formatMessage({id: 'UPDATE'})}
                  loadingText={intl.formatMessage({id: 'UPDATE'})}
                  event={formik.handleSubmit}
                />
              )}
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {EditOrderInfo}
