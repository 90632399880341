import React, {useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {
  Button,
  InputTextFormik,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../../_gori/partials/widgets'
import * as Yup from 'yup'
import UseYupValidate from '../../../../../_gori/hooks/UseYupValidate'
import {OPTION_COUNTRIES} from '../../../../../_gori/constants'
import {find, forEach, includes, isEmpty} from 'lodash'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import OnboardService from '../../core/_requests'
import {KTSVG} from '../../../../../_gori/helpers'
import {CSSTransition} from 'react-transition-group'
import {OPTIONS_METHODS, OPTIONS_SERVICES, PROFILE_FIELD} from '../../core/_const'
import {TransparentButton} from '../../../../../_gori/partials/widgets/form/TransparentButton'
import {useNavigate} from 'react-router-dom'
import {AutocompleteAddress} from '../../../common'
import {InputCheckboxFormik} from '../../../../../_gori/partials/widgets/form/InputCheckboxFormik'
import CardLayout from '../common/CardLayout'
import UserService from '../../../users/core/_requests'
import {useAuth} from '../../../auth'

type Props = {
  onboard?: any
}

const Onboarding: React.FC<Props> = ({onboard}) => {
  const intl = useIntl()
  const {setCurrentUser} = useAuth()
  const {stringYup, regexYup} = UseYupValidate()
  const [validationErrors, setValidationErrors] = useState<any>()
  const {newCancelToken, isCancel} = useCancelToken()
  const [saveAndExit, setSaveAndExit] = useState<boolean>(false)
  const [sameAddress, setSameAddress] = useState<boolean>(true)
  const [sameAddressUpsFedex, setSameAddressUpsFedex] = useState<boolean>(true)
  const navigate = useNavigate()
  const OPTIONS_SERVICES_CUSTOM = useMemo(() => {
    return [...OPTIONS_SERVICES, {label: intl.formatMessage({id: 'NOT_SURE'}), value: 'not_sure'}]
  }, [intl])
  const OPTIONS_METHODS_CUSTOM = useMemo(() => {
    return [...OPTIONS_METHODS, {label: intl.formatMessage({id: 'NOT_SURE'}), value: 'not_sure'}]
  }, [intl])

  const [usps, setUsps] = useState<boolean>(false)
  const [uspsDisable, setUspsDisable] = useState<boolean>(false)

  const [ups, setUps] = useState<boolean>(false)
  const [upsDisable, setUpsDisable] = useState<boolean>(false)

  const [fedex, setFedex] = useState<boolean>(false)
  const [fedexDisable, setFedexDisable] = useState<boolean>(false)

  const [dhl, setDhl] = useState<boolean>(false)
  const [dhlDisable, setDhlDisable] = useState<boolean>(false)

  const [serviceNotSure, setServiceNotSure] = useState<boolean>(false)
  const [serviceNotSureDisable, setServiceNotSureDisable] = useState<boolean>(false)

  const [methodShipbae, setMethodShipbae] = useState<boolean>(false)
  const [methodShipbaeDisable, setMethodShipbaeDisable] = useState<boolean>(false)

  const [methodGori, setMethodGori] = useState<boolean>(false)
  const [methodGoriDisable, setMethodGoriDisable] = useState<boolean>(false)

  const [methodNotSure, setMethodNotSure] = useState<boolean>(false)
  const [methodNotSureDisable, setMethodNotSureDisable] = useState<boolean>(false)

  const [isRequiredService, setIsRequiredService] = useState<boolean>(false)
  const [isRequiredMethod, setIsRequiredMethod] = useState<boolean>(false)

  const [isFormUpsFedex, setIsFormUpsFedex] = useState<boolean>(false)

  const [mndaChecked, setMndaChecked] = useState<boolean>(false)

  useEffect(() => {
    if (ups || fedex) {
      setIsFormUpsFedex(true)
    } else {
      setIsFormUpsFedex(false)
    }
  }, [fedex, ups])

  const validationSchema = useMemo(() => {
    let required = true

    return Yup.object().shape({
      country: stringYup(255, 'COUNTRY', required),
      street1: stringYup(255, 'ADDRESS', required),
      street2: stringYup(255, 'ADDRESS_2', false),
      city: stringYup(255, 'CITY', required),
      zip: Yup.string().when(`country`, {
        is: (val) => val === 'US',
        then: regexYup.zipCode('POSTAL_CODE', required),
        otherwise: stringYup(255, 'POSTAL_CODE', required),
      }),
      state: Yup.string().when(`country`, {
        is: (val) => val === 'US',
        then: regexYup.state('STATE', required),
        otherwise: stringYup(255, 'STATE', required),
      }),
      default_country: Yup.string().when('is_same_address', {
        is: (val) => val === false,
        then: stringYup(255, 'COUNTRY', required),
        otherwise: stringYup(255, 'COUNTRY', false),
      }),
      default_street1: Yup.string().when('is_same_address', {
        is: (val) => val === false,
        then: stringYup(255, 'ADDRESS', required),
        otherwise: stringYup(255, 'ADDRESS', false),
      }),
      default_street2: stringYup(255, 'ADDRESS_2', false),
      default_city: Yup.string().when('is_same_address', {
        is: (val) => val === false,
        then: stringYup(255, 'CITY', required),
        otherwise: stringYup(255, 'CITY', false),
      }),
      default_zip: Yup.string().when(['default_country', 'is_same_address'], {
        is: (default_country, is_same_address) =>
          default_country === 'US' && is_same_address === false,
        then: regexYup.zipCode('POSTAL_CODE', !sameAddress),
        otherwise: stringYup(255, 'POSTAL_CODE', !sameAddress),
      }),
      default_state: Yup.string().when(['default_country', 'is_same_address'], {
        is: (default_country, is_same_address) =>
          default_country === 'US' && is_same_address === false,
        then: regexYup.state('STATE', !sameAddress),
        otherwise: stringYup(255, 'STATE', !sameAddress),
      }),
      ups_fedex_country: Yup.string().when('ups_fedex_is_same_address', {
        is: (val) => val === false,
        then: stringYup(255, 'COUNTRY', required),
        otherwise: stringYup(255, 'COUNTRY', false),
      }),
      ups_fedex_street1: Yup.string().when('ups_fedex_is_same_address', {
        is: (val) => val === false,
        then: stringYup(255, 'ADDRESS', required),
        otherwise: stringYup(255, 'ADDRESS', false),
      }),
      ups_fedex_street2: stringYup(255, 'ADDRESS_2', false),
      ups_fedex_city: Yup.string().when('ups_fedex_is_same_address', {
        is: (val) => val === false,
        then: stringYup(255, 'ADDRESS', required),
        otherwise: stringYup(255, 'ADDRESS', false),
      }),
      ups_fedex_zip: Yup.string().when(['ups_fedex_country', 'ups_fedex_is_same_address'], {
        is: (ups_fedex_country, ups_fedex_is_same_address) =>
          ups_fedex_country === 'US' && ups_fedex_is_same_address === false,
        then: regexYup.zipCode('POSTAL_CODE', !sameAddressUpsFedex),
        otherwise: stringYup(255, 'POSTAL_CODE', !sameAddressUpsFedex),
      }),
      ups_fedex_state: Yup.string().when(['ups_fedex_country', 'ups_fedex_is_same_address'], {
        is: (ups_fedex_country, ups_fedex_is_same_address) =>
          ups_fedex_country === 'US' && ups_fedex_is_same_address === false,
        then: regexYup.state('STATE', !sameAddressUpsFedex),
        otherwise: stringYup(255, 'STATE', !sameAddressUpsFedex),
      }),
      sales_rep: regexYup.inputText('SALE_REP', false, 50),
      promo_code: stringYup(50, 'PROMO_CODE', false),
      mnda_terms_conditions: Yup.boolean()
        .oneOf([true], intl.formatMessage({id: 'YOU_MUST_AGREE_TO_TERM_AND_CONDITIONS'}))
        .required(intl.formatMessage({id: 'YOU_MUST_AGREE_TO_TERM_AND_CONDITIONS'})),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sameAddress, saveAndExit, isFormUpsFedex, sameAddressUpsFedex])

  useEffect(() => {
    let dataService = onboard?.shipping?.interested_services ?? []
    dataService.forEach((item: string) => {
      if (item === 'ups') {
        setUps(true)
      }
      if (item === 'usps') {
        setUsps(true)
      }
      if (item === 'fedex') {
        setFedex(true)
      }
      if (item === 'dhl') {
        setDhl(true)
      }
      if (item === 'not_sure') {
        setServiceNotSure(true)
      }
    })

    let dataMethod = onboard?.shipping?.using_methods ?? []
    dataMethod.forEach((item: string) => {
      if (item === 'shipbae') {
        setMethodShipbae(true)
      }
      if (item === 'gori_api') {
        setMethodGori(true)
      }
      if (item === 'not_sure') {
        setMethodNotSure(true)
      }
    })
  }, [onboard?.shipping?.interested_services, onboard?.shipping?.using_methods])

  const initialValues = useMemo(() => {
    let init: any = {}
    forEach([...PROFILE_FIELD.SALES, ...PROFILE_FIELD.BUSINESS_ADDRESS], (field: any) => {
      if (!isEmpty(onboard)) {
        init[field.name] = onboard?.profile?.[`${field.name}`]
      }
    })

    let isSameAdd = true
    let isSameAddUps = true
    if (onboard?.ship_from_address) {
      onboard?.ship_from_address?.forEach((item) => {
        if (item.type === 'default') {
          isSameAdd = item.is_same_address ?? false
        }

        if (item.type === 'for_usps_fedex') {
          isSameAddUps = item.is_same_address ?? false
        }
      })
    }

    let dataShippingDefault = {
      default_country: onboard?.shipping?.country,
      default_street1: onboard?.shipping?.street1,
      default_street2: onboard?.shipping?.street2,
      default_city: onboard?.shipping?.city,
      default_zip: onboard?.shipping?.zip,
      default_state: onboard?.shipping?.state,
      is_same_address: onboard?.shipping?.is_same_address ?? isSameAdd,
    }

    let dataShippingUspFedex = {
      country: onboard?.shipping?.ups_fedex_country,
      street1: onboard?.shipping?.ups_fedex_street1,
      street2: onboard?.shipping?.ups_fedex_street2,
      city: onboard?.shipping?.ups_fedex_city,
      zip: onboard?.shipping?.ups_fedex_zip,
      state: onboard?.shipping?.ups_fedex_state,
      ups_fedex_is_same_address: onboard?.shipping?.ups_fedex_is_same_address ?? isSameAddUps,
    }

    setSameAddress(dataShippingDefault.is_same_address)
    setSameAddressUpsFedex(dataShippingUspFedex.ups_fedex_is_same_address)
    setMndaChecked(onboard?.profile?.mnda_terms_conditions)
    let arrInterestedServices = onboard?.shipping?.interested_services ?? []
    const checkEditsUps = arrInterestedServices.includes('ups')
    const checkEditsFedex = arrInterestedServices.includes('fedex')
    if (checkEditsUps || checkEditsFedex) {
      setIsFormUpsFedex(true)
    } else {
      setIsFormUpsFedex(false)
    }

    return {
      ...init,
      interested_services:
        OPTIONS_SERVICES_CUSTOM.filter((option) =>
          onboard?.shipping?.interested_services?.includes(option.value)
        ) ?? [],
      using_methods:
        OPTIONS_METHODS_CUSTOM.filter((option) =>
          onboard?.shipping?.using_methods?.includes(option.value)
        ) ?? [],
      default_country: dataShippingDefault?.default_country ?? '',
      default_street1: dataShippingDefault?.default_street1 ?? '',
      default_street2: dataShippingDefault?.default_street2 ?? '',
      default_city: dataShippingDefault?.default_city ?? '',
      default_zip: dataShippingDefault?.default_zip ?? '',
      default_state: dataShippingDefault?.default_state ?? '',
      is_same_address: checkEditsUps || checkEditsFedex ? dataShippingDefault?.is_same_address : '',
      ups_fedex_country: checkEditsUps || checkEditsFedex ? dataShippingUspFedex?.country : '',
      ups_fedex_street1: checkEditsUps || checkEditsFedex ? dataShippingUspFedex?.street1 : '',
      ups_fedex_street2: checkEditsUps || checkEditsFedex ? dataShippingUspFedex?.street2 : '',
      ups_fedex_city: checkEditsUps || checkEditsFedex ? dataShippingUspFedex?.city : '',
      ups_fedex_zip: checkEditsUps || checkEditsFedex ? dataShippingUspFedex?.zip : '',
      ups_fedex_state: checkEditsUps || checkEditsFedex ? dataShippingUspFedex?.state : '',
      ups_fedex_is_same_address:
        checkEditsUps || checkEditsFedex ? dataShippingUspFedex?.ups_fedex_is_same_address : '',
      ship_from_address: onboard?.ship_from_address,
      mnda_terms_conditions: onboard?.profile?.mnda_terms_conditions,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboard])

  const getInterestedService = () => {
    let data = []
    if (serviceNotSure) {
      // @ts-ignore
      data = ['not_sure']
    } else {
      if (ups) {
        // @ts-ignore
        data.push('ups')
      }
      if (usps) {
        // @ts-ignore
        data.push('usps')
      }
      if (fedex) {
        // @ts-ignore
        data.push('fedex')
      }
      if (dhl) {
        // @ts-ignore
        data.push('dhl')
      }
    }

    return data
  }
  const getMethodService = () => {
    let data = []
    if (methodNotSure) {
      // @ts-ignore
      data = ['not_sure']
    } else {
      if (methodShipbae) {
        // @ts-ignore
        data.push('shipbae')
      }

      if (methodGori) {
        // @ts-ignore
        data.push('gori_api')
      }
    }

    return data
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      let payload = {
        step: 'single_step',
        data: {
          ...values,
          check_hazardous: true,
          is_same_address: sameAddress,
          ups_fedex_is_same_address: sameAddressUpsFedex,
          interested_services: getInterestedService(),
          using_methods: getMethodService(),
        },
        save_and_exit: saveAndExit,
      }

      let isMethodEmpty = isEmpty(payload.data.using_methods)
      let isServiceEmpty = isEmpty(payload.data.interested_services)

      if (isMethodEmpty) {
        setIsRequiredMethod(true)
      }

      if (isServiceEmpty) {
        setIsRequiredService(true)
      }

      if (isMethodEmpty || isServiceEmpty) {
        return
      }

      if (sameAddress) {
        payload.data.default_country = values.country || ''
        payload.data.default_street1 = values.street1 || ''
        payload.data.default_street2 = values.street2 || ''
        payload.data.default_city = values.city || ''
        payload.data.default_zip = values.zip || ''
        payload.data.default_state = values.state || ''
      }

      if (sameAddressUpsFedex) {
        payload.data.ups_fedex_city = payload.data.default_city || ''
        payload.data.ups_fedex_country = payload.data.default_country || ''
        payload.data.ups_fedex_state = payload.data.default_state || ''
        payload.data.ups_fedex_street1 = payload.data.default_street1 || ''
        payload.data.ups_fedex_street2 = payload.data.default_street2 || ''
        payload.data.ups_fedex_zip = payload.data.default_zip || ''
      }

      if (!isFormUpsFedex) {
        payload.data.ups_fedex_city = ''
        payload.data.ups_fedex_country = ''
        payload.data.ups_fedex_is_same_address = true
        payload.data.ups_fedex_state = ''
        payload.data.ups_fedex_street1 = ''
        payload.data.ups_fedex_street2 = ''
        payload.data.ups_fedex_zip = ''
      }

      try {
        const config = {cancelToken: newCancelToken()}
        await OnboardService.store(payload, config)
        setCurrentUser(await UserService.getUser(config))
      } catch (error: any) {
        if (isCancel(error)) return
        let errorFields = error?.response?.data?.error?.fields
        if (errorFields.interested_services || errorFields.using_methods) {
          if (errorFields.interested_services) {
            setIsRequiredService(true)
          }
          if (errorFields.using_methods) {
            setIsRequiredMethod(true)
          }
        } else {
          setValidationErrors(error?.response)
        }
      } finally {
        setSubmitting(false)
        if (saveAndExit) {
          navigate('/logout')
        } else {
          navigate('/orders')
        }
      }
    },
  })

  useEffect(() => {
    if (!serviceNotSure) {
      setUspsDisable(false)
      setUpsDisable(false)
      setFedexDisable(false)
      setDhlDisable(false)
    } else {
      setUspsDisable(true)
      setUpsDisable(true)
      setFedexDisable(true)
      setDhlDisable(true)
    }

    if (!methodNotSure) {
      setMethodGoriDisable(false)
      setMethodShipbaeDisable(false)
    } else {
      setMethodGoriDisable(true)
      setMethodShipbaeDisable(true)
    }
  }, [serviceNotSure, methodNotSure])

  const setSameAddressInput = async (isSame: boolean | ((prevState: boolean) => boolean)) => {
    const address = onboard.profile
    let shipping = onboard.shipping

    formik
      .setFieldValue('default_country', isSame ? address.country : shipping?.country ?? '')
      .then(() => {
        formik.setFieldError('default_country', undefined)
      })
    formik
      .setFieldValue('default_street1', isSame ? address.street1 : shipping?.street1 ?? '')
      .then(() => {
        formik.setFieldError('default_street1', undefined)
      })

    formik
      .setFieldValue('default_street2', isSame ? address.street2 : shipping?.street2 ?? '')
      .then(() => {
        formik.setFieldError('default_street2', undefined)
      })
    formik.setFieldValue('default_city', isSame ? address.city : shipping?.city ?? '').then(() => {
      formik.setFieldError('default_city', undefined)
    })
    formik.setFieldValue('default_zip', isSame ? address.zip : shipping?.zip ?? '').then(() => {
      formik.setFieldError('default_zip', undefined)
    })
    formik
      .setFieldValue('default_state', isSame ? address.state : shipping?.state ?? '')
      .then(() => {
        formik.setFieldError('default_state', undefined)
      })
    setSameAddress(isSame)
  }

  const setSameAddressInputUpsFedex = async (
    isSameUpsFedex: boolean | ((prevState: boolean) => boolean)
  ) => {
    let profile = {
      country: onboard?.shipping?.ups_fedex_country ?? '',
      street1: onboard?.shipping?.ups_fedex_street1 ?? '',
      street2: onboard?.shipping?.ups_fedex_street2 ?? '',
      city: onboard?.shipping?.ups_fedex_city ?? '',
      zip: onboard?.shipping?.ups_fedex_zip ?? '',
      state: onboard?.shipping?.ups_fedex_state ?? '',
    }

    formik
      .setFieldValue(
        'ups_fedex_country',
        isSameUpsFedex ? formik.values.default_country : profile?.country ?? ''
      )
      .then(() => {
        formik.setFieldError('ups_fedex_country', undefined)
      })
    formik
      .setFieldValue(
        'ups_fedex_street1',
        isSameUpsFedex ? formik.values.default_street1 : profile?.street1 ?? ''
      )
      .then(() => {
        formik.setFieldError('ups_fedex_street1', undefined)
      })

    formik
      .setFieldValue(
        'ups_fedex_street2',
        isSameUpsFedex ? formik.values.default_street2 : profile?.street2 ?? ''
      )
      .then(() => {
        formik.setFieldError('ups_fedex_street2', undefined)
      })
    formik
      .setFieldValue(
        'ups_fedex_city',
        isSameUpsFedex ? formik.values.default_city : profile?.city ?? ''
      )
      .then(() => {
        formik.setFieldError('ups_fedex_city', undefined)
      })
    formik
      .setFieldValue(
        'ups_fedex_zip',
        isSameUpsFedex ? formik.values.default_zip : profile?.zip ?? ''
      )
      .then(() => {
        formik.setFieldError('ups_fedex_zip', undefined)
      })
    formik
      .setFieldValue(
        'ups_fedex_state',
        isSameUpsFedex ? formik.values.default_state : profile?.state ?? ''
      )
      .then(() => {
        formik.setFieldError('ups_fedex_state', undefined)
      })

    setSameAddressUpsFedex(isSameUpsFedex)
  }

  const setAgreeTermsCondition = async (isChecked: boolean | ((prevState: boolean) => boolean)) => {
    setMndaChecked(isChecked)
  }

  const selectedPlace = (selected) => {
    let city = find(selected.address_components, (item) => {
      return includes(item?.types, 'locality')
    })
    formik.setFieldValue(formik.getFieldProps(`city`).name, city?.long_name ?? '')
    let state = find(selected.address_components, (item) => {
      return includes(item?.types, 'administrative_area_level_1')
    })
    formik.setFieldValue(formik.getFieldProps(`state`).name, state?.short_name ?? '')
    let country = find(selected.address_components, (item) => {
      return includes(item?.types, 'country')
    })
    formik.setFieldValue(formik.getFieldProps(`country`).name, country?.short_name ?? '')
    let zipCode = find(selected.address_components, (item) => {
      return includes(item?.types, 'postal_code')
    })
    let suffix = find(selected.address_components, (item) => {
      return includes(item?.types, 'postal_code_suffix')
    })
    if (zipCode?.long_name) {
      zipCode = zipCode?.long_name + (suffix?.long_name ? `-${suffix?.long_name}` : '')
    } else {
      zipCode = ''
    }
    formik.setFieldValue('zip', zipCode)
  }

  const selectedPlaceDefault = (selected) => {
    let city = find(selected.address_components, (item) => {
      return includes(item?.types, 'locality')
    })
    formik.setFieldValue(formik.getFieldProps(`default_city`).name, city?.long_name ?? '')
    let state = find(selected.address_components, (item) => {
      return includes(item?.types, 'administrative_area_level_1')
    })
    formik.setFieldValue(formik.getFieldProps(`default_state`).name, state?.short_name ?? '')
    let country = find(selected.address_components, (item) => {
      return includes(item?.types, 'country')
    })
    formik.setFieldValue(formik.getFieldProps(`default_country`).name, country?.short_name ?? '')
    let zipCode = find(selected.address_components, (item) => {
      return includes(item?.types, 'postal_code')
    })
    let suffix = find(selected.address_components, (item) => {
      return includes(item?.types, 'postal_code_suffix')
    })
    if (zipCode?.long_name) {
      zipCode = zipCode?.long_name + (suffix?.long_name ? `-${suffix?.long_name}` : '')
    } else {
      zipCode = ''
    }
    formik.setFieldValue('default_zip', zipCode)
  }
  const selectedPlaceUpsFedex = (selected) => {
    let ups_fedex_city = find(selected.address_components, (item) => {
      return includes(item?.types, 'locality')
    })
    formik.setFieldValue(
      formik.getFieldProps(`ups_fedex_city`).name,
      ups_fedex_city?.long_name ?? ''
    )
    let ups_fedex_state = find(selected.address_components, (item) => {
      return includes(item?.types, 'administrative_area_level_1')
    })
    formik.setFieldValue(
      formik.getFieldProps(`ups_fedex_state`).name,
      ups_fedex_state?.short_name ?? ''
    )
    let ups_fedex_country = find(selected.address_components, (item) => {
      return includes(item?.types, 'country')
    })
    formik.setFieldValue(
      formik.getFieldProps(`ups_fedex_country`).name,
      ups_fedex_country?.short_name ?? ''
    )
    let ups_fedex_zip_code = find(selected.address_components, (item) => {
      return includes(item?.types, 'postal_code')
    })
    let suffix = find(selected.address_components, (item) => {
      return includes(item?.types, 'postal_code_suffix')
    })
    if (ups_fedex_zip_code?.long_name) {
      ups_fedex_zip_code =
        ups_fedex_zip_code?.long_name + (suffix?.long_name ? `-${suffix?.long_name}` : '')
    } else {
      ups_fedex_zip_code = ''
    }
    formik.setFieldValue('ups_fedex_zip', ups_fedex_zip_code)
  }

  const handleEventServiceNotSure = () => {
    setServiceNotSure(!serviceNotSure)
    if (!serviceNotSure) {
      setUspsDisable(true)
      setUpsDisable(true)
      setFedexDisable(true)
      setDhlDisable(true)
    } else {
      setUspsDisable(false)
      setUpsDisable(false)
      setFedexDisable(false)
      setDhlDisable(false)
    }

    if (!serviceNotSure || fedex || usps || dhl || ups) {
      setIsRequiredService(false)
    } else {
      setIsRequiredService(true)
    }
  }

  useEffect(() => {
    if (usps || ups || fedex || dhl) {
      setServiceNotSureDisable(true)
    } else {
      setServiceNotSureDisable(false)
    }
  }, [usps, ups, fedex, dhl])
  const handleEventMethodNotSure = () => {
    setMethodNotSure(!methodNotSure)
    if (!methodNotSure) {
      setMethodShipbaeDisable(true)
      setMethodGoriDisable(true)
    } else {
      setMethodShipbaeDisable(false)
      setMethodGoriDisable(false)
    }
    if (!methodNotSure || methodGori || methodShipbae) {
      setIsRequiredMethod(false)
    } else {
      setIsRequiredMethod(true)
    }
  }

  useEffect(() => {
    if (methodShipbae || methodGori) {
      setMethodNotSureDisable(true)
    } else {
      setMethodNotSureDisable(false)
    }
  }, [methodShipbae, methodGori])

  const getClassShippingPreference = (value, isDisable) => {
    return clsx('btn-sm btn-none border rounded-2 mb-2 me-2 fs-6 text-nowrap', {
      'opacity-50 cursor-no-drop': isDisable,
      'border-hover-primary text-hover-primary  bg-white': !value && !isDisable,
      'btn-primary border-primary text-white': value,
      'btn-light border-secondary text-gray-600': !value,
    })
  }
  const setUspsAndCheckShippingService = (value) => {
    setUsps(value)
    if (value || fedex || ups || dhl || serviceNotSure) {
      setIsRequiredService(false)
    } else {
      setIsRequiredService(true)
    }
  }
  const setUpsAndCheckShippingService = (value) => {
    setUps(value)
    if (value || fedex || usps || dhl || serviceNotSure) {
      setIsRequiredService(false)
    } else {
      setIsRequiredService(true)
    }
  }
  const setFedexAndCheckShippingService = (value) => {
    setFedex(value)
    if (value || ups || usps || dhl || serviceNotSure) {
      setIsRequiredService(false)
    } else {
      setIsRequiredService(true)
    }
  }
  const setDhlAndCheckShippingService = (value) => {
    setDhl(value)
    if (value || ups || usps || fedex || serviceNotSure) {
      setIsRequiredService(false)
    } else {
      setIsRequiredService(true)
    }
  }
  const setShipBaeAndCheckMethod = (value) => {
    setMethodShipbae(value)
    if (value || serviceNotSure || methodGori) {
      setIsRequiredMethod(false)
    } else {
      setIsRequiredMethod(true)
    }
  }
  const setGoriApiAndCheckMethod = (value) => {
    setMethodGori(value)
    if (value || serviceNotSure || methodShipbae) {
      setIsRequiredMethod(false)
    } else {
      setIsRequiredMethod(true)
    }
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}

      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <>
          <CardLayout>
            <div className='col-md-4'>
              <div className='text-start'>
                <p className='fs-3 fw-bolder text-primary'>
                  {intl.formatMessage({id: 'SHIPPING_PREFERENCE'})}
                </p>
                <div className='row'>
                  <div className='mt-2 mb-0'>
                    <label className='form-label fw-bolder required'>
                      {intl.formatMessage({
                        id: 'WHICH_METHOD_ARE_YOU_USING_TO_CREATE_YOUR_LABELS',
                      })}
                    </label>
                    <div className={'d-flex flex-wrap'}>
                      <Button
                        className={getClassShippingPreference(methodShipbae, methodShipbaeDisable)}
                        event={() => setShipBaeAndCheckMethod(!methodShipbae)}
                        disabled={methodShipbaeDisable}
                      >
                        {intl.formatMessage({id: 'SHIPBAE'})}
                      </Button>
                      <Button
                        className={getClassShippingPreference(methodGori, methodGoriDisable)}
                        event={() => setGoriApiAndCheckMethod(!methodGori)}
                        disabled={methodGoriDisable}
                      >
                        {intl.formatMessage({id: 'GORI_API'})}
                      </Button>
                      <Button
                        className={getClassShippingPreference(methodNotSure, methodNotSureDisable)}
                        event={() => handleEventMethodNotSure()}
                        disabled={methodNotSureDisable}
                      >
                        {intl.formatMessage({id: 'NOT_SURE'})}
                      </Button>
                    </div>
                    <div className='fv-help-block text-danger'>
                      <span role='alert'>
                        {isRequiredMethod
                          ? intl.formatMessage({
                              id: 'PLEASE_SELECT_SHIPPING_METHOD',
                            })
                          : ''}
                      </span>
                    </div>
                  </div>

                  <div className={clsx('my-2')}>
                    <label className='form-label fw-bolder required'>
                      {intl.formatMessage({id: 'WHAT_SHIPPING_SERVICES_ARE_YOU_INTERESTED_IN'})}
                    </label>
                    <br />
                    <div className={'d-flex flex-wrap'}>
                      <Button
                        className={getClassShippingPreference(usps, uspsDisable)}
                        event={() => setUspsAndCheckShippingService(!usps)}
                        disabled={uspsDisable}
                      >
                        {intl.formatMessage({id: 'USPS'})}
                      </Button>
                      <Button
                        className={getClassShippingPreference(ups, upsDisable)}
                        event={() => setUpsAndCheckShippingService(!ups)}
                        disabled={upsDisable}
                      >
                        {intl.formatMessage({id: 'UPS'})}
                      </Button>
                      <Button
                        className={getClassShippingPreference(fedex, fedexDisable)}
                        event={() => setFedexAndCheckShippingService(!fedex)}
                        disabled={fedexDisable}
                      >
                        {intl.formatMessage({id: 'FEDEX'})}
                      </Button>

                      <Button
                        className={getClassShippingPreference(dhl, dhlDisable)}
                        event={() => setDhlAndCheckShippingService(!dhl)}
                        disabled={dhlDisable}
                      >
                        {intl.formatMessage({id: 'DHL'})}
                      </Button>
                      <Button
                        className={getClassShippingPreference(
                          serviceNotSure,
                          serviceNotSureDisable
                        )}
                        event={() => handleEventServiceNotSure()}
                        disabled={serviceNotSureDisable}
                      >
                        {intl.formatMessage({id: 'NOT_SURE'})}
                      </Button>
                    </div>

                    <br />
                    <div className='fv-help-block text-danger'>
                      <span role='alert'>
                        {isRequiredService
                          ? intl.formatMessage({
                              id: 'PLEASE_SELECT_SERVICE_SERVICE',
                            })
                          : ''}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-8'>
              <div className='text-start'>
                <p className='fs-3 fw-bolder text-primary'>
                  {intl.formatMessage({id: 'BUSINESS_ADDRESS'})}
                </p>
                <div className='row pe-10'>
                  {PROFILE_FIELD.BUSINESS_ADDRESS.map((field, index) => {
                    if (field.type === 'text') {
                      return (
                        <div className={`my-2 ${field?.col}`} key={index}>
                          <InputTextFormik
                            className='col-12'
                            formik={formik}
                            name={field.name}
                            disabled={formik.isSubmitting}
                            label={intl.formatMessage({id: field.label})}
                            labelClassName={clsx('fw-bolder', {required: field.required})}
                          />
                        </div>
                      )
                    }
                    if (field.type === 'select') {
                      return (
                        <div className={`my-2 ${field?.col}`} key={index}>
                          <SelectFormik
                            checkFormik
                            placeholder={''}
                            options={field?.options || []}
                            formik={formik}
                            name={field.name}
                            required={field.required}
                            label={intl.formatMessage({id: field.label})}
                            labelClassName={clsx('fw-bolder', {required: field.required})}
                          />
                        </div>
                      )
                    }
                    if (field.type === 'address') {
                      return (
                        <div className={`my-2 ${field?.col} address-complete`} key={index}>
                          <label
                            className={`form-label fw-bolder ${field.required ? 'required' : ''}`}
                          >
                            {intl.formatMessage({id: field.label})}
                            {!field.required && (
                              <i className='ms-2 text-muted fs-7'>
                                {intl.formatMessage({id: 'OPTIONAL_LABEL'})}
                              </i>
                            )}
                          </label>
                          <AutocompleteAddress
                            formik={formik}
                            name={field.name}
                            label=''
                            onSelected={(selected) => {
                              selectedPlace(selected)
                            }}
                          />
                        </div>
                      )
                    }
                    return null
                  })}
                </div>
              </div>
              <div className='text-start'>
                <p
                  className='fs-3 fw-bolder text-primary my-4'
                  style={{display: !isFormUpsFedex ? '' : 'none'}}
                >
                  {intl.formatMessage({id: 'FROM_ADDRESS'})}
                </p>
                <p
                  className='fs-3 fw-bolder text-primary my-4'
                  style={{display: !isFormUpsFedex ? 'none' : ''}}
                >
                  {intl.formatMessage({id: 'FROM_ADDRESSES'})}
                </p>
                <div className='row pt-2'>
                  <div className='col-md-5 form-label fw-bolder'>
                    <span style={{fontSize: '1.35rem'}}>
                      {intl.formatMessage({id: 'DEFAULT'})}{' '}
                    </span>
                    <span className='text-gray-700'>
                      {intl.formatMessage({id: 'SHIPPING_FROM_ADDRESS_DEFAULT'})}
                    </span>
                  </div>
                  <div className='col-md-7'>
                    <InputCheckboxFormik
                      formik={formik}
                      type='checkbox'
                      checked={sameAddress}
                      label={intl.formatMessage({id: 'SAME_AS_BUSINESS_ADDRESS'})}
                      onClick={() => setSameAddressInput(!sameAddress)}
                      name={'is_same_address'}
                    />
                  </div>

                  {!sameAddress && (
                    <div className='col-md-12 pe-10'>
                      <div className={'px-5 py-4 bg-gray-100 rounded-2'}>
                        <div className={`row`}>
                          <div className={`my-2 col-3`}>
                            <label className='form-label fw-bolder required'>
                              {intl.formatMessage({id: 'COUNTRY'})}
                            </label>
                            <SelectFormik
                              checkFormik
                              placeholder={''}
                              options={OPTION_COUNTRIES}
                              formik={formik}
                              name={'default_country'}
                              required
                            />
                          </div>
                          <div className={`my-2 col-6 address-complete`}>
                            <label className={'form-label fw-bolder required'}>
                              {intl.formatMessage({id: 'ADDRESS'})}
                            </label>
                            <AutocompleteAddress
                              formik={formik}
                              name={'default_street1'}
                              label=''
                              onSelected={(selected) => {
                                selectedPlaceDefault(selected)
                              }}
                            />
                          </div>
                          <div className={`my-2 col-3`}>
                            <InputTextFormik
                              formik={formik}
                              name={'default_street2'}
                              disabled={formik.isSubmitting}
                              label={
                                <>
                                  {intl.formatMessage({id: 'ADDRESS_2'})}
                                  {
                                    <i className='ms-2 text-muted fs-7'>
                                      {intl.formatMessage({id: 'OPTIONAL_LABEL'})}
                                    </i>
                                  }
                                </>
                              }
                              labelClassName={'fw-bolder'}
                            />
                          </div>
                        </div>

                        <div className={`row`}>
                          <div className={`my-2 col-3`}>
                            <InputTextFormik
                              formik={formik}
                              name={'default_city'}
                              disabled={formik.isSubmitting}
                              label={intl.formatMessage({id: 'CITY'})}
                              labelClassName={'fw-bolder required'}
                            />
                          </div>
                          <div className={`my-2 col-3`}>
                            <InputTextFormik
                              formik={formik}
                              name={'default_zip'}
                              disabled={formik.isSubmitting}
                              label={intl.formatMessage({id: 'POSTAL_CODE'})}
                              labelClassName={'fw-bolder required'}
                            />
                          </div>
                          <div className={`my-2 col-3`}>
                            <InputTextFormik
                              formik={formik}
                              name={'default_state'}
                              disabled={formik.isSubmitting}
                              label={intl.formatMessage({id: 'STATE'})}
                              labelClassName={'fw-bolder required'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className='row py-4' style={{display: !isFormUpsFedex ? 'none' : ''}}>
                  <div className='col-md-5'>
                    <label className='form-label fw-bolder'>
                      <h3>{intl.formatMessage({id: 'SHIPPING_FROM_ADDRESS_UPS_FEDEX'})}</h3>
                    </label>
                  </div>
                  <div className='col-md-7'>
                    <InputCheckboxFormik
                      formik={formik}
                      type='checkbox'
                      checked={sameAddressUpsFedex}
                      label={intl.formatMessage({id: 'SAME_AS_DEFAULT'})}
                      onClick={() => setSameAddressInputUpsFedex(!sameAddressUpsFedex)}
                      name={'ups_fedex_is_same_address'}
                    />
                  </div>
                  {!sameAddressUpsFedex && (
                    <div className='col-md-12 pe-10'>
                      <div className={'px-5 py-4 bg-gray-100 rounded-2'}>
                        <div className={`row`}>
                          <div className={`my-2 col-3`}>
                            <label className='form-label fw-bolder required'>
                              {intl.formatMessage({id: 'COUNTRY'})}
                            </label>
                            <SelectFormik
                              checkFormik
                              placeholder={''}
                              options={OPTION_COUNTRIES}
                              formik={formik}
                              name={'ups_fedex_country'}
                            />
                          </div>
                          <div className={`my-2 col-6 address-complete`}>
                            <label className={'form-label fw-bolder required'}>
                              {intl.formatMessage({id: 'ADDRESS'})}
                            </label>
                            <AutocompleteAddress
                              formik={formik}
                              name={'ups_fedex_street1'}
                              label=''
                              onSelected={(selected) => {
                                selectedPlaceUpsFedex(selected)
                              }}
                            />
                          </div>
                          <div className={`my-2 col-3`}>
                            <InputTextFormik
                              formik={formik}
                              name={'ups_fedex_street2'}
                              disabled={formik.isSubmitting}
                              label={
                                <>
                                  {intl.formatMessage({id: 'ADDRESS_2'})}
                                  <i className='ms-2 text-muted fs-7'>
                                    {intl.formatMessage({id: 'OPTIONAL_LABEL'})}
                                  </i>
                                </>
                              }
                              labelClassName={'fw-bolder'}
                            />
                          </div>
                        </div>
                        <div className={`row`}>
                          <div className={`my-2 col-3`}>
                            <InputTextFormik
                              formik={formik}
                              name={'ups_fedex_city'}
                              disabled={formik.isSubmitting}
                              label={intl.formatMessage({id: 'CITY'})}
                              labelClassName={'fw-bolder required'}
                            />
                          </div>
                          <div className={`my-2 col-3`}>
                            <InputTextFormik
                              formik={formik}
                              name={'ups_fedex_zip'}
                              disabled={formik.isSubmitting}
                              label={intl.formatMessage({id: 'POSTAL_CODE'})}
                              labelClassName={'fw-bolder required'}
                            />
                          </div>
                          <div className={`my-2 col-3`}>
                            <InputTextFormik
                              formik={formik}
                              name={'ups_fedex_state'}
                              disabled={formik.isSubmitting}
                              label={intl.formatMessage({id: 'STATE'})}
                              labelClassName={'fw-bolder required'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='col-12'>
              <hr className='bg-gray-400' />
            </div>
            <div className='col-md-12 mt-3'>
              <div className='row'>
                {PROFILE_FIELD.SALES.map((field, index) => {
                  if (field.type === 'text') {
                    return (
                      <div className='col-md-4' key={index}>
                        <InputTextFormik
                          className='col-12'
                          formik={formik}
                          name={field.name}
                          disabled={formik.isSubmitting || field.disabled}
                          label={
                            <>
                              {intl.formatMessage({id: field.label})}
                              {!field.required && (
                                <i className='ms-2 text-muted fs-7'>
                                  {intl.formatMessage({id: 'OPTIONAL_LABEL'})}
                                </i>
                              )}
                            </>
                          }
                          labelClassName={clsx('fw-bolder', {required: field.required})}
                        />
                      </div>
                    )
                  }
                  return null
                })}
              </div>
            </div>
            <div className='col-md-12 mt-4'>
              <div className='row'>
                <div className={clsx('my-2')}>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <InputCheckboxFormik
                        formik={formik}
                        type='checkbox'
                        checked={mndaChecked}
                        label={
                          <span className='fw-bolder'>
                            {intl.formatMessage({id: 'CLICK_HERE_TO_AGREE_TO'})}{' '}
                            <a
                              href='/mnda'
                              target='_blank'
                              rel='noopener noreferrer'
                              className='fw-bolder'
                            >
                              {intl.formatMessage({id: 'TERM_AND_CONDITIONS'})}
                            </a>
                          </span>
                        }
                        onClick={() => setAgreeTermsCondition(!mndaChecked)}
                        name={'mnda_terms_conditions'}
                        required={true}
                      />
                      {formik.touched.mnda_terms_conditions && formik.errors.mnda_terms_conditions && (
                        <span className='text-danger mt-1' role='alert'>
                          {formik.errors.mnda_terms_conditions}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-9 d-flex justify-content-end'>
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'CONTINUE'})}
                loadingText={intl.formatMessage({id: 'CONTINUE'})}
                loading={formik.isSubmitting}
                event={async () => {
                  await setSaveAndExit(false)
                  formik.handleSubmit()
                }}
                disabled={
                  formik.isSubmitting ||
                  !isEmpty(formik.errors) ||
                  isRequiredMethod ||
                  isRequiredService
                }
              />
            </div>
          </CardLayout>
          <div className='d-flex justify-content-end'>
            <TransparentButton
              className='btn save-and-exit me-20'
              label={intl.formatMessage({id: 'SAVE_AND_EXIT'})}
              loadingText={intl.formatMessage({id: 'SAVE_AND_EXIT'})}
              loading={formik.isSubmitting}
              event={async () => {
                await setSaveAndExit(true)
                formik.handleSubmit()
              }}
              disabled={
                !isEmpty(formik.errors) ||
                formik.isSubmitting ||
                isRequiredMethod ||
                isRequiredService
              }
            >
              <KTSVG path='/media/gori/orders/delete.svg' className='m-0' svgClassName='mh-10px' />
            </TransparentButton>

            <TransparentButton
              className='btn save-and-exit'
              label={intl.formatMessage({id: 'EXIT'})}
              loadingText={intl.formatMessage({id: 'EXIT'})}
              loading={formik.isSubmitting}
              event={() => navigate('/logout')}
              disabled={formik.isSubmitting}
            ></TransparentButton>
          </div>
        </>
      </CSSTransition>
    </>
  )
}

export {Onboarding}
