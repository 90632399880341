import clsx from 'clsx'
import {compact, get} from 'lodash'
import React from 'react'

type Props = {
  className?: string
  name: string
  data: any
}

const ShowAddress: React.FC<Props> = ({name, data, className = ''}) => {
  const firstName = get(data, `${name}_first_name`, '')
  const lastName = get(data, `${name}_last_name`, '')
  const company = get(data, `${name}_company`, '')
  const street1 = get(data, `${name}_street1`, '')
  const city = get(data, `${name}_city`, '')
  const state = get(data, `${name}_state`, '')
  const zip = get(data, `${name}_zip`, '')

  const fullName = compact([firstName, lastName]).join(' ')
  const address = compact([street1, city, state, zip]).join(' ')

  return (
    <div className={clsx(className)}>
      {company && <div className='fw-bolder'>{company}</div>}
      {fullName && <div className='fs-7'>{fullName}</div>}
      {address && <div className='d-block mt-2'>{address}</div>}
    </div>
  )
}

export {ShowAddress}
