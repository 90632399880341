import clsx from 'clsx'
import {find} from 'lodash'
import moment from 'moment'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useSearchParams} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {A1BatchesFilter} from '../..'
import {DEFAULT_PAGE, DEFAULT_PAGE_SIZE, FORMAT_DATE} from '../../../../../_gori/constants'
import {FEATURES} from '../../../../../_gori/constants/_features'
import {
  convertUserTimeZone,
  isFeatureEnabled,
  KTSVG,
  useDisabled,
} from '../../../../../_gori/helpers'
import {TableSkeleton} from '../../../../../_gori/helpers/components'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import {PageTitle} from '../../../../../_gori/layout/core'
import {
  Button,
  Table,
  TableBody,
  TableHeader,
  TableTabs,
  TableWrapper,
} from '../../../../../_gori/partials/widgets'
import {UploadA1Batch} from '../../../a1'
import {useAuth} from '../../../auth'
import {HelpCenterWidget} from '../../../common/components/HelpCenterWidget'
import {HELP_CENTER_WIDGET_PARAMS} from '../../../orders/core/_const'
import A1_CONSTANTS from '../../core/_const'
import A1BatchService from '../../core/_requests'

const A1BatchesPage: React.FC = () => {
  const intl = useIntl()
  const {loadingSwitch, currentUser} = useAuth()
  const {newCancelToken, isCancel} = useCancelToken()
  const [searchParams] = useSearchParams()
  const {setPageDisabled} = useDisabled()

  const [show, setShow] = useState<{upload: boolean; hasNotice: boolean}>({
    upload: false,
    hasNotice: false,
  })
  const [loadingFirst, setLoadingFirst] = useState<boolean>(true)
  const [statistic, setStatistic] = useState<any>([])
  const [tableData, setTableData] = useState<any>([])
  const [pagination, setPagination] = useState<any>({})

  const columns = useMemo(
    () => [
      {
        id: 'created_at',
        Header: intl.formatMessage({id: 'DATE'}),
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) =>
          row.original.created_at && (
            <span className='fw-bold'>
              {convertUserTimeZone(
                row.original.created_at,
                currentUser,
                process.env.REACT_APP_DATE_FORMAT
              )}
            </span>
          ),
      },
      {
        id: 'code',
        Header: intl.formatMessage({id: 'A1_BATCH_ID'}),
        headerClassName: 'min-w-150px',
        cellClassName: 'fw-bolder fs-6 border-0',
        Cell: ({row}: {row: any}) => <span className='fw-bold'>{row.original.code}</span>,
      },
      {
        id: 'name',
        Header: intl.formatMessage({id: 'A1_BATCH_NAME'}),
        headerClassName: 'min-w-150px',
        cellClassName: 'fw-bolder fs-6 border-0',
        Cell: ({row}: {row: any}) => (
          <Link
            to={`/a1-batches/${row.original.id}`}
            className='text-hover-primary text-dark cursor-pointer'
          >
            {row.original.name}
          </Link>
        ),
      },
      {
        id: 'info',
        Header: intl.formatMessage({id: 'A1_BATCH_INFO'}),
        headerClassName: 'min-w-150px',
        Cell: ({row}: {row: any}) => (
          <span className='d-flex align-items-center'>
            <KTSVG
              path={`/media/gori/a1/${row.original.readied ? 'verify_smooth' : 'incomplete'}.svg`}
              className={clsx('svg-icon-2 text-primary me-2', {
                'text-primary': row.original.readied,
                'text-danger': !row.original.readied,
              })}
            />
            <span className='fs-5 fw-bold'>
              {intl.formatMessage({id: row.original.readied ? 'COMPLETE' : 'INCOMPLETE'})}
            </span>
          </span>
        ),
      },
      {
        id: 'journey_status',
        Header: intl.formatMessage({id: 'STATUS'}),
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) => {
          const journeyStatus = find(Object.values(A1_CONSTANTS.OPTIONS_JOURNEY_STATUS), {
            value: [row.original.journey_status],
          })
          return (
            <span className='d-flex align-items-center'>
              <KTSVG
                path='/media/gori/a1/circle.svg'
                className='svg-icon-8 me-2'
                style={{color: journeyStatus?.color}}
              />
              <span className='fs-5 fw-bold'>
                {journeyStatus && intl.formatMessage({id: journeyStatus?.label})}
              </span>
            </span>
          )
        },
      },
      {
        id: 'pickup_scheduled',
        Header: intl.formatMessage({id: 'PICKUP_SCHEDULED'}),
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) =>
          (row.original?.actual_pickup_at || row.original?.estimated_pickup_at) && (
            <span className='d-flex align-items-center'>
              <span className='fs-5 fw-bold text-gray-700'>
                {convertUserTimeZone(
                  row.original?.actual_pickup_at || row.original?.estimated_pickup_at,
                  currentUser,
                  process.env.REACT_APP_DATE_FORMAT
                )}
              </span>
            </span>
          ),
      },
      {
        id: 'origin',
        Header: intl.formatMessage({id: 'ORIGIN'}),
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) => (
          <span className='d-flex align-items-center'>
            <span className='fs-5 fw-bold text-gray-700'>{row.original?.origin?.airport}</span>
          </span>
        ),
      },
      {
        id: 'destination',
        Header: intl.formatMessage({id: 'DESTINATION'}),
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) => (
          <span className='d-flex align-items-center'>
            <span className='fs-5 fw-bold text-gray-700'>{row.original?.destination?.airport}</span>
          </span>
        ),
      },
      {
        id: 'shipments',
        Header: intl.formatMessage({id: 'SHIPMENTS'}),
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) => (
          <span className='d-flex align-items-center'>
            <span className='fs-5 fw-bold text-gray-700'>{row.original?.shipments}</span>
          </span>
        ),
      },
      {
        id: 'etd',
        Header: intl.formatMessage({id: 'ETD'}),
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) =>
          row.original?.origin?.etd_at && (
            <span className='d-flex align-items-center'>
              <span className='fs-5 fw-bold text-gray-700'>
                {convertUserTimeZone(row.original?.origin?.etd_at, currentUser, FORMAT_DATE.DATE)}
              </span>
            </span>
          ),
      },
      {
        id: 'eta',
        Header: intl.formatMessage({id: 'ETA'}),
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) =>
          row.original?.destination?.eta_at && (
            <span className='d-flex align-items-center'>
              <span className='fs-5 fw-bold text-gray-700'>
                {convertUserTimeZone(
                  row.original?.destination?.eta_at,
                  currentUser,
                  FORMAT_DATE.DATE
                )}
              </span>
            </span>
          ),
      },
    ],
    [currentUser, intl]
  )

  const initFilterValue = useMemo(
    () => ({
      start: moment().subtract(29, 'days').startOf('day').format(),
      end: moment().endOf('day').format(),
    }),
    []
  )

  const setTable = useCallback(async () => {
    setPageDisabled(true)
    const config = {
      params: {
        page: searchParams.get('page') || DEFAULT_PAGE,
        page_size: searchParams.get('page_size') || DEFAULT_PAGE_SIZE,
        search_text: searchParams.get('search_text') || null,
        sort_by: searchParams.get('sort_by') || null,
        sort_direction: searchParams.get('sort_direction') || null,
        status: searchParams.get('status') || null,
        // BEGIN: Order filters
        date_from: searchParams.get('date_from') || initFilterValue.start,
        date_to: searchParams.get('date_to') || initFilterValue.end,
        code: searchParams.get('code') || null,
        name: searchParams.get('name') || null,
        readied: searchParams.get('readied') || null,
        origin: searchParams.get('origin') || null,
        destination: searchParams.get('destination') || null,
        journey_status: searchParams.get('journey_status') || null,
        // END: Order filters
      },
      cancelToken: newCancelToken(),
    }
    try {
      const response = await A1BatchService.getList(config)
      setTableData(response.a1_batches)
      setPagination(response.pagination)
      setStatistic(response.statistics)
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setPageDisabled(false)
      setLoadingFirst(false)
    }
  }, [
    initFilterValue.end,
    initFilterValue.start,
    isCancel,
    newCancelToken,
    searchParams,
    setPageDisabled,
  ])

  useEffect(() => {
    if (loadingSwitch) return

    setTable()
  }, [loadingSwitch, setTable])

  return (
    <>
      <UploadA1Batch
        show={show.upload}
        handleClose={() => setShow((prev) => ({...prev, upload: false}))}
        handleSuccess={() => setTable()}
      />
      <PageTitle>
        <span className='d-flex align-items-center'>
          {intl.formatMessage({id: 'A1_BATCHES'})}
          <KTSVG
            path={`/media/gori/a1/${show.hasNotice ? 'has_notice' : 'no_notice'}.svg`}
            className='svg-icon-1 ms-2 text-gray-700'
          />
        </span>
      </PageTitle>
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        {loadingFirst ? (
          <>
            <div className='mb-5'>
              <div className='btn col-6 col-md-2 placeholder placeholder-lg rounded-2 bg-white' />
            </div>
            <TableWrapper>
              <TableBody>
                <div className='d-flex justify-content-between my-5'>
                  <div className='btn col-2 placeholder placeholder-lg rounded-2 bg-secondary' />
                  <div className='col-6 col-md-6 d-flex justify-content-end gap-2'>
                    <div className='btn col-5 col-md-4 placeholder placeholder-lg rounded-2 bg-secondary' />
                    <div className='btn placeholder placeholder-lg rounded-2 bg-secondary mx-2' />
                    <div className='btn col-3 col-md-2 placeholder placeholder-lg rounded-2 bg-secondary' />
                  </div>
                </div>
                <TableSkeleton countColumn={4} />
              </TableBody>
            </TableWrapper>
          </>
        ) : (
          <>
            <Button
              className='btn btn-white text-gray-700 mb-5'
              label={intl.formatMessage({id: 'UPLOAD_A1_BATCH'})}
              icon={<KTSVG path='/media/gori/a1/logo_upload.svg' className='svg-icon-3' />}
              event={() => setShow((prev) => ({...prev, upload: true}))}
            />
            <TableTabs
              dataTabs={statistic}
              keyCheckActive='status'
              children={
                <TableWrapper className='rounded-top-left-0-dt'>
                  <TableHeader className='min-h-1px my-5 mb-8 d-flex flex-column'>
                    <A1BatchesFilter />
                  </TableHeader>
                  <TableBody>
                    <Table
                      columns={columns}
                      data={tableData}
                      pagination={pagination}
                      usePagination
                    />
                  </TableBody>
                </TableWrapper>
              }
            />
          </>
        )}
      </CSSTransition>
      {isFeatureEnabled(FEATURES.HELP_CENTER_WIDGET) && (
        <HelpCenterWidget data={HELP_CENTER_WIDGET_PARAMS.BATCHES} />
      )}
    </>
  )
}

export {A1BatchesPage}
