import React from 'react'

interface CardLayoutProps {
  children: React.ReactNode
}

const CardLayout: React.FC<CardLayoutProps> = ({children}) => {
  return (
    <div className='d-flex my-9 justify-content-center align-items-center m-auto'>
      <div className='card shadow w-100 p-6' style={{maxWidth: '1227px'}}>
        <div className='card-body display-flex row justify-content-center align-items-start p-0'>
          {children}
        </div>
      </div>
    </div>
  )
}

export default CardLayout
