import {getIn} from 'formik'
import {cloneDeep, compact, isEmpty, isEqual, mapValues, pick} from 'lodash'
import React, {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {ShowAddress} from '../../../../../_gori/components'
import {useStatesGlobal} from '../../../../../_gori/helpers/components/StatesGlobalProvider'
import {SelectFormik} from '../../../../../_gori/partials/widgets'
import {useOrdersProvider} from '../../../../../_gori/providers/OrdersProvider'
import {OrdersConfig} from '../../core/_const'
import {FromOrToModal} from '../../modals/FromOrToModal'

type Props = {
  formik: any
  fromToModal: any
  handleCloseFromToModal: any
  disabled?: boolean
  name?: string
  hasReturn?: boolean
  optionReturn?: string
  hasGetAddress?: boolean
}

const EditAddress: React.FC<Props> = ({
  formik,
  fromToModal,
  handleCloseFromToModal,
  disabled = false,
  name = '',
  hasReturn = false,
  optionReturn = '',
  hasGetAddress = true,
}) => {
  const intl = useIntl()
  const {statesGlobal, getAddress} = useStatesGlobal()
  const address = statesGlobal.address
  const {optionsAddress, setOptionsAddress} = useOrdersProvider()

  const nameReturn = useMemo(() => {
    let _nameReturn = name

    if (hasReturn) {
      _nameReturn = name === 'from' ? 'to' : 'from'
    }

    if (optionReturn) {
      _nameReturn = optionReturn
    }

    return _nameReturn
  }, [hasReturn, name, optionReturn])

  // BEGIN: Field Address
  useEffect(() => {
    if (!hasGetAddress) return
    getAddress(nameReturn)

    return () => {}
  }, [getAddress, hasGetAddress, nameReturn])

  useEffect(() => {
    if (!isEmpty(address?.[nameReturn])) {
      const options = address[nameReturn].map((item) => {
        const {first_name, last_name, company, street1, city, state, zip, is_verified} = item
        const fullName = compact([first_name, last_name]).join(', ')
        const labelOption = (
          <div>
            <div className='fw-bolder'>{company}</div>
            <div className='fs-7'>{fullName}</div>
            <div className='d-block'>{compact([street1, city, state, zip]).join(', ')}</div>
            {is_verified && (
              <div className='text-success'>{intl.formatMessage({id: 'VERIFIED_ADDRESS'})}</div>
            )}
          </div>
        )

        let valueOption = {}
        const customValues = pick(item, [
          ...Object.values(cloneDeep(OrdersConfig.SHIPPING)).map((item) => {
            return item.value
          }),
        ])
        if (customValues) {
          valueOption = Object.entries(customValues).reduce((_values, [key, value]) => {
            switch (key) {
              case OrdersConfig.SHIPPING.RESIDENTIAL.value:
                _values[`${name}_${key}`] = value || false
                break
              case OrdersConfig.SHIPPING.VERIFIED.value:
              case OrdersConfig.SHIPPING.SAVE_ADDRESS.value:
                break
              default:
                _values[`${name}_${key}`] = value || ''
                break
            }
            return _values
          }, {})
        }

        return {
          label: labelOption,
          value: valueOption,
        }
      })

      let addressData = formik?.values?.[nameReturn]
      if (!isEmpty(addressData)) {
        const selectedOption = Array.isArray(options)
          ? options?.find((option) => isEqual(option?.value, addressData))
          : null

        let isCheckData = true
        const allNullExceptResidential = Object.entries(addressData).every(([key, value]) =>
          key === `${nameReturn}_is_residential` ? true : value === null || value === ''
        )
        if (allNullExceptResidential) {
          isCheckData = false
        }

        if (!selectedOption && isCheckData) {
          const fullName = compact([
            addressData?.[`${nameReturn}_first_name`],
            addressData?.[`${nameReturn}_last_name`],
          ]).join(', ')
          const labelOption = (
            <div>
              <div className='fw-bolder'>{addressData?.[`${nameReturn}_company`]}</div>
              <div className='fs-7'>{fullName}</div>
              <div className='d-block'>
                {compact([
                  addressData?.[`${nameReturn}_street1`],
                  addressData?.[`${nameReturn}_city`],
                  addressData?.[`${nameReturn}_state`],
                  addressData?.[`${nameReturn}_zip`],
                ]).join(', ')}
              </div>
              {addressData?.[`${nameReturn}_is_verified`] && (
                <div className='text-success'>{intl.formatMessage({id: 'VERIFIED_ADDRESS'})}</div>
              )}
            </div>
          )
          options.push({
            label: labelOption,
            value: addressData,
          })
        }
      }

      setOptionsAddress((prev) => ({...prev, [nameReturn]: options}))
    }
  }, [address, formik?.values, intl, name, nameReturn, setOptionsAddress])

  const checkValidateFailed = useMemo(() => {
    if (!formik.values[name][`${name}_company`] && !formik.values[name][`${name}_first_name`]) {
      return true
    }
    const validate = [
      `${name}_city`,
      `${name}_state`,
      `${name}_country`,
      `${name}_zip`,
      `${name}_street1`,
    ]
    return validate.some((item) => !formik.values[name][item])
  }, [formik.values, name])
  // END: Field Address

  const handleAddAddress = (nameSave, address) => {
    if (!optionsAddress[nameSave].find((option) => isEqual(option.value, address))) {
      const first_name = address[`${nameSave}_first_name`]
      const last_name = address[`${nameSave}_last_name`]
      const company = address[`${nameSave}_company`]
      const street1 = address[`${nameSave}_street1`]
      const city = address[`${nameSave}_city`]
      const state = address[`${nameSave}_state`]
      const zip = address[`${nameSave}_zip`]
      const is_verified = address[`${nameSave}_is_verified`]

      const fullName = compact([first_name, last_name]).join(', ')
      const labelOption = (
        <div>
          <div className='fw-bolder'>{company}</div>
          <div className='fs-7'>{fullName}</div>
          <div className='d-block'>{compact([street1, city, state, zip]).join(', ')}</div>
          {is_verified && (
            <div className='text-success'>{intl.formatMessage({id: 'VERIFIED_ADDRESS'})}</div>
          )}
        </div>
      )

      const optionEdit = {
        label: labelOption,
        value: address,
      }
      setOptionsAddress((prev) => ({
        ...prev,
        [nameSave]: [optionEdit, ...prev[nameSave]].filter((item) => !isEmpty(item)),
      }))
    }
  }

  const handleSaveModal = (nameSave, address) => {
    const trimmedDataAddress = mapValues(address, (value) => {
      if (typeof value === 'string') {
        return value.trim()
      }
      return value
    })
    handleAddAddress(nameSave, trimmedDataAddress)
    formik.setFieldValue(nameSave, trimmedDataAddress)
    handleCloseFromToModal()
  }

  return (
    <>
      {fromToModal.show && nameReturn === fromToModal.name && (
        <FromOrToModal
          data={fromToModal}
          handleSave={handleSaveModal}
          handleClose={handleCloseFromToModal}
          labelModal={fromToModal?.label}
          disabled={disabled}
          hasReturn={hasReturn}
        />
      )}

      <div className=''>
        <div>
          <SelectFormik
            className='mt-5'
            options={optionsAddress[name]}
            name={name}
            formik={formik}
            required
            placeholder={intl.formatMessage({id: 'PLEASE_CHOOSE'})}
            isSearchable={false}
            emptyDefault={false}
            showMessageError={false}
            disabled={disabled}
          />
        </div>
        {!isEmpty(formik.getFieldProps(name).value) &&
          !optionsAddress[name].find((option) => isEqual(option.value, formik.values?.[name])) && (
            <div className='px-4 mt-2'>
              <ShowAddress data={formik.values[name]} name={name} />
              {formik.getFieldProps(`${name}.${name}_is_verified`).value ? (
                <div className='text-success mt-2'>
                  {intl.formatMessage({id: 'VERIFIED_ADDRESS'})}
                </div>
              ) : null}
            </div>
          )}
        {checkValidateFailed && getIn(formik.touched, name) && (
          <div className='px-4 mt-2'>
            <span className='text-danger d-block fw-bold'>
              {intl.formatMessage(
                {id: 'INPUT_IS_REQUIRED'},
                {
                  input: intl.formatMessage({
                    id: `SHIP_${name?.toUpperCase()}`,
                  }),
                }
              )}
            </span>
          </div>
        )}
      </div>
    </>
  )
}

export {EditAddress}
