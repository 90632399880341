import clsx from 'clsx'
import {isEmpty, orderBy, startCase} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {Button} from '../../../../_gori/partials/widgets'
import {blockPopUp} from '../../../../_gori/helpers/BrowserHelpers'
import {toast} from 'react-toastify'
import ShipmentService from '../../shipments/core/_requests'
import {SET_UP_MODAL_ALL} from '../core/_const'
import OrderService from '../../orders/core/_requests'

type Props = {
  show: boolean
  handleClose: () => void
  arrNotice?: any
  setUpModal: {
    key?: string
    title: string
    success: string
    failed: string
    showPrintLabels: boolean
  }
}

const ActionAllDoneModal: React.FC<Props> = ({show, handleClose, arrNotice = [{}], setUpModal}) => {
  const intl = useIntl()
  const {newCancelToken} = useCancelToken()
  const [loadingPrintAllLabels, setLoadingPrintAllLabels] = useState(false)

  const data = useMemo(() => {
    return orderBy(
      arrNotice,
      [
        (item) => {
          const code = item.data?.code ?? item.data?.order_code
          return code !== null && code !== undefined ? code : Number.MAX_SAFE_INTEGER
        },
        (item) => item.data?.code ?? item.data?.order_code,
      ],
      ['desc']
    )
  }, [arrNotice])

  const handlePrintAllLabels = useCallback(async () => {
    let ids: any = []
    let urls: any = []

    data.forEach((notice: any) => {
      if (notice?.success) {
        switch (setUpModal.key) {
          case SET_UP_MODAL_ALL.CREATE_LABELS.key:
            ids.push(notice?.success.order_id)
            break
          case SET_UP_MODAL_ALL.RETURN_LABELS.key:
            ids.push(notice?.success.shipment_return_id)
            urls.push(notice?.success.label_url)
            break
          case SET_UP_MODAL_ALL.PACKING_SLIPS.key:
            ids.push(notice?.success.packing_slip_id)
            urls.push(notice?.success.packing_slip_url)
            break
          default:
            break
        }
      }
    })

    if (ids.length > 0 || urls.length > 0) {
      setLoadingPrintAllLabels(true)

      const config = {cancelToken: newCancelToken()}
      let response: any
      if (SET_UP_MODAL_ALL.RETURN_LABELS.key === setUpModal.key) {
        response = await ShipmentService.print(
          {
            shipment_ids: ids,
            shipment_labels: urls,
          },
          config
        )
      } else if (SET_UP_MODAL_ALL.PACKING_SLIPS.key === setUpModal.key) {
        response = await ShipmentService.printPackingSlip(
          {
            packing_slip_ids: ids,
            packing_slip_labels: urls,
          },
          config
        )
      } else if (SET_UP_MODAL_ALL.CREATE_LABELS.key === setUpModal.key) {
        response = await OrderService.print(
          {
            order_ids: ids,
          },
          config
        )
      }

      if (!isEmpty(response)) {
        if (blockPopUp(response.label_url)) {
          toast.warning(
            intl.formatMessage({
              id: 'POP_UP_IS_BLOCKED',
            })
          )
        }
      }
      setLoadingPrintAllLabels(false)
    }
  }, [data, intl, newCancelToken, setUpModal.key])

  return (
    <Modal
      id='gori_order_modal_error'
      tabIndex={-1}
      aria-hidden='true'
      centered
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}>
            {intl.formatMessage({id: setUpModal?.title})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='mb-5 vh-75 scroll-y'>
          {!isEmpty(data) && (
            <div className='d-flex justify-content-center flex-column'>
              {data.some((notice: any) => notice?.success) && setUpModal?.showPrintLabels && (
                <Button
                  loading={loadingPrintAllLabels}
                  disabled={loadingPrintAllLabels}
                  event={handlePrintAllLabels}
                  label={intl.formatMessage({
                    id:
                      SET_UP_MODAL_ALL.PACKING_SLIPS.key === setUpModal.key
                        ? 'PRINT_PACKING_SLIPS'
                        : 'PRINT_LABELS',
                  })}
                  loadingText={intl.formatMessage({
                    id:
                      SET_UP_MODAL_ALL.PACKING_SLIPS.key === setUpModal.key
                        ? 'PRINT_PACKING_SLIPS'
                        : 'PRINT_LABELS',
                  })}
                  className='btn btn-primary flex-fill mb-5'
                />
              )}

              {data.map((notice, index) => {
                const orderCode =
                  notice?.data?.order_code || notice?.data?.code || notice?.data?.bae_id
                    ? `${notice?.data?.order_code || notice?.data?.code || notice?.data?.bae_id}:`
                    : ''

                let title = notice?.success
                  ? setUpModal?.key === 'REQUEST_REFUND'
                    ? notice?.success?.status
                    : intl.formatMessage({
                        id: setUpModal?.success,
                      })
                  : intl.formatMessage({
                      id: notice?.error?.data?.error?.message || `${setUpModal?.failed}`,
                    }) ?? `${notice?.error?.data?.error?.message}`

                if (notice?.error?.data?.error?.log_id) {
                  title +=
                    ' ' +
                    intl.formatMessage({id: 'LOG_ID'}, {log_id: notice?.error?.data?.error?.log_id})
                }
                let arrResults: any = [{}]

                if (notice?.error?.data?.error?.fields) {
                  Object.entries(notice?.error?.data?.error?.fields).forEach(
                    ([name, value]: [any, any]) => {
                      if (!name.includes('.') && typeof value !== 'string') {
                        value.forEach((err) => {
                          let arrErrorField = err.split('|')
                          if (arrErrorField.length === 1) {
                            arrResults.push({id: arrErrorField[0]})
                          } else if (arrErrorField.length === 2) {
                            arrResults.push({
                              input: startCase(arrErrorField[0]),
                              id: arrErrorField[1],
                            })
                          } else {
                            arrResults.push({
                              input: startCase(arrErrorField[0]),
                              id: arrErrorField[1],
                              max: arrErrorField[2],
                            })
                          }
                        })
                      } else {
                        value.forEach((err) => {
                          arrResults.push({noIntl: err})
                        })
                      }
                    }
                  )
                }

                arrResults = arrResults.filter((item) => !isEmpty(item))

                return (
                  <div className='custom__timeline__label' key={index}>
                    {title && arrResults && (
                      <div className='custom__timeline__item'>
                        <div className='custom__timeline__badge'>
                          <i className='bi bi-circle'></i>
                        </div>
                        <div className='custom__timeline__content'>
                          <div className={clsx('fw-bolder fs-6 text-nowrap me-2')}>{orderCode}</div>
                          <div className='flex-fill'>
                            <div
                              className={clsx('d-flex justify-content-center fw-bolder fs-6', {
                                'text-danger': !notice.success,
                                'text-dark': notice.success,
                              })}
                            >
                              {title}
                            </div>
                            {arrResults?.length > 0 &&
                              !notice.success &&
                              arrResults.map((err, index) => (
                                <div key={index} className='d-flex'>
                                  <div className='fw-bolder fs-5 text-danger'>
                                    &#8226;{' '}
                                    {err?.max
                                      ? intl.formatMessage(
                                          {id: err.id},
                                          {
                                            input: err.input,
                                            max: err.max,
                                          }
                                        )
                                      : err?.input
                                      ? intl.formatMessage(
                                          {id: err.id},
                                          {
                                            input: err.input,
                                          }
                                        )
                                      : err?.id
                                      ? intl.formatMessage({id: err.id})
                                      : err.noIntl}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          )}
        </Modal.Body>
      </div>
    </Modal>
  )
}

export {ActionAllDoneModal}
