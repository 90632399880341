import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {toast} from 'react-toastify'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../../_gori/hooks/UseYupValidate'
import {useA1BatchesProvider} from '../../../../../_gori/providers/A1BatchesProvider'
import A1BatchService from '../../core/_requests'
import {Button, InputTextFormik, ValidationErrorModal} from '../../../../../_gori/partials/widgets'

type Props = {
  show: boolean
  handleClose: any
}

const A1BatchRenameModal: React.FC<Props> = ({show, handleClose}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const {stringYup} = UseYupValidate()
  const [validationErrors, setValidationErrors] = useState<any>()
  const {a1Batch, setA1Batch} = useA1BatchesProvider()

  const validateSchema = Yup.object({
    name: stringYup(255, 'A1_BATCH_NAME'),
  })

  const formik = useFormik({
    initialValues: {
      name: a1Batch.name,
    },
    enableReinitialize: true,
    validationSchema: validateSchema,
    onSubmit: async (values, {setSubmitting}) => {
      const config = {cancelToken: newCancelToken()}
      try {
        setSubmitting(true)
        const res = await A1BatchService.updateA1Batch(a1Batch.id, {name: values.name}, config)
        if (res) {
          setA1Batch((prev) => ({...prev, name: values.name}))
          toast.success(intl.formatMessage({id: 'RENAMED_A1_BATCH_SUCCESSFULLY'}))
          handleClose()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <Modal
        id='gori_modal_a1_batch_rename'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-600px h-auto'
        show={show}
        backdrop='static'
        onHide={() => handleClose()}
      >
        {validationErrors && (
          <ValidationErrorModal
            handleClose={() => {
              setValidationErrors(undefined)
            }}
            response={validationErrors}
          />
        )}
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'RENAME_A1_BATCH'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3 className='fw-bolder fs-4'>
              {intl.formatMessage({id: 'GIVE_A_UNIQUE_NAME_FOR_YOUR_A1_BATCH'})}:
            </h3>
            <InputTextFormik
              className='w-75'
              labelClassName='form-label'
              required
              formik={formik}
              name='name'
              placeholder='100000'
            />
          </Modal.Body>
          <Modal.Footer>
            <div className='row'>
              <div className='d-flex justify-content-end'>
                <Button
                  className='btn btn-secondary me-3'
                  label={intl.formatMessage({id: 'CANCEL'})}
                  event={() => handleClose()}
                  disabled={formik.isSubmitting}
                />
                <Button
                  className='btn btn-primary'
                  label={intl.formatMessage({id: 'SUBMIT'})}
                  loadingText={intl.formatMessage({id: 'SUBMIT'})}
                  loading={formik.isSubmitting}
                  disabled={formik.isSubmitting}
                  event={formik.handleSubmit}
                />
              </div>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {A1BatchRenameModal}
