import {OPTION_COUNTRIES} from '../../../../_gori/constants'

export const STEPS = {
  welcome: 0,
  single_step: 1,
}

export const OPTIONS_SIGNATURE = [
  {
    label: 'SIGNATURE_WRITE',
    value: 'write',
  },
  {
    label: 'SIGNATURE_DRAWING',
    value: 'drawing',
  },
]

export const OPTIONS_SERVICES = [
  {
    label: 'USPS',
    value: 'usps',
  },
  {
    label: 'UPS',
    value: 'ups',
  },
  {
    label: 'FedEx',
    value: 'fedex',
  },
  {
    label: 'DHL',
    value: 'dhl',
  },
]

export const OPTIONS_METHODS = [
  {
    label: 'ShipBae',
    value: 'shipbae',
  },
  {
    label: 'Gori API',
    value: 'gori_api',
  },
]

export const PROFILE_FIELD = {
  SALES: [
    {
      name: 'sales_rep',
      label: 'GORI_SALE_REP',
      type: 'text',
      disabled: false,
      required: false,
      placeholder: 'GORI_SALE_REP',
      optional_label: 'OPTIONAL_LABEL',
    },
    {
      name: 'promo_code',
      label: 'PROMO_CODE',
      type: 'text',
      disabled: false,
      required: false,
      placeholder: 'PROMO_CODE_LABEL',
      optional_label: 'OPTIONAL_LABEL',
      options: [],
    },
  ],
  BUSINESS_ADDRESS: [
    {
      name: 'country',
      label: 'COUNTRY',
      type: 'select',
      required: true,
      options: OPTION_COUNTRIES,
      col: 'col-3',
    },
    {
      name: 'street1',
      label: 'ADDRESS',
      type: 'address',
      required: true,
      col: 'col-6',
    },
    {
      name: 'street2',
      label: 'ADDRESS_2',
      type: 'address',
      required: false,
      col: 'col-3',
    },
    {
      name: 'city',
      label: 'CITY',
      type: 'text',
      required: true,
      col: 'col-3',
    },
    {
      name: 'zip',
      label: 'POSTAL_CODE',
      type: 'text',
      required: true,
      col: 'col-3',
    },
    {
      name: 'state',
      label: 'STATE',
      type: 'text',
      required: true,
      col: 'col-3',
    },
  ],
}
