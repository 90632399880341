import {AxiosRequestConfig} from 'axios'
import RequestBase from '../../../api/RequestBase'

const BASE_URL = `users`

function getUser(config?: AxiosRequestConfig | undefined) {
  return RequestBase.get(`/${BASE_URL}`, config)
}

function getMeter(currentAccount: string) {
  return RequestBase.get(`/${BASE_URL}/meter${currentAccount ? `?selected=${currentAccount}` : ''}`)
}

function getListUser(config?: AxiosRequestConfig | undefined) {
  return RequestBase.get(`/${BASE_URL}/list`, config)
}

function getListUserGlobal(currentAccount: string, config?: AxiosRequestConfig | undefined) {
  return RequestBase.get(`/${BASE_URL}/list?selected=${currentAccount}`, config)
}

function showUser(userId?: any, config?: AxiosRequestConfig | undefined) {
  return RequestBase.get(`/${BASE_URL}/show/${userId}`, config)
}

function getPermissions(config?: AxiosRequestConfig | undefined) {
  return RequestBase.get(`/permissions`, config)
}

const update = (userId?: any, payload?: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`/${BASE_URL}/update/${userId}`, payload, config)
}

const invite = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`/${BASE_URL}/invite`, payload, config)
}

function removeFromCompany(userId) {
  return RequestBase.remove(`/${BASE_URL}/remove/${userId}`)
}

const resend = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`/${BASE_URL}/resend`, payload, config)
}

const cancel = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`/${BASE_URL}/cancel`, payload, config)
}

function getTutorialCard(config?: AxiosRequestConfig | undefined) {
  return RequestBase.get(`/${BASE_URL}/profile/tutorial-cards`, config)
}

function removeTutorialCard(payload: any, config?: AxiosRequestConfig | undefined) {
  return RequestBase.put(`/${BASE_URL}/profile/tutorial-card`, payload, config)
}

const UserService = {
  getUser,
  getMeter,
  getListUser,
  getPermissions,
  showUser,
  update,
  getListUserGlobal,
  invite,
  removeFromCompany,
  resend,
  cancel,

  // UserProfile
  getTutorialCard,
  removeTutorialCard,
}

export default UserService
