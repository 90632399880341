import {useFormik} from 'formik'
import {cloneDeep, find, isEmpty} from 'lodash'
import React, {useEffect, useMemo, useState} from 'react'
import {Container, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {OPTION_COUNTRIES} from '../../../../../_gori/constants'
import {useStatesGlobal} from '../../../../../_gori/helpers/components/StatesGlobalProvider'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../../_gori/hooks/UseYupValidate'
import {
  Button,
  InputTextFormik,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../../_gori/partials/widgets'
import {OPTIONS_CONTENT_TYPE, OrdersConfig} from '../../../orders/core/_const'
import A1_CONSTANTS from '../../core/_const'
import A1BatchService from '../../core/_requests'
import {toast} from 'react-toastify'

type Props = {
  show: boolean
  handleClose: () => void
  data: {ids: Array<number>; type: string}
  handleSuccess: () => void
}

const A1MassUpdateModal: React.FC<Props> = ({show, handleClose, data, handleSuccess}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const {stringYup, regexYup, orderYup} = UseYupValidate()
  const {statesGlobal, getAddress} = useStatesGlobal()
  const address = statesGlobal.address
  const carriers = statesGlobal.carriers
  const [validationErrors, setValidationErrors] = useState<any>()
  const [loading, setLoading] = useState<{update: boolean}>({update: false})

  const handleCloseModal = () => {
    handleClose()
    formik.resetForm()
  }

  const initValidateSchema = useMemo(() => {
    return {
      type: Yup.string(),
      to: Yup.object().when(['type'], {
        is: (type) => type === 'to',
        then: Yup.object()
          .nullable()
          .required(
            intl.formatMessage(
              {id: 'INPUT_IS_REQUIRED'},
              {input: intl.formatMessage({id: 'SHIP_TO'})}
            )
          ),
        otherwise: Yup.object().nullable(),
      }),
      content: Yup.string()
        .nullable()
        .when('type', {
          is: (type) => type === 'content',
          then: orderYup.orderA1.content,
        }),
      description: Yup.string()
        .nullable()
        .when('type', {
          is: (type) => type === 'description',
          then: orderYup.orderA1.description,
        }),
      hs_tariff_number: Yup.string()
        .nullable()
        .when('type', {
          is: (type) => type === 'hs_tariff_number',
          then: orderYup.orderA1.htsCode(),
        }),
      container_id: Yup.string()
        .nullable()
        .when('type', {
          is: (type) => type === 'container_id',
          then: orderYup.orderA1.containerId(),
        }),
      country: Yup.string()
        .nullable()
        .when('type', {
          is: (type) => type === 'country',
          then: regexYup.country('COUNTRY_OF_ORIGIN'),
        }),
      quantity: Yup.number()
        .nullable()
        .when('type', {
          is: (type) => type === 'quantity',
          then: orderYup.orderA1.quantity,
        }),
      value: Yup.number()
        .nullable()
        .when('type', {
          is: (type) => type === 'value',
          then: orderYup.orderA1.value,
        }),
      weight: Yup.number()
        .nullable()
        .when('type', {
          is: (type) => type === 'weight',
          then: orderYup.orderA1.weight,
        }),
      service: Yup.string()
        .nullable()
        .when('type', {
          is: (type) => type === 'service',
          then: stringYup(255, 'SERVICE'),
        }),
    }
  }, [intl, orderYup.orderA1, regexYup, stringYup])

  const formik = useFormik({
    initialValues: {
      type: data?.type,
      to: null,
      content: null,
      description: null,
      hs_tariff_number: null,
      container_id: null,
      country: null,
      quantity: null,
      value: null,
      weight: null,
      service: null,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape(initValidateSchema),
    onSubmit: async (values) => {
      const config = {cancelToken: newCancelToken()}
      let payload: any = {
        order_ids: data.ids,
      }
      Object.entries(A1_CONSTANTS.MASS_UPDATE).forEach(([key, field], index) => {
        switch (field.value) {
          case 'to':
            if (values.type === field.value && values?.to) {
              payload['type'] = 'address'
              payload['fields'] = Object.entries(OrdersConfig.SHIPPING).reduce(
                (acc, [key, field]) => {
                  switch (field.value) {
                    case OrdersConfig.SHIPPING.VERIFIED.value:
                    case OrdersConfig.SHIPPING.RESIDENTIAL.value:
                      acc[`to_${field?.value}`] = values?.to?.[field.value] || false
                      break
                    case OrdersConfig.SHIPPING.SAVE_ADDRESS.value:
                      acc[`to_${field?.value}`] = !!values?.to?.[field.value]
                      break
                    default:
                      acc[`to_${field?.value}`] = values?.to?.[field.value] || ''
                      break
                  }
                  return acc
                },
                {}
              )
            }
            break
          case 'service':
            if (values.type === field.value) {
              payload['type'] = 'service'
              payload['fields'] = {service: values?.service}
            }
            break
          default:
            if (values.type === field.value) {
              payload['type'] = 'a1'
              payload['fields'] = {[values.type]: values?.[values.type]}
            }
            break
        }
      })

      try {
        setLoading((prev) => ({...prev, update: true}))
        const {message} = await A1BatchService.massUpdateOrderA1Batch(payload, config)
        if (message) {
          toast.success(intl.formatMessage({id: message}))
          handleSuccess()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, update: false}))
      }
    },
  })

  // BEGIN: Options address
  useEffect(() => {
    if (data?.type !== 'to') return
    getAddress(data?.type)

    return () => {}
  }, [data?.type, getAddress])

  const optionsAddress = useMemo(() => {
    if (data?.type !== 'to' || !address) return []

    return address[data.type].map((item) => ({
      label: (
        <div>
          <span>{item.company}</span>
          <span className='d-block fw-bold'>
            {`${item.street1} ${item.city} ${item.state} ${item.zip}`}
          </span>
        </div>
      ),
      value: item,
    }))
  }, [address, data.type])
  // END: Options address

  // BEGIN: Options service
  const optionsService = useMemo(() => {
    if (isEmpty(carriers)) return
    const domesticCarriers = Object.values(cloneDeep(carriers)).filter(
      (carrier: any) => !carrier?.international && carrier?.status
    )

    return [
      {
        label: intl.formatMessage({id: 'DOMESTIC'}),
        options: domesticCarriers.map((carrier: any) => ({
          label: carrier?.visuals?.display_name,
          value: carrier?.carrier_service,
        })),
      },
    ]
  }, [carriers, intl])
  // END: Options service and package

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='gori_modal_mass_update_order'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-600px h-auto'
        show={show}
        backdrop='static'
        onHide={() => {
          handleClose()
        }}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'MASS_UPDATE'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div className='d-flex justify-content-between align-items-center'>
                <span className='fs-2 fw-bolder'>
                  {intl.formatMessage({
                    id: find(A1_CONSTANTS.MASS_UPDATE, {value: data.type})?.label || 'MASS_UPDATE',
                  })}
                </span>
                <span className='fw-bold text-primary'>
                  {intl.formatMessage(
                    {id: 'THIS_WILL_APPLY_TO_INPUT_SELECTED_SHIPMENTS'},
                    {input: data?.ids?.length}
                  )}
                </span>
              </div>
              {Object.entries(A1_CONSTANTS.MASS_UPDATE).map(([key, field], index) => {
                switch (field.value) {
                  case 'content':
                  case 'to':
                  case 'country':
                  case 'service':
                    let options
                    let hasUseIntl = true

                    switch (field.value) {
                      case 'content':
                        options = OPTIONS_CONTENT_TYPE
                        break
                      case 'to':
                        options = optionsAddress
                        break
                      case 'country':
                        options = OPTION_COUNTRIES
                        hasUseIntl = false
                        break
                      case 'service':
                        options = optionsService
                        hasUseIntl = false
                        break
                    }

                    return (
                      field.value === data?.type && (
                        <div className='mt-2' key={index}>
                          <SelectFormik
                            placeholder={intl.formatMessage({id: 'PLEASE_CHOOSE'})}
                            formik={formik}
                            options={options || []}
                            required
                            name={field.value}
                            hasUseIntl={hasUseIntl}
                            emptyDefault={false}
                            isSearchable={false}
                          />
                        </div>
                      )
                    )
                  case 'description':
                  case 'hs_tariff_number':
                  case 'container_id':
                    return (
                      field.value === data?.type && (
                        <InputTextFormik className='mt-2' formik={formik} name={field.value} />
                      )
                    )

                  case 'quantity':
                  case 'value':
                  case 'weight':
                    return (
                      field.value === data?.type && (
                        <div className='mt-2' key={index}>
                          <InputTextFormik
                            type='number'
                            min={0}
                            formik={formik}
                            name={field.value}
                          />
                        </div>
                      )
                    )
                  default:
                    return null
                }
              })}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
                disabled={loading.update}
              />
              <Button
                className={'btn btn-primary'}
                label={intl.formatMessage({id: 'UPDATE'})}
                loadingText={intl.formatMessage({id: 'UPDATE'})}
                event={formik.handleSubmit}
                disabled={loading.update}
                loading={loading.update}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {A1MassUpdateModal}
