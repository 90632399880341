/* eslint-disable no-useless-escape */
export const REGEX_PASSWORD = new RegExp(
  /^(?=.*[a-z])(?=.*\d)[a-z\d!@#$%^&*()_+-={}[\];:,.<>?/\\'"|]{8,}$/,
  'iu'
)
export const REGEX_URL = new RegExp(
  /[(http(s)?:\/\/(www\.)?a-z0-9@:%\._-]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/=]*)/,
  'iu'
)
export const REGEX_EMAIL = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'iu')
export const REGEX_PHONE = new RegExp(/^[0-9()+\-.\s]*$/, 'iu')
export const REGEX_STATE = new RegExp(/^[a-z]{2}$/, 'iu')
export const REGEX_ZIP_CODE = new RegExp(/^[0-9]{5}(-[0-9]{4})?$/, 'iu')
export const REGEX_HTS_CODE = new RegExp(/^\d{4}\.\d{2}\.\d{4}$/, 'iu')
export const REGEX_SHOPIFY_SUBDOMAIN = new RegExp(
  /^(http(s)?:\/\/)?[^.\s]+\.myshopify\.com(\/)?$/,
  'iu'
)
export const REGEX_INPUT_TEXT = new RegExp(/^(?!.*'{2,})[\p{L}'\s]+$/, 'u')
export const REGEX_CVV = new RegExp(/^[0-9]{3,4}$/, 'u')
export const REGEX_ALPHANUMERIC = new RegExp(/^[a-z0-9]+$/, 'iu')
export const REGEX_ORDER_CHILD = new RegExp(/^\/orders\/[^/]+$/, 'u')
