import clsx from 'clsx'
import {isEmpty} from 'lodash'
import React, {useCallback, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {FORMAT_DATE} from '../../../../../_gori/constants'
import {convertUserTimeZone, KTSVG} from '../../../../../_gori/helpers'
import {useA1BatchesProvider} from '../../../../../_gori/providers/A1BatchesProvider'
import {useAuth} from '../../../auth'
import {AddressView} from '../../../orders'
import A1_CONSTANTS from '../../core/_const'

type Props = {
  handleEditJourneyInfo: () => void
}

const JourneyInfoCard: React.FC<Props> = ({handleEditJourneyInfo}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {a1Batch} = useA1BatchesProvider()

  const formattedDate = useCallback(
    (date) => (date ? convertUserTimeZone(date, currentUser, FORMAT_DATE.DATE_TIME) : null),
    [currentUser]
  )

  const formatNumber = useCallback(
    ({number, showDecimal = false}: {number: string | number; showDecimal?: boolean}) => {
      if (!number || number === '0.00') return null
      return intl.formatNumber(Number(number), {
        style: 'decimal',
        minimumFractionDigits: showDecimal ? 2 : 0,
        maximumFractionDigits: 2,
      })
    },
    [intl]
  )

  const showInfo = useMemo(() => {
    const flights = a1Batch?.flights?.reduce(
      (acc, flight) => ({
        ...acc,
        [flight.type]: flight,
      }),
      {}
    )

    return {
      label: {
        go: flights?.origin?.actual_departure_at ? 'ATD' : 'ETD',
        arrive: flights?.destination?.actual_arrival_at ? 'ATA' : 'ETA',
      },
    }
  }, [a1Batch?.flights])

  const showShipFrom = useMemo(() => {
    return isEmpty(a1Batch.from) ? null : <AddressView data={a1Batch.from} />
  }, [a1Batch.from])

  return (
    <div className='card me-xxl-4 h-100'>
      <div className='card-header border-0 fw-bolder d-flex justify-content-between align-items-center'>
        <span className='fs-3 required'>{intl.formatMessage({id: 'JOURNEY_INFO'})}</span>
        {a1Batch?.journey_status === A1_CONSTANTS.OPTIONS_JOURNEY_STATUS.N_A.value[0] &&
          a1Batch.readied && (
            <div
              className='btn btn-sm btn-light-primary rounded-pill'
              onClick={handleEditJourneyInfo}
            >
              {intl.formatMessage({id: 'EDIT'})}
            </div>
          )}
      </div>
      <div className='card-body bg-light rounded-bottom d-flex flex-column flex-md-row'>
        <div className='col-12 col-md-6'>
          <div className='d-flex pe-md-6 text-primary fs-5 fs-xxl-7 text-center fw-bolder'>
            <div className='col-4'>{intl.formatMessage({id: 'JOURNEY'})}</div>
            <div className='col-4'>{intl.formatMessage({id: 'FLIGHT_NUMBER'})}</div>
            <div className='col-4'>
              {showInfo.label.go} &#8226; {showInfo.label.arrive}
            </div>
          </div>
          <div className='d-flex flex-column min-h-150px mh-175px scroll-y pe-md-6 mt-4'>
            {!isEmpty(a1Batch?.flights) &&
              a1Batch?.flights?.map((flight: any, index) => {
                return (
                  <div
                    key={index}
                    className={clsx('d-flex flex-column', {
                      'flex-column-fluid': index !== a1Batch?.flights?.length - 1,
                    })}
                  >
                    <div className='col-4 text-center'>
                      <div className='fs-1 fw-boldest'>{flight?.airport}</div>
                    </div>
                    {index !== a1Batch?.flights?.length - 1 && (
                      <div className='d-flex flex-fill'>
                        <div className='col-4 d-flex flex-column justify-content-center align-items-center'>
                          <div className='border-dashed border-gray-600 border-2 flex-fill h-100' />
                        </div>
                        <div className='col-4 d-flex align-items-center justify-content-center fw-bolder gap-2'>
                          <KTSVG
                            path='/media/gori/a1/flight.svg'
                            className='svg-icon-1 text-gray-700'
                          />
                          <span className='fs-4 fs-xxl-6 text-truncate'>
                            {flight?.flight_number}
                          </span>
                        </div>
                        <div className='col-4 d-flex flex-column justify-content-center text-center fs-6 fs-xxl-8 fw-bold gap-2 text-gray-700'>
                          <span>
                            {formattedDate(
                              flight?.actual_departure_at || flight?.estimated_departure_at
                            )}
                          </span>
                          {flight?.est_duration && (
                            <span className='fw-boldest text-dark'>{flight?.est_duration}h</span>
                          )}
                          <span>
                            {formattedDate(
                              a1Batch?.flights[index + 1]?.actual_arrival_at ||
                                a1Batch?.flights[index + 1]?.estimated_arrival_at
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
          </div>
        </div>
        <span className='border border-secondary my-8 my-md-n8' />
        <div className='col-12 col-md-6 ps-6 fs-5 fs-xxl-7 fw-bold scroll-x'>
          <div className='col-12 d-flex border-bottom border-secondary'>
            <div className='col-3'>{intl.formatMessage({id: 'SHIP_FROM'})}</div>
            {showShipFrom && <span className='fs-5 fs-xxl-7 fw-boldest'>{showShipFrom}</span>}
          </div>
          <div className='col-12 d-flex border-bottom border-secondary mt-10'>
            <div className='col-3'>{intl.formatMessage({id: 'A1_SERVICE'})}</div>
            {a1Batch?.services?.service && (
              <span className='fs-5 fs-xxl-7 fw-boldest'>{a1Batch?.services?.service}</span>
            )}
          </div>
          <div className='d-flex mt-10'>
            <span className='min-w-50 d-flex justify-content-between'>
              <span className='col-3 border-bottom border-secondary'>MAWB</span>
              <span className='flex-fill border-bottom border-secondary ps-2' />
              <span className='fw-boldest border-bottom border-secondary pe-4 text-truncate'>
                {a1Batch?.mawb}
              </span>
            </span>
            <span className='min-w-50 d-flex justify-content-between'>
              <span className='col-6 border-bottom border-secondary text-nowrap'>
                # {intl.formatMessage({id: 'OF_PALLETS'})}
              </span>
              <span className='flex-fill border-bottom border-secondary ps-2' />
              <span className='fw-boldest border-bottom border-secondary'>
                {formatNumber({number: a1Batch?.origin?.packaging?.pallets})}
              </span>
            </span>
          </div>
          <div className='d-flex mt-10'>
            <span className='min-w-50 d-flex justify-content-between'>
              <span className='col-6 border-bottom border-secondary text-nowrap'>
                # {intl.formatMessage({id: 'OF_PARCELS'})}
              </span>
              <span className='flex-fill border-bottom border-secondary ps-2' />
              <span className='fw-boldest border-bottom border-secondary pe-4 text-truncate'>
                {formatNumber({number: a1Batch?.origin?.packaging?.parcels})}
              </span>
            </span>
            <span className='min-w-50 d-flex justify-content-between'>
              <span className='col-6 border-bottom border-secondary'>
                {intl.formatMessage({id: 'KGS'})}
              </span>
              <span className='flex-fill border-bottom border-secondary ps-2' />
              <span className='fw-boldest border-bottom border-secondary'>
                {formatNumber({
                  number: a1Batch?.origin?.packaging?.weight?.value,
                  showDecimal: true,
                })}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export {JourneyInfoCard}
