import clsx from 'clsx'
import {isUndefined, omit} from 'lodash'
import {useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import {DEFAULT_PAGE} from '../../../../../_gori/constants'
import {KTSVG} from '../../../../../_gori/helpers'
import {DatePicker} from '../../../../../_gori/helpers/components/DatePicker'
import {InputSearch, SelectMultiDropdown} from '../../../../../_gori/partials/widgets'
import {InputFilter} from '../../../../../_gori/partials/widgets/form/InputFilter'
import {SaveFilterButton} from '../../../orders'
import A1_CONSTANTS from '../../core/_const'

const A1BatchesFilter = () => {
  const intl = useIntl()
  const [searchParams, setSearchParams] = useSearchParams()
  const [clickFilter, setClickFilter] = useState<boolean>(false)

  const [showModal, setShowModal] = useState<{
    save_current_filter: boolean
  }>({
    save_current_filter: false,
  })

  const initFilterOrder = useMemo(() => {
    return A1_CONSTANTS.PARAMS_A1_BATCHES_FILTER.reduce((acc, param) => {
      acc[param] = searchParams.get(param) || undefined
      return acc
    }, {})
  }, [searchParams])

  const hasFilter = useMemo(() => {
    return Object.values(initFilterOrder).some((filter) => !isUndefined(filter))
  }, [initFilterOrder])

  const [dataFilters, setDataFilters] = useState<{[key: string]: any}>(initFilterOrder)

  useEffect(() => {
    setDataFilters(initFilterOrder)
  }, [initFilterOrder])

  const handleFiltersOrder = (key: string, value: any) => {
    value = value.toString()
    value.trim() ? searchParams.set(key, value.trim()) : searchParams.delete(key)
    searchParams.set('page', DEFAULT_PAGE)
    setSearchParams(searchParams)
  }

  const handleClearAll = () => {
    A1_CONSTANTS.PARAMS_A1_BATCHES_FILTER.forEach((param) => searchParams.delete(param))
    setSearchParams(searchParams)
    setClickFilter(false)
  }

  return (
    <>
      <div className='d-flex flex-wrap flex-fill justify-content-between'>
        <div className='d-flex align-items-center position-relative my-1'>
          <InputSearch placeholder={intl.formatMessage({id: 'SEARCH_A1_BATCH'})} />
        </div>
        <div className='d-flex flex-wrap'>
          <DatePicker
            dateFrom='date_from'
            dateTo='date_to'
            name={intl.formatMessage({id: 'A1_BATCHES_DATE'})}
            className='m-2 bg-input-dark'
          />
          <div className='d-flex justify-content-center align-items-center'>
            <div
              className={clsx(
                'btn btn-icon border border-2 border-light btn-active-light-primary m-2',
                {
                  'border border-2 border-primary rounded-2 active': clickFilter,
                  'bg-primary text-hover-primary': hasFilter && !clickFilter,
                }
              )}
              onClick={() => setClickFilter(!clickFilter)}
            >
              <KTSVG
                path='/media/gori/orders/filter.svg'
                className={clsx('svg-icon svg-icon-1', {
                  'text-white': hasFilter && !clickFilter,
                })}
                small={false}
              />
            </div>
          </div>
          <SaveFilterButton
            setClickFilter={setClickFilter}
            showModalSaveCurrentFilter={showModal.save_current_filter}
            setShowModalSaveCurrentFilter={setShowModal}
            dataFilters={dataFilters}
            handleClearAll={handleClearAll}
            className='m-2 bg-input-dark'
            filterKey='a1_batch_filter'
            paramsOptions={A1_CONSTANTS.PARAMS_A1_BATCHES_FILTER}
          />
        </div>
      </div>
      {clickFilter && (
        <div className='d-flex flex-wrap justify-content-end'>
          <span
            className='text-gray-600 text-hover-danger m-2 d-flex align-items-center cursor-pointer'
            onClick={handleClearAll}
          >
            <span className='fw-bolder me-2'>{intl.formatMessage({id: 'CLEAR_ALL'})}</span> X
          </span>
          {hasFilter && (
            <span
              className='text-primary bg-hover-light px-2 rounded-2 m-2 d-flex align-items-center cursor-pointer'
              onClick={() => setShowModal((prev) => ({...prev, save_current_filter: true}))}
            >
              <span className='fw-bolder me-2'>
                {intl.formatMessage({id: 'SAVE_CURRENT_FILTER'})}
              </span>
            </span>
          )}
          <InputFilter
            className='m-2 bg-input-dark rounded-2'
            name={intl.formatMessage({id: 'A1_BATCH_ID'})}
            valueInput={dataFilters.code}
            setValueInput={(value) => {
              handleFiltersOrder('code', value)
            }}
          />
          <InputFilter
            className='m-2 bg-input-dark rounded-2'
            name={intl.formatMessage({id: 'A1_BATCH_NAME'})}
            valueInput={dataFilters.name}
            setValueInput={(value) => {
              handleFiltersOrder('name', value)
            }}
          />
          <SelectMultiDropdown
            className='m-2 bg-input-dark'
            name={intl.formatMessage({id: 'A1_BATCH_INFO'})}
            dataOptions={Object.values(A1_CONSTANTS.OPTIONS_JOURNEY_INFO)}
            valueOptions={dataFilters.readied}
            handleSetValueOptions={(value) => handleFiltersOrder('readied', value)}
            hasClearValue
            hasUseIntl
            isMultiSelect={false}
          />
          <SelectMultiDropdown
            className='m-2 bg-input-dark'
            name={intl.formatMessage({id: 'JOURNEY_STATUSES'})}
            dataOptions={Object.values(omit(A1_CONSTANTS.OPTIONS_JOURNEY_STATUS, 'N_A')).map(
              (item: any) => ({
                ...item,
                value: item.value[0],
              })
            )}
            valueOptions={dataFilters.journey_status}
            handleSetValueOptions={(value) => handleFiltersOrder('journey_status', value)}
            hasClearValue
            hasUseIntl
          />
          <SelectMultiDropdown
            className='m-2 bg-input-dark'
            name={intl.formatMessage({id: 'ORIGIN_AIRPORT'})}
            dataOptions={A1_CONSTANTS.OPTIONS_ORIGIN}
            valueOptions={dataFilters.origin}
            handleSetValueOptions={(value) => handleFiltersOrder('origin', value)}
            hasClearValue
          />
          <SelectMultiDropdown
            className='m-2 bg-input-dark'
            name={intl.formatMessage({id: 'DESTINATION_AIRPORT'})}
            dataOptions={A1_CONSTANTS.OPTIONS_DESTINATION}
            valueOptions={dataFilters.destination}
            handleSetValueOptions={(value) => {
              handleFiltersOrder('destination', value)
            }}
            hasClearValue
          />
        </div>
      )}
    </>
  )
}

export {A1BatchesFilter}
