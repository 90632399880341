import axios from 'axios'
import clsx from 'clsx'
import {cloneDeep, isEmpty, isEqual, size} from 'lodash'
import {useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../app/modules/auth'
import UserService from '../../../app/modules/users/core/_requests'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import {resetClickEvents} from '../CommonHelpers'
import {KTSVG} from './KTSVG'
import {useMeter} from './MeterProvider'

type Props = {
  className?: string
  classNameMenu?: string
  isModeLaptop?: boolean
}

const SIGNATURE_ACCESS_KEY = process.env.REACT_APP_SIGNATURE_ACCESS_KEY

const SwitchCompany: React.FC<Props> = ({
  className = '',
  classNameMenu = '',
  isModeLaptop = false,
}) => {
  const intl = useIntl()
  const {currentUser, handleSaveCurrentUser, currentAccount, loadingSwitch, setLoadingSwitch} =
    useAuth()
  const {setMeterChange} = useMeter()
  const [valueSearch, setValueSearch] = useState('')
  const {isMobileTablet, isWindows} = useWindowDimensions()

  const listCompany = useMemo(() => {
    return currentUser?.accounts?.map((item: any) => ({
      label: item.name,
      value: item,
    }))
  }, [currentUser?.accounts])

  const handleSwitchCompany = async (currentAccount: any) => {
    axios.defaults.headers.common[`${SIGNATURE_ACCESS_KEY}`] = currentAccount?.encrypt_key
    setLoadingSwitch(true)
    resetClickEvents()
    try {
      const user = await UserService.getUser()
      await handleSaveCurrentUser(user)
      await setMeterChange(true)
    } catch (error) {
    } finally {
      setLoadingSwitch(false)
    }
  }

  const customsData = useMemo(() => {
    if (!valueSearch) return listCompany
    return cloneDeep(listCompany)?.filter(({label}) => {
      return label.toLowerCase().includes(valueSearch.toLowerCase().trim())
    })
  }, [listCompany, valueSearch])

  return (
    <div className={clsx(className)}>
      <div
        className={clsx(
          'd-flex justify-content-between align-items-center border text-primary rounded-2 px-5 py-3',
          {
            'cursor-pointer border-hover-primary bg-hover-light-primary': size(listCompany) > 1,
            'cursor-no-drop border-hover-info bg-hover-light-secondary': size(listCompany) <= 1,
          }
        )}
        data-kt-menu-trigger={size(listCompany) > 1 && 'click'}
        data-kt-menu-overflow='false'
        data-kt-menu-placement={(isMobileTablet && 'left') || (isWindows && 'right')}
      >
        <span className='fw-bolder'>{currentAccount?.name}</span>
        <KTSVG path='/media/gori/arrows/arrow_right.svg' className='svg-icon-2' />
      </div>
      {size(listCompany) > 1 && (
        <div
          className={clsx(
            'menu menu-sub menu-sub-dropdown menu-column menu-rounded ms-9 fs-6 w-300px h-180px scroll-y',
            classNameMenu,
            {'mt-8': isModeLaptop}
          )}
          data-kt-menu='true'
        >
          <div className='px-3 py-2 sticky-top bg-white'>
            <input
              type='text'
              className='form-control'
              onChange={(e) => setValueSearch(e.target.value)}
              value={valueSearch}
            />
          </div>
          {isEmpty(customsData) ? (
            <div className='d-flex flex-fill justify-content-center align-items-center fs-3 text-gray-600'>
              {intl.formatMessage({id: 'NOT_FOUND'})}
            </div>
          ) : (
            customsData?.map((company) => (
              <div className='px-3'>
                <div
                  className={clsx(
                    'd-flex align-items-center justify-content-between p-3 rounded-2 flex-fill text-truncate overflow-auto',
                    {
                      'bg-primary text-white cursor-no-drop': isEqual(
                        company.value,
                        currentAccount
                      ),
                      'bg-hover-light-primary text-hover-primary': !isEqual(
                        company.value,
                        currentAccount
                      ),
                    }
                  )}
                  onClick={() => {
                    if (!isEqual(company.value, currentAccount) && !loadingSwitch) {
                      handleSwitchCompany(company.value)
                    }
                  }}
                >
                  {company.label}
                  {isEqual(company.value, currentAccount) && (
                    <KTSVG
                      path='/media/gori/valid_current_color.svg'
                      className='svg-icon-3 text-white'
                    />
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  )
}

export {SwitchCompany}
