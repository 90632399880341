import clsx from 'clsx'
import {useFormik} from 'formik'
import {isEmpty, isEqual} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../_gori/hooks/UseYupValidate'
import {Button, ValidationErrorModal} from '../../../../_gori/partials/widgets'
import {EditShipAddress} from '../components/EditShipAddress'
import {OrdersConfig} from '../core/_const'
import OrderService from '../core/_requests'
import {AddressValidationModal} from './AddressValidationModal'

type Props = {
  show: boolean
  handleClose: () => void
  disabled?: boolean
  data: any
  handleToEditSuccess: () => void
}

const FromToEditModal: React.FC<Props> = ({
  show,
  handleClose,
  disabled,
  data,
  handleToEditSuccess,
}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const {stringYup, orderYup, regexYup, infoYup} = UseYupValidate()
  const [action, setAction] = useState<'save' | 'validate' | undefined>()
  const [loadingValidate, setLoadingValidate] = useState<boolean>()
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const [showValidationModal, setShowValidationModal] = useState(false)
  const [dataSuggest, setDataSuggest] = useState<any>()
  const [validationErrors, setValidationErrors] = useState<any>()
  const [fromToModal, setFormToModal] = useState<any>({
    label: '',
    show: false,
    name: undefined,
    data: {},
    validate: {},
  })

  const validateAddress = useCallback(
    (name) => {
      const validate = {
        [`${name}_company`]: Yup.string().when([`${name}_first_name`], {
          is: (first_name) => !first_name || first_name.trim() === '',
          then: orderYup.companyFirstName('COMPANY'),
          otherwise: stringYup(255, 'COMPANY', false),
        }),
        [`${name}_first_name`]: Yup.string().when(`${name}_company`, {
          is: (company) => !company || company.trim() === '',
          then: orderYup.companyFirstName('FIRST_NAME'),
          otherwise: stringYup(255, 'FIRST_NAME', false),
        }),
        [`${name}_street1`]: stringYup(255, 'ADDRESS'),
        [`${name}_city`]: stringYup(255, 'CITY'),
        [`${name}_state`]: Yup.string().when(`${name}_country`, {
          is: (val) => val === 'US',
          then: regexYup.state(),
          otherwise: stringYup(255, 'STATE'),
        }),
        [`${name}_country`]: regexYup.country(),
        [`${name}_zip`]: Yup.string().when(`${name}_country`, {
          is: (val) => val === 'US',
          then: regexYup.zipCode(),
          otherwise: stringYup(255, 'POSTAL_CODE'),
        }),
        [`${name}_last_name`]: stringYup(255, 'LAST_NAME', false),
        [`${name}_street2`]: stringYup(255, 'ADDRESS_2', false),
        [`${name}_phone`]: infoYup.phone('PHONE', false),
        [`${name}_email`]: infoYup.email('EMAIL', false),
      }
      return validate
    },
    [orderYup, regexYup, stringYup, infoYup]
  )

  const validateSchema = Yup.object({
    from: Yup.object().shape(
      Object.entries(validateAddress('from')).reduce((validate, [key, value]) => {
        validate[key] = value
        return validate
      }, {}),
      [['from_first_name', 'from_company']]
    ),
    to: Yup.object().shape(
      Object.entries(validateAddress('to')).reduce((validate, [key, value]) => {
        validate[key] = value
        return validate
      }, {}),
      [['to_first_name', 'to_company']]
    ),
  })

  const handleUseSuggested = (key) => {
    const updateValue = OrdersConfig.VALIDATE_ADDRESS.reduce((initValue, current) => {
      initValue[`${key}_${current}`] = dataSuggest[key][current]
      return initValue
    }, {})
    updateValue[`${key}_is_verified`] = true

    formik.setFieldValue(key, {...formik.getFieldProps(key).value, ...updateValue})
  }

  const initialValues = useMemo(() => {
    const init: any = Object.entries(OrdersConfig.SHIPPING).reduce(
      (initValue, [key, value]) => {
        switch (value.value) {
          case OrdersConfig.SHIPPING.VERIFIED.value:
          case OrdersConfig.SHIPPING.RESIDENTIAL.value:
            initValue.from[`from_${value.value}`] = data?.[`from_${value.value}`] || false
            initValue.to[`to_${value.value}`] = data?.[`to_${value.value}`] || false
            break
          case OrdersConfig.SHIPPING.SAVE_ADDRESS.value:
            initValue.from[`from_${value.value}`] = !!data?.[`from_${value.value}`]
            initValue.to[`to_${value.value}`] = !!data?.[`to_${value.value}`]
            break
          default:
            initValue.from[`from_${value.value}`] = data?.[`from_${value.value}`] || ''
            initValue.to[`to_${value.value}`] = data?.[`to_${value.value}`] || ''
            break
        }
        return initValue
      },
      {from: {}, to: {}}
    )
    return init
  }, [data])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validateSchema,
    onSubmit: async (values) => {
      if (action === 'save') {
        handleEditForm(values)
      }

      if (action === 'validate') {
        handleValidateAddress(values)
      }
    },
  })

  const handleCloseModal = useCallback(() => {
    handleClose()
    setAction(undefined)
    formik.resetForm()
  }, [formik, handleClose])

  const handleEditForm = useCallback(
    async (values) => {
      setLoadingSave(true)

      try {
        await OrderService.patch(
          {
            type: 'address',
            order_ids: [data.id],
            fields: {
              preset_id: isEqual(formik.values.from, initialValues.from) ? data.preset_id : null,
              ...values.from,
              ...values.to,
            },
          },
          {cancelToken: newCancelToken()}
        )

        handleCloseModal()
        handleToEditSuccess()
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoadingSave(false)
      }
    },
    [
      data.id,
      data.preset_id,
      formik.values.from,
      handleCloseModal,
      handleToEditSuccess,
      initialValues.from,
      isCancel,
      newCancelToken,
    ]
  )

  const handleValidateAddress = useCallback(
    async (values) => {
      setLoadingValidate(true)
      try {
        const [valuePayloadFrom, valuePayloadTo] = ['from', 'to'].map((item) =>
          OrdersConfig.VALIDATE_ADDRESS.reduce((valueCurrent, current) => {
            valueCurrent[current] = values[item][`${item}_${current}`]
            return valueCurrent
          }, {})
        )

        const res = await Promise.all([
          OrderService.validateAddress(
            {address: valuePayloadFrom},
            {cancelToken: newCancelToken()}
          ),
          OrderService.validateAddress({address: valuePayloadTo}, {cancelToken: newCancelToken()}),
        ])

        const [valueSuggestFrom, valueSuggestTo] = [0, 1].map((index) =>
          OrdersConfig.VALIDATE_ADDRESS.reduce((initValue, current) => {
            initValue[current] = res[index][current]
            return initValue
          }, {})
        )
        formik.setFieldValue(
          'from.from_is_verified',
          isEqual(valuePayloadFrom, valueSuggestFrom) &&
            res[0].verifications.delivery.success &&
            res[0].verifications.zip4.success
        )
        formik.setFieldValue(
          'to.to_is_verified',
          isEqual(valuePayloadTo, valueSuggestTo) &&
            res[1].verifications.delivery.success &&
            res[1].verifications.zip4.success
        )

        setDataSuggest({from: res[0], to: res[1]})
        setShowValidationModal(true)
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoadingValidate(false)
        setAction(undefined)
      }
    },
    [formik, isCancel, newCancelToken]
  )

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      {showValidationModal && (
        <AddressValidationModal
          show={showValidationModal}
          handleCloseValidation={() => setShowValidationModal(false)}
          handleUseSuggested={handleUseSuggested}
          dataSuggest={dataSuggest}
          dataOriginal={formik.values}
        />
      )}
      <Modal
        id='gori_modal_to_edit_modal'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1000px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div
          className={clsx('modal-content', {
            'cursor-no-drop': disabled,
          })}
        >
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'SHIPPING_ADDRESS'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <Container
              className={clsx({
                'pe-none': disabled,
              })}
            >
              <div className='row'>
                {['from', 'to'].map((item, index) => (
                  <div className='col-md-6 d-flex' key={index}>
                    <div className='flex-fill bg-light rounded p-4 my-4'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <h3 className='fw-bolder fs-2'>
                          {intl.formatMessage({id: `SHIP_${item.toUpperCase()}`})}
                        </h3>
                        <div
                          className='d-flex align-items-center text-primary fw-bolder cursor-pointer bg-hover-light-primary'
                          onClick={() => {
                            setFormToModal({
                              show: true,
                              name: item,
                              data: formik.getFieldProps(item).value,
                              validate: validateAddress(item),
                            })
                          }}
                        >
                          <span className='fs-2 me-1'>+</span>
                          {intl.formatMessage({
                            id: Object.entries(formik.getFieldProps(item).value).some(
                              ([key, value]) => !isEmpty(value)
                            )
                              ? 'EDIT_ADDRESS'
                              : 'NEW_ADDRESS',
                          })}
                        </div>
                      </div>
                      <EditShipAddress
                        formik={formik}
                        fromToModal={fromToModal}
                        handleCloseFromToModal={() =>
                          setFormToModal({
                            show: false,
                            name: undefined,
                            data: {},
                            validate: {},
                          })
                        }
                        name={item}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-link text-decoration-underline fw-bolder me-4'
                label={intl.formatMessage({id: 'VERIFY_ADDRESS'})}
                loadingText={intl.formatMessage({id: 'VERIFY_ADDRESS'})}
                loading={loadingValidate}
                disabled={formik.isSubmitting}
                event={() => {
                  setAction('validate')
                  formik.submitForm()
                }}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SAVE'})}
                loadingText={intl.formatMessage({id: 'SAVE'})}
                event={() => {
                  setAction('save')
                  formik.submitForm()
                }}
                loading={loadingSave}
                disabled={loadingSave}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {FromToEditModal}
