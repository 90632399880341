const UPLOAD_CONFIG = {
  MAX_UPLOAD: 5000,
  SIZE: 8388608,
  SUPPORTED_FORMATS: ['text/csv'],
}

const STATUS_ADD_TO_A1_BATCH = {
  ACTIVE_BOX: {
    SMART: 'smart',
    CREATE: 'create',
    EXISTING: 'existing',
  },
  ACTION: {
    CREATE: 'create',
    EXCLUDE: 'exclude',
    MOVE: 'move',
  },
}

const A1_BATCH_STATUS = {
  OPEN: 'open',
}

const OPTIONS_JOURNEY_INFO = {
  INCOMPLETE: {label: 'INCOMPLETE', value: 'false'},
  COMPLETE: {label: 'COMPLETE', value: 'true'},
}

const OPTIONS_JOURNEY_STATUS = {
  N_A: {label: 'N_A', value: [null], color: '#FF7272'},
  READY: {label: 'READY', value: ['ready'], color: '#A9DEC8'},
  PICK_UP_DROP_OFF: {
    label: 'PICK_UP_DROP_OFF',
    value: ['pickup_dropoff', 'picked_up', 'air_delivery'], // Value 1 is Status Primary, Value 2 is Status Hidden
    color: '#085E57',
  },
  AIR_DELIVERY: {
    label: 'AIR_DELIVERY',
    value: ['air_delivery_arranged'],
    color: '#14B8A6',
  },
  CUSTOMS: {label: 'CUSTOMS', value: ['customs'], color: '#D7B3FC'},
  TRANSFERRED_TO_LOCAL_CARRIER: {
    label: 'TRANSFERRED_TO_LOCAL_CARRIER',
    value: ['transferred'],
    color: '#D7B3FC',
  },
  DELIVERING: {label: 'DELIVERING', value: ['delivering'], color: '#550F8C'},
  DELIVERED: {label: 'DELIVERED', value: ['delivered'], color: '#550F8C'},
}
const OPTIONS_ORIGIN = [
  {label: 'SZX', value: 'SZX'},
  {label: 'PEK', value: 'PEK'},
  {label: 'SHA', value: 'SHA'},
  {label: 'HAN (Vietnam)', value: 'HAN'},
]

const OPTIONS_DESTINATION = [
  {label: 'LAX', value: 'LAX'},
  {label: 'ORD', value: 'ORD'},
  {label: 'JFK', value: 'JFK'},
  {label: 'ATL', value: 'ATL'},
]

const OPTIONS_A1_SERVICE = {
  ALL_IN_ONE: {
    label: 'A1_ALL_IN_ONE',
    value: 'all_in_one',
  },
  CUSTOMIZE: {
    label: 'CUSTOMIZE',
    value: 'customize',
    isDisabled: true,
  },
}

const PARAMS_A1_BATCHES_FILTER = [
  'date_from',
  'date_to',
  'code',
  'name',
  'readied',
  'journey_info',
  'journey_status',
  'origin',
  'destination',
]

const MASS_UPDATE = {
  SHIP_TO: {label: 'SHIP_TO', value: 'to'},
  CONTENT: {label: 'CONTENT', value: 'content'},
  DESCRIPTION: {label: 'DESCRIPTION', value: 'description'},
  HTS_CODE: {label: 'HTS_CODE', value: 'hs_tariff_number'},
  CONTAINER_ID: {label: 'CONTAINER_ID', value: 'container_id'},
  COUNTRY_OF_ORIGIN: {label: 'COUNTRY_OF_ORIGIN', value: 'country'},
  QUANTITY: {label: 'QUANTITY', value: 'quantity'},
  VALUE: {label: 'VALUE_$', value: 'value'},
  WEIGHT: {label: 'WEIGHT_KG', value: 'weight'},
  SERVICE: {label: 'SERVICE', value: 'service'},
}

const A1_ORDER_STATUS = {
  INCOMPLETE: {label: 'INCOMPLETE', value: 'incomplete'},
  READY: {label: 'READY', value: 'ready'},
  RELEASED: {label: 'RELEASED', value: 'released'},
  HOLD: {label: 'HOLD', value: 'hold'},
}

const A1_CONSTANTS = {
  UPLOAD_CONFIG,
  STATUS_ADD_TO_A1_BATCH,
  A1_BATCH_STATUS,
  OPTIONS_JOURNEY_INFO,
  OPTIONS_JOURNEY_STATUS,
  OPTIONS_ORIGIN,
  OPTIONS_DESTINATION,
  OPTIONS_A1_SERVICE,
  PARAMS_A1_BATCHES_FILTER,
  MASS_UPDATE,
  A1_ORDER_STATUS,
}

export default A1_CONSTANTS
