import {AxiosRequestConfig} from 'axios'
import RequestBase from '../../../api/RequestBase'

const URL = '/dashboard'

const getPostagePaid = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL}/postage-paid`, config)
}

const getPostageHistory = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL}/postage-history`, config)
}

const getActivities = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL}/activity-log`, config)
}

const getShipmentMap = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL}/shipment-map`, config)
}

const DashboardService = {
  getPostagePaid,
  getPostageHistory,
  getActivities,
  getShipmentMap,
}

export default DashboardService
