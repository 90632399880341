import * as Yup from 'yup'
import {IntlShape} from 'react-intl/src/types'
import {SchemaLike} from 'yup/lib/types'

interface NumberOption {
  value: number
  label: number | string
}

interface YupOptions {
  name: string
  required?: boolean
  nullable?: boolean
  trim?: boolean
  min?: NumberOption
  max?: NumberOption
  integer?: boolean
  regex?: {
    value: RegExp
    label: string
  }
  when?: {
    target: string
    is: any
    then?: SchemaLike
    otherwise?: SchemaLike
  }
}

const number = (
  intl: IntlShape,
  {name, required = false, nullable = false, integer = false, min, max, when}: YupOptions
) => {
  let yup = Yup.number()
    .nullable()
    .typeError(
      intl.formatMessage(
        {id: 'INPUT_CAN_ONLY_BE_FILLED_IN_DIGITS'},
        {input: intl.formatMessage({id: name})}
      )
    )

  if (required && !nullable) {
    yup = yup.required(
      intl.formatMessage({id: 'INPUT_IS_REQUIRED'}, {input: intl.formatMessage({id: name})})
    )
  }

  if (integer) {
    yup = yup.integer(
      intl.formatMessage(
        {id: 'INPUT_ONLY_ACCEPT_INTEGERS'},
        {input: intl.formatMessage({id: name})}
      )
    )
  }

  if (min) {
    yup = yup.min(
      min.value,
      intl.formatMessage(
        {id: 'INPUT_MUST_BE_GREATER_THAN'},
        {input: intl.formatMessage({id: name}), min: min.label}
      )
    )
  }

  if (max) {
    yup = yup.max(
      max.value,
      intl.formatMessage(
        {id: 'INPUT_MUST_NOT_BE_GREATER_THAN'},
        {input: intl.formatMessage({id: name}), max: max.label}
      )
    )
  }

  if (when) {
    yup = yup.when(when.target, {
      is: when.is,
      then: when.then,
      otherwise: when.otherwise,
    })
  }

  return yup
}

const string = (
  intl: IntlShape,
  {name, required = false, nullable = false, trim = false, min, max, regex, when}: YupOptions
) => {
  let yup = Yup.string().nullable()

  if (required && !nullable) {
    yup = yup.required(
      intl.formatMessage({id: 'INPUT_IS_REQUIRED'}, {input: intl.formatMessage({id: name})})
    )
  }

  if (min) {
    yup = yup.min(
      min.value,
      intl.formatMessage(
        {id: 'INPUT_MUST_BE_GREATER_THAN_CHARACTERS'},
        {input: intl.formatMessage({id: name}), max: min.label}
      )
    )
  }

  if (max) {
    yup = yup.max(
      max.value,
      intl.formatMessage(
        {id: 'INPUT_MUST_NOT_BE_GREATER_THAN_CHARACTERS'},
        {input: intl.formatMessage({id: name}), max: max.label}
      )
    )
  }

  if (regex) {
    yup = yup.matches(regex.value, {
      message: intl.formatMessage({id: regex.label}),
      excludeEmptyString: true,
    })
  }

  if (when) {
    yup = yup.when(when.target, {
      is: when.is,
      then: when.then,
      otherwise: when.otherwise,
    })
  }

  if (trim) {
    yup = yup.trim()
  }

  return yup
}

export const yupHelper = (intl) => {
  return {
    number: (options: YupOptions) => number(intl, options),
    string: (options: YupOptions) => string(intl, options),
  }
}
