import clsx from 'clsx'
import {cloneDeep, isEmpty, size} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {CSSTransition} from 'react-transition-group'
import {ConfirmActionModal} from '../../../../../_gori/components'
import {useDisabled} from '../../../../../_gori/helpers'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import {
  Button,
  InputCheckNoFormik,
  SelectNoFormik,
  TableHeader,
  ValidationErrorModal,
} from '../../../../../_gori/partials/widgets'
import {useA1BatchesProvider} from '../../../../../_gori/providers/A1BatchesProvider'
import {A1MassUpdateModal} from '../../../a1'
import {
  CheapestServiceModal,
  ProcessCheapestServiceModal,
  ProcessEstimatedServiceModal,
} from '../../../orders'
import A1_CONSTANTS from '../../core/_const'
import A1BatchService from '../../core/_requests'

type Props = {
  selectedList?: any
  reloadTable: any
  statistics: any
}

const A1BatchesDetailFilter: React.FC<Props> = ({statistics, selectedList, reloadTable}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const {setPageDisabled} = useDisabled()
  const [searchParams, setSearchParams] = useSearchParams()
  const {a1Batch} = useA1BatchesProvider()
  const [showPopover, setShowPopover] = useState<boolean>(false)
  const [loading, setLoading] = useState<{removeOrderA1Batch: boolean; export: boolean}>({
    removeOrderA1Batch: false,
    export: false,
  })
  const [validationErrors, setValidationErrors] = useState<any>()
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<{
    estimatedService: boolean
    processCheapestService: boolean
    cheapestService: boolean
    removeFromA1Batch: boolean
    massUpdateA1Batch: boolean
  }>({
    estimatedService: false,
    processCheapestService: false,
    cheapestService: false,
    removeFromA1Batch: false,
    massUpdateA1Batch: false,
  })
  const [dataModal, setDataModal] = useState<{
    removeFromA1Batch: any
    massUpdateA1Batch: {ids: Array<number>; type: string}
  }>({
    removeFromA1Batch: [],
    massUpdateA1Batch: {ids: [], type: ''},
  })

  useEffect(() => {
    if (!selectedList.selectedMaster) {
      setSelectAll(false)
    }
  }, [selectedList.selected, selectedList.selectedMaster])

  const handleExport = async () => {
    try {
      setLoading((prev) => ({...prev, export: true}))
      const response = await A1BatchService.downloadOrdersA1Batch(a1Batch.id, {
        cancelToken: newCancelToken(),
      })
      if (response) {
        const blob = new Blob([response.content], {type: response.type})
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = response.file_name
        link.click()
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setLoading((prev) => ({...prev, export: false}))
    }
  }

  const getIdsByStatus = useCallback(
    (nameStatus) => {
      if (isEmpty(statistics)) return []
      const status: any = cloneDeep(statistics).find((item: any) => item.value === nameStatus)

      let orders: any
      if (!status) {
        orders = cloneDeep(statistics)
          .flatMap((item: any) => item.orders || [])
          .map((order: any) => ({original: order}))
      } else {
        orders = (status?.orders || []).map((order: any) => ({original: order}))
      }

      return orders
    },
    [statistics]
  )

  const filterOrdersByStatus = useCallback(
    (statusList) => {
      if (selectAll) {
        const currentStatus = searchParams.get('status')
        if (!currentStatus) {
          return statusList.flatMap((status) => getIdsByStatus(status))
        } else if (statusList.includes(currentStatus)) {
          return getIdsByStatus(currentStatus)
        }
      } else {
        return cloneDeep(selectedList.selected).flatMap((row) =>
          statusList.includes(row.original.status) ? [row] : []
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getIdsByStatus, searchParams, selectAll, selectedList.selected]
  )

  const orderIds = useMemo(() => {
    return {
      massUpdateA1Batch: filterOrdersByStatus([A1_CONSTANTS.A1_BATCH_STATUS.OPEN]),
      cheapestService: filterOrdersByStatus([A1_CONSTANTS.A1_BATCH_STATUS.OPEN]),
      estimatedService: filterOrdersByStatus([A1_CONSTANTS.A1_BATCH_STATUS.OPEN]),
      removeFromA1Batch: filterOrdersByStatus([A1_CONSTANTS.A1_BATCH_STATUS.OPEN]),
    }
  }, [filterOrdersByStatus])

  const contentRemoveFromA1Batch = useMemo(() => {
    return (
      <p className='fs-4 fw-normal'>
        <span>{intl.formatMessage({id: 'ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE'})}</span>
        <span>
          <b>
            {' ' +
              size(dataModal.removeFromA1Batch) +
              ' ' +
              (size(dataModal.removeFromA1Batch) === 1
                ? intl.formatMessage({id: 'ORDER'})
                : intl.formatMessage({id: 'ORDERS'})) +
              ' '}
          </b>
        </span>
        <span>{intl.formatMessage({id: 'FROM_THE_CURRENT_A1_BATCH'})}</span>
      </p>
    )
  }, [dataModal.removeFromA1Batch, intl])

  const handleRemoveOrderA1Batch = async () => {
    try {
      setLoading((prev) => ({...prev, removeOrderA1Batch: true}))
      setPageDisabled(true)
      const config = {cancelToken: newCancelToken()}

      const res = await A1BatchService.removeOrderA1Batch(
        a1Batch.id,
        {
          order_ids: dataModal.removeFromA1Batch.map((order: any) => order.original.id),
        },
        config
      )
      if (res) {
        toast.success(intl.formatMessage({id: res.message}))
        reloadTable()
        setShowModal((prev) => ({...prev, removeFromA1Batch: false}))
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setPageDisabled(false)
      setLoading((prev) => ({...prev, removeOrderA1Batch: false}))
    }
  }

  const handleCloseRemoveModal = () => {
    setDataModal((prev) => ({...prev, removeFromA1Batch: []}))
    setShowModal((prev) => ({...prev, removeFromA1Batch: false}))
  }

  const inputIncomplete = useMemo(() => {
    const handleIncompleteToggle = (e) => {
      const updatedParams = new URLSearchParams(searchParams)
      if (e.target.checked) {
        updatedParams.set('incomplete', 'true')
      } else {
        updatedParams.delete('incomplete')
      }
      setSearchParams(updatedParams)
    }

    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        show={showPopover}
        placement='top'
        overlay={
          <Popover className='min-w-400px mb-4'>
            <div className='p-5 rounded-2 border-start border-5 border-danger shadow text-danger fw-bold'>
              <div>{intl.formatMessage({id: 'SOME_ORDERS_IN_THIS_A1_BATCH_ARE_INCOMPLETE'})}</div>
              <div>
                {intl.formatMessage({
                  id: 'PLEASE_COMPLETE_THE_ORDER_INFORMATION_TO_PROCEED',
                })}
              </div>
            </div>
          </Popover>
        }
        delay={300}
      >
        <div>
          <div
            className='form-check form-check-danger'
            onMouseEnter={() => setShowPopover(true)}
            onMouseLeave={() => setShowPopover(false)}
          >
            <InputCheckNoFormik
              inputClassName={'border-danger'}
              label={intl.formatMessage({id: 'VIEW_INCOMPLETE_ORDERS_ONLY'})}
              labelClassName={'text-danger'}
              checked={searchParams.get('incomplete') === 'true'}
              onChange={handleIncompleteToggle}
            />
          </div>
        </div>
      </OverlayTrigger>
    )
  }, [intl, searchParams, setSearchParams, showPopover])

  const handleMassUpdate = useCallback(
    (option) => {
      setShowModal((prev) => ({...prev, massUpdateA1Batch: true}))
      setDataModal((prev) => ({
        ...prev,
        massUpdateA1Batch: {
          ids: orderIds.massUpdateA1Batch.map((item: any) => {
            return item.original.id
          }),
          type: option.value,
        },
      }))
    },
    [orderIds.massUpdateA1Batch]
  )

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      {showModal.cheapestService && (
        <CheapestServiceModal
          show={showModal.cheapestService}
          handleClose={() => setShowModal((prev) => ({...prev, cheapestService: false}))}
          orders={orderIds.cheapestService}
          confirm={() => setShowModal((prev) => ({...prev, processCheapestService: true}))}
        />
      )}
      {showModal.processCheapestService && (
        <ProcessCheapestServiceModal
          isSelectAll={selectAll}
          show={showModal.processCheapestService}
          data={orderIds.cheapestService}
          handleClose={() => {
            reloadTable()
            setShowModal((prev) => ({...prev, processCheapestService: false}))
          }}
          type='a1_batch'
        />
      )}
      {showModal.estimatedService && (
        <ProcessEstimatedServiceModal
          isSelectAll={selectAll}
          show={showModal.estimatedService}
          data={orderIds.estimatedService}
          handleClose={() => {
            reloadTable()
            setShowModal((prev) => ({...prev, estimatedService: false}))
          }}
          type='a1_batch'
        />
      )}
      {showModal.removeFromA1Batch && (
        <ConfirmActionModal
          show={showModal.removeFromA1Batch}
          title={intl.formatMessage({id: 'REMOVE_FROM_A1_BATCH'})}
          message={contentRemoveFromA1Batch}
          messagePrimary={intl.formatMessage({id: 'SUBMIT'})}
          messageSecondary={intl.formatMessage({id: 'CANCEL'})}
          handlePrimaryAction={handleRemoveOrderA1Batch}
          loadingPrimary={loading.removeOrderA1Batch}
          handleSecondaryAction={handleCloseRemoveModal}
          handleClose={handleCloseRemoveModal}
        />
      )}
      {showModal.massUpdateA1Batch && (
        <A1MassUpdateModal
          show={showModal.massUpdateA1Batch}
          handleClose={() => {
            setDataModal((prev) => ({...prev, massUpdateA1Batch: {ids: [], type: ''}}))
            setShowModal((prev) => ({...prev, massUpdateA1Batch: false}))
          }}
          data={dataModal.massUpdateA1Batch}
          handleSuccess={() => {
            reloadTable()
            setDataModal((prev) => ({...prev, massUpdateA1Batch: {ids: [], type: ''}}))
            setShowModal((prev) => ({...prev, massUpdateA1Batch: false}))
          }}
        />
      )}
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <TableHeader className='card-header border-0 d-flex align-items-center justify-content-between my-5'>
          <div
            className={clsx('d-flex justify-content-end align-items-center flex-wrap gap-5', {
              invisible: selectedList.selected.length <= 0,
            })}
          >
            <div className='fw-bolder'>
              <span className='me-2'>
                {selectAll ? selectedList.total : selectedList.selected.length}
              </span>
              {intl.formatMessage({id: 'SELECTED'})}
            </div>
            {!selectAll && selectedList.selectedMaster && (
              <div
                onClick={() => setSelectAll(true)}
                className='fw-bolder link-blue text-decoration-underline cursor-pointer'
              >
                {intl.formatMessage({id: 'SELECT_ALL'})} {selectedList.total}
              </div>
            )}
            {selectAll && (
              <div
                onClick={() => setSelectAll(false)}
                className='fw-bolder link-blue text-decoration-underline cursor-pointer'
              >
                {intl.formatMessage({id: 'CLEAR_SELECTION'})}
              </div>
            )}
            {!isEmpty(orderIds.massUpdateA1Batch) && (
              <div className='cursor-pointer'>
                <SelectNoFormik
                  emptyDefault={false}
                  className={'w-200px'}
                  value={{label: intl.formatMessage({id: 'MASS_UPDATE'}), value: ''}}
                  options={Object.values(A1_CONSTANTS.MASS_UPDATE)}
                  onChange={handleMassUpdate}
                  hasUseIntl={true}
                />
              </div>
            )}
            {!isEmpty(orderIds.cheapestService) && (
              <Button
                className='btn btn-light-warning'
                label={intl.formatMessage({id: 'CHEAPEST_SERVICE'})}
                loadingText={intl.formatMessage({id: 'CHEAPEST_SERVICE'})}
                event={() => setShowModal((prev) => ({...prev, cheapestService: true}))}
              />
            )}
            {!isEmpty(orderIds.estimatedService) && (
              <Button
                className='btn btn-light-info'
                label={intl.formatMessage({id: 'ESTIMATE_SERVICE'})}
                loadingText={intl.formatMessage({id: 'ESTIMATE_SERVICE'})}
                event={() => setShowModal((prev) => ({...prev, estimatedService: true}))}
              />
            )}
            {!isEmpty(orderIds.removeFromA1Batch) && (
              <Button
                className='btn btn-light-danger'
                label={intl.formatMessage({id: 'REMOVE_FROM_A1_BATCH'})}
                loadingText={intl.formatMessage({id: 'REMOVE_FROM_A1_BATCH'})}
                event={() => {
                  setDataModal((prev) => ({...prev, removeFromA1Batch: orderIds.removeFromA1Batch}))
                  setShowModal((prev) => ({...prev, removeFromA1Batch: true}))
                }}
              />
            )}
          </div>
          <div className='gap-5 d-flex'>
            {!a1Batch?.readied && inputIncomplete}
            <div
              className={clsx(
                'text-gray-700 text-hover-primary cursor-pointer me-4 fw-bolder text-decoration-underline'
              )}
              onClick={handleExport}
            >
              {intl.formatMessage({id: 'EXPORT_AS_CSV'})}
              {loading.export && (
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              )}
            </div>
          </div>
        </TableHeader>
      </CSSTransition>
    </>
  )
}

export {A1BatchesDetailFilter}
