import clsx from 'clsx'
import React, {useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_gori/helpers'
import {Button} from '../../../../_gori/partials/widgets'
import {OrdersConfig} from '../core/_const'

type Props = {
  show: boolean
  handleCloseValidation: () => void
  handleUseSuggested: any
  dataSuggest: {from?: any; to?: any}
  dataOriginal: {from?: any; to?: any}
  fromOrTo?: 'from' | 'to' | 'pickup_address'
}

const AddressValidationModal: React.FC<Props> = ({
  show,
  handleCloseValidation,
  handleUseSuggested,
  dataSuggest,
  dataOriginal,
  fromOrTo,
}) => {
  const intl = useIntl()
  const [iconShow, setIconShow] = useState({from: false, to: false})

  const checkEqual = useMemo(() => {
    if (fromOrTo) {
      const _equal = OrdersConfig.VALIDATE_ADDRESS.every(
        (item) => dataSuggest[fromOrTo][item] === dataOriginal[fromOrTo][`${fromOrTo}_${item}`]
      )
      return {[fromOrTo]: _equal}
    } else {
      const validArr = ['from', 'to'].map((validate) => {
        return OrdersConfig.VALIDATE_ADDRESS.every(
          (item) => dataSuggest[validate][item] === dataOriginal[validate][`${validate}_${item}`]
        )
      })
      return {from: validArr[0], to: validArr[1]}
    }
  }, [dataOriginal, dataSuggest, fromOrTo])

  return (
    <>
      <Modal
        id='gori_modal_new_order'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-800px h-auto'
        show={show}
      >
        <div className='modal-content'>
          <Modal.Header closeButton={false}>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'VERIFICATION_ADDRESS'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              {(fromOrTo ? [`${fromOrTo}`] : ['from', 'to']).map((item, key) => {
                const checkAddressValid =
                  dataSuggest[item]?.verifications?.delivery?.success &&
                  dataSuggest[item]?.verifications?.zip4?.success

                return (
                  <div className='row my-4' key={key}>
                    <h3 className='text-dark'>{intl.formatMessage({id: item.toUpperCase()})}</h3>
                    <div className='col-md-12 d-flex flex-fill justify-content-between'>
                      {checkAddressValid ? (
                        <>
                          <div className='col-md-5'>
                            <h5 className='text-dark'>{intl.formatMessage({id: 'SUGGESTED'})}</h5>
                            <div className='text-dark'>{dataSuggest[item].street1}</div>
                            <div className='text-dark'>{`${dataSuggest[item].city}, ${dataSuggest[item].state} ${dataSuggest[item].zip}`}</div>
                            <div className='text-dark'>{dataSuggest[item].country}</div>
                          </div>
                          <div className='col-md-5 mx-3'>
                            <h5 className='text-dark'>{intl.formatMessage({id: 'ORIGINAL'})}</h5>
                            <div className='text-dark'>{dataOriginal[item][`${item}_street1`]}</div>
                            <div className='text-dark'>{`${dataOriginal[item][`${item}_city`]}, ${
                              dataOriginal[item][`${item}_state`]
                            } ${dataOriginal[item][`${item}_zip`]}`}</div>
                            <div className='text-dark'>{dataOriginal[item][`${item}_country`]}</div>
                          </div>
                          <div className='col-md-2 d-flex flex-column justify-content-center align-items-center'>
                            {checkEqual[item] || iconShow[item] ? (
                              <KTSVG path='/media/gori/orders/success.svg' small={false} />
                            ) : (
                              <>
                                <div
                                  className='fs-8 p-2 bg-secondary mb-3 rounded-2 text-center cursor-pointer'
                                  onClick={() => {
                                    setIconShow((prev) => ({...prev, [item]: true}))
                                    handleUseSuggested(item)
                                  }}
                                >
                                  {intl.formatMessage({id: 'USE_SUGGESTED'})}
                                </div>
                                <div
                                  className='fs-8 p-2 bg-primary text-white mb-3 rounded-2 text-center cursor-pointer'
                                  onClick={() => setIconShow((prev) => ({...prev, [item]: true}))}
                                >
                                  {intl.formatMessage({id: 'KEEP_ORIGINAL'})}
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <div className='d-flex flex-fill justify-content-center'>
                          <h3 className={clsx('text-center text-danger')}>
                            {intl.formatMessage({
                              id: 'ADDRESS_IS_INVALID',
                            })}
                          </h3>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary'
                label={intl.formatMessage({id: 'CLOSE'})}
                event={handleCloseValidation}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {AddressValidationModal}
