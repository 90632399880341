import clsx from 'clsx'
import {find, isEmpty, omit, size} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {CSSTransition} from 'react-transition-group'
import {ConfirmActionModal} from '../../../../../_gori/components'
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  OPTION_COUNTRIES,
  TABLE_KEY_COLUMN,
} from '../../../../../_gori/constants'
import {FEATURES} from '../../../../../_gori/constants/_features'
import {
  KTSVG,
  convertUserTimeZone,
  getShipmentFees,
  isFeatureEnabled,
  setLoadingActionTable,
  useDisabled,
} from '../../../../../_gori/helpers'
import {blockPopUp} from '../../../../../_gori/helpers/BrowserHelpers'
import {TableSkeleton} from '../../../../../_gori/helpers/components'
import {useMeter} from '../../../../../_gori/helpers/components/MeterProvider'
import {useStatesGlobal} from '../../../../../_gori/helpers/components/StatesGlobalProvider'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import {PageTitle} from '../../../../../_gori/layout/core'
import {
  Button,
  ButtonBack,
  CarrierBadge,
  Table,
  TableBody,
  TableWrapper,
  ValidationErrorModal,
} from '../../../../../_gori/partials/widgets'
import {useA1BatchesProvider} from '../../../../../_gori/providers/A1BatchesProvider'
import HttpStatusCode from '../../../../api/HttpStatusCode'
import {
  A1BatchRenameModal,
  A1BatchesDetailFilter,
  EditOrderInfo,
  JourneyInfoCard,
  JourneyInformationModal,
  JourneyStatusCard,
} from '../../../a1'
import {useAuth} from '../../../auth'
import {CreateAllLabelsModal} from '../../../batches'
import {HelpCenterWidget} from '../../../common/components/HelpCenterWidget'
import {AddressView} from '../../../orders'
import {
  HELP_CENTER_WIDGET_PARAMS,
  OPTIONS_CONTENT_TYPE,
  OrderStatus,
} from '../../../orders/core/_const'
import A1_CONSTANTS from '../../core/_const'
import A1BatchService from '../../core/_requests'

const SkeletonA1BatchDetail = () => (
  <>
    <div className='d-flex flex-wrap mb-10'>
      <div className='col-12 col-xxl-6'>
        <div className='card me-xxl-4 h-100'>
          <div className='card-header border-0 fw-bolder d-flex justify-content-between align-items-center'>
            <span className='fs-1 col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
          </div>
          <div className='card-body bg-light rounded-bottom d-flex flex-column flex-md-row'>
            <div className='col-12 col-md-6'>
              <div className='d-flex pe-md-6 text-primary fs-5 fs-xxl-7 text-center fw-bolder'>
                <div className='col-4'>
                  <span className='fs-1 col-9 placeholder placeholder-lg rounded-2 bg-secondary' />
                </div>
                <div className='col-4'>
                  <span className='fs-1 col-9 placeholder placeholder-lg rounded-2 bg-secondary' />
                </div>
                <div className='col-4'>
                  <span className='fs-1 col-9 placeholder placeholder-lg rounded-2 bg-secondary' />
                </div>
              </div>
              <div className='d-flex flex-column min-h-150px pe-md-6'></div>
            </div>
            <span className='border border-secondary my-8 my-md-n8' />
            <div className='col-12 col-md-6 ps-6 fs-5 fs-xxl-7 fw-bold'>
              <div className='col-12 d-flex border-bottom border-secondary min-h-50px' />
              <div className='col-12 d-flex border-bottom border-secondary min-h-50px' />
              <div className='col-12 d-flex border-bottom border-secondary min-h-50px' />
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 col-xxl-6'>
        <div className='card mt-4 mt-xxl-0 ms-xxl-4 h-100'>
          <div className='card-header border-0 d-flex justify-content-between align-items-center fw-bolder'>
            <span className='fs-1 col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
          </div>
          <div className='card-body d-flex flex-column scroll-x pb-6'>
            <div className='d-flex text-center mb-6'>
              {Object.entries(omit(A1_CONSTANTS.OPTIONS_JOURNEY_STATUS, 'N_A')).map(
                ([key, status]) => (
                  <div className='col-7-equal min-w-100px position-relative' key={key}>
                    <div className='w-100 border border-2 border-secondary position-absolute top-50 start-50 translate-middle' />
                    <KTSVG
                      path={`/media/gori/a1/checkbox_unchecked.svg`}
                      className='svg-icon-1 position-absolute top-50 start-50 translate-middle'
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    <TableWrapper>
      <TableBody>
        <div className='d-flex justify-content-end my-5'>
          <span className='btn col-2 placeholder placeholder-lg rounded-2 bg-secondary' />
        </div>
        <TableSkeleton countRow={8} />
      </TableBody>
    </TableWrapper>
  </>
)

const A1BatchesDetailPage: React.FC = () => {
  const intl = useIntl()
  const {id} = useParams()
  const navigate = useNavigate()
  const {setMeterChange} = useMeter()
  const [searchParams] = useSearchParams()
  const {setPageDisabled} = useDisabled()
  const {statesGlobal} = useStatesGlobal()
  const carriers = statesGlobal.carriers
  const {currentUser, loadingSwitch} = useAuth()
  const {a1Batch, setA1Batch} = useA1BatchesProvider()
  const {newCancelToken, isCancel} = useCancelToken()
  const [selectedListId, setSelectedListId] = useState<any>([])
  const [selectedMaster, setSelectedMaster] = useState<boolean>(false)
  const [tableData, setTableData] = useState<any>([])
  const [pagination, setPagination] = useState<any>({})
  const [statistics, setStatistics] = useState<any>([])
  const [validationErrors, setValidationErrors] = useState<any>()
  const [loading, setLoading] = useState<{
    first: boolean
    getA1Batch: boolean
    removeBatch: boolean
    printAllLabel: boolean
  }>({
    first: true,
    getA1Batch: false,
    removeBatch: false,
    printAllLabel: false,
  })
  const [showModal, setShowModal] = useState<{
    rename: boolean
    removeBatch: boolean
    journeyInfo: boolean
    createAllLabel: boolean
    editOrderInfo: boolean
  }>({
    rename: false,
    removeBatch: false,
    journeyInfo: false,
    createAllLabel: false,
    editOrderInfo: false,
  })
  const [dataModal, setDataModal] = useState<{createAllLabel: boolean; editOrderInfo: any}>({
    createAllLabel: false,
    editOrderInfo: {},
  })

  const setTable = useCallback(async () => {
    try {
      setPageDisabled(true)
      const config = {
        params: {
          page: searchParams.get('page') || DEFAULT_PAGE,
          page_size: searchParams.get('page_size') || DEFAULT_PAGE_SIZE,
          status: searchParams.get('status') || null,
          sort_by: searchParams.get('sort_by') || null,
          sort_direction: searchParams.get('sort_direction') || null,
          incomplete: searchParams.get('incomplete') || null,
        },
        cancelToken: newCancelToken(),
      }
      const response = await A1BatchService.getA1BatchOrders(id, config)
      if (response) {
        let newOrder = Array.from(response.orders, (order: any) => {
          return {
            ...order,
            canCheck: true,
          }
        })
        setTableData(newOrder)
        setStatistics(response.statistics)
        setPagination(response.pagination)
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setPageDisabled(false)
      setLoading((prev) => ({...prev, first: false}))
    }
  }, [id, isCancel, newCancelToken, searchParams, setPageDisabled])

  const getA1Batch = useCallback(async () => {
    try {
      setLoading((prev) => ({...prev, getA1Batch: true}))
      const {a1_batch, from, orders_readied} = await A1BatchService.getDetail(id, {
        cancelToken: newCancelToken(),
      })
      if (a1_batch) {
        setA1Batch({...a1_batch, from: from, orders_readied: orders_readied})
      }
    } catch (error: any) {
      if (isCancel(error)) return
      if (error?.response?.status === HttpStatusCode.NOT_FOUND) {
        navigate(-1)
      }
      setValidationErrors(error?.response)
    } finally {
      setLoading((prev) => ({...prev, getA1Batch: false}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancel, newCancelToken])

  useEffect(() => {
    if (loadingSwitch) return
    setTable()

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSwitch, searchParams])

  useEffect(() => {
    if (loadingSwitch) return
    getA1Batch()

    return () => {
      setA1Batch(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSwitch])

  const columns = useMemo(() => {
    const showEditOrderInfo = (child, row, showRequired = true) => {
      return (
        <div
          className='cursor-pointer'
          onClick={() => {
            setDataModal((prev) => ({...prev, editOrderInfo: row.original}))
            setShowModal((prev) => ({...prev, editOrderInfo: true}))
          }}
        >
          {(showRequired && (
            <div className='d-flex text-danger'>
              <KTSVG
                path='/media/gori/settings/errorsPreset.svg'
                className='svg-icon-3 text-danger'
              />
              <span className='ms-2 text-decoration-underline text-uppercase'>
                {intl.formatMessage({id: 'REQUIRED'})}
              </span>
            </div>
          )) || <span className='text-hover-primary'>{child}</span>}
        </div>
      )
    }

    const _columns = [
      {
        id: TABLE_KEY_COLUMN.CAN_CHECK,
        Header: intl.formatMessage({id: 'ID'}),
        accessor: TABLE_KEY_COLUMN.CAN_CHECK,
      },
      {
        id: 'created_at',
        Header: intl.formatMessage({id: 'DATE'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) => {
          return showEditOrderInfo(
            convertUserTimeZone(
              row.original?.created_at,
              currentUser,
              process.env.REACT_APP_DATE_FORMAT
            ),
            row,
            false
          )
        },
      },
      {
        id: 'code',
        Header: 'BAE ID',
        headerClassName: 'min-w-150px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) => showEditOrderInfo(row.original?.bae_id, row, false),
      },
      {
        id: 'ship_to',
        Header: intl.formatMessage({id: 'SHIP_TO'}),
        headerClassName: 'min-w-200px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) => {
          return showEditOrderInfo(<AddressView data={row.original.to} />, row, false)
        },
      },
      {
        id: 'tracking_number',
        Header: intl.formatMessage({id: 'TRACKING_NUMBER'}),
        headerClassName: 'min-w-150px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) => {
          return showEditOrderInfo(
            <>
              {row?.original?.tracking_number}
              {!isEmpty(row.original.error) && (
                <div className='row'>
                  <span className='text-danger'>{row.original?.error?.message}</span>
                  <span className='text-danger'>{row.original?.error?.Description}</span>
                  {row.original?.error?.fields &&
                    Object.entries(row.original.error.fields).map(([key, value]: [any, any]) => {
                      return (
                        <span key={key} className='text-danger'>
                          - {key} {value}
                        </span>
                      )
                    })}
                </div>
              )}
            </>,
            row,
            false
          )
        },
      },
      {
        id: 'rate',
        Header: intl.formatMessage({id: 'RATE'}),
        headerClassName: 'min-w-150px text-center',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) => {
          let sum = 0
          let service = carriers?.[row.original.carrier + '_' + row.original.service] ?? []

          if (row.original?.fees) {
            sum = getShipmentFees(row.original?.fees)
          } else if (row.original?.estimated_rate) {
            sum = row.original?.estimated_rate
          }
          const isCheapest = row.original?.is_cheapest_rate ?? false
          return showEditOrderInfo(
            row.original?.carrier && (
              <CarrierBadge
                service={service}
                isCheapest={isCheapest}
                {...((row.original?.fees || row.original?.estimated_rate) && {
                  cost: sum,
                })}
              />
            ),
            row,
            false
          )
        },
      },
      {
        id: 'hawb',
        Header: 'HAWB',
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) => {
          return showEditOrderInfo(row?.original?.a1?.hawb || null, row, false)
        },
      },
      {
        id: 'container_id',
        Header: intl.formatMessage({id: 'CONTAINER_ID'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) => {
          return showEditOrderInfo(row?.original?.a1?.container_id || null, row, false)
        },
      },
      {
        id: 'content',
        Header: intl.formatMessage({id: 'CONTENT'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) =>
          showEditOrderInfo(
            !isEmpty(row.original.items) &&
              (row.original?.expand ? row.original?.items : [row.original?.items[0]]).map(
                (item: any) => {
                  const content = find(OPTIONS_CONTENT_TYPE, {value: item?.a1?.content})
                  return (
                    content && (
                      <div className='d-flex'>
                        <span className='mw-200px text-truncate'>
                          {intl.formatMessage({id: content?.label})}
                        </span>
                      </div>
                    )
                  )
                }
              ),
            row,
            row.original?.items?.some((item) => isEmpty(item?.a1?.content))
          ),
      },
      {
        id: 'description',
        Header: intl.formatMessage({id: 'DESCRIPTION'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) =>
          showEditOrderInfo(
            (row.original?.expand ? row.original?.items : [row.original?.items[0]]).map(
              (item: any) => {
                return (
                  item?.a1?.description && (
                    <div className='d-flex'>
                      <span className='mw-200px text-truncate'>{item?.a1?.description}</span>
                    </div>
                  )
                )
              }
            ),
            row,
            row.original?.items?.some((item) => isEmpty(item?.a1?.description))
          ),
      },
      {
        id: 'hts_code',
        Header: intl.formatMessage({id: 'HTS_CODE'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) =>
          showEditOrderInfo(
            !isEmpty(row.original.items) &&
              (row.original?.expand ? row.original?.items : [row.original?.items[0]]).map(
                (item: any) => {
                  return (
                    item?.a1?.hs_tariff_number && (
                      <div className='d-flex'>
                        <span className='mw-200px text-truncate'>{item?.a1?.hs_tariff_number}</span>
                      </div>
                    )
                  )
                }
              ),
            row,
            row.original?.items?.some((item) => isEmpty(item?.a1?.hs_tariff_number))
          ),
      },
      {
        id: 'country',
        Header: intl.formatMessage({id: 'COUNTRY_OF_ORIGIN'}),
        headerClassName: 'min-w-150px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) =>
          showEditOrderInfo(
            !isEmpty(row.original.items) &&
              (row.original?.expand ? row.original?.items : [row.original?.items[0]]).map(
                (item: any) => {
                  const country = find(OPTION_COUNTRIES, {value: item?.a1?.country})
                  return (
                    country && (
                      <div className='d-flex'>
                        <span className='mw-200px text-truncate'>{country?.label}</span>
                      </div>
                    )
                  )
                }
              ),
            row,
            row.original?.items?.some((item) => isEmpty(item?.a1?.country))
          ),
      },
      {
        id: 'quantity',
        Header: intl.formatMessage({id: 'QUANTITY'}),
        headerClassName: 'min-w-150px text-center',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) =>
          showEditOrderInfo(
            !isEmpty(row.original.items) &&
              (row.original?.expand ? row.original?.items : [row.original?.items[0]]).map(
                (item: any) => {
                  const quantity = item?.a1?.quantity?.toString()

                  return (
                    !!quantity && <div className='d-flex justify-content-center'>{quantity}</div>
                  )
                }
              ),
            row,
            row.original?.items?.some((item) => isEmpty(item?.a1?.quantity?.toString()))
          ),
      },
      {
        id: 'value',
        Header: `${intl.formatMessage({id: 'VALUES'})} ($)`,
        headerClassName: 'min-w-150px text-center',
        cellClassName: 'text-center text-gray-800',
        Cell: ({row}: {row: any}) =>
          showEditOrderInfo(
            !isEmpty(row.original.items) &&
              (row.original?.expand ? row.original?.items : [row.original?.items[0]]).map(
                (item: any) => {
                  const formatNumber = (number = 0) => {
                    return intl.formatNumber(Number(number), {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }

                  return (
                    item?.a1?.value && (
                      <div className='d-flex justify-content-center'>
                        {formatNumber(item?.a1?.value)}
                      </div>
                    )
                  )
                }
              ),
            row,
            row.original?.items?.every((item) => isEmpty(item?.a1?.value))
          ),
      },
      {
        id: 'actions_header',
        Header: '',
        headerClassName: 'text-center',
        cellClassName: 'text-center',
        Cell: ({row}: {row: any}) => (
          <div
            className={clsx(
              'text-primary text-hover-active-primary fw-bold cursor-pointer text-decoration-underline',
              {
                'd-none': size(row.original.items) <= 1,
                'min-w-150px': size(row.original.items) > 1,
              }
            )}
            onClick={() => {
              setTableData(
                setLoadingActionTable(tableData, [row.original.id], !row.original.expand, 'expand')
              )
            }}
          >
            {intl.formatMessage({id: row.original.expand ? 'COLLAPSE' : 'VIEW_MORE_ITEMS'})}
          </div>
        ),
      },
    ]
    return _columns.filter((col) => col)
  }, [intl, currentUser, carriers, tableData])

  // BEGIN: Title Page
  const handleRemoveA1Batch = async () => {
    try {
      setLoading((prev) => ({...prev, removeBatch: true}))
      const res = await A1BatchService.removeBatch(a1Batch.id)
      if (res) {
        toast.success(intl.formatMessage({id: 'DELETED_A1_BATCH_SUCCESSFULLY'}))
        navigate('/a1-batches')
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setLoading((prev) => ({...prev, removeBatch: false}))
    }
  }

  const detailMenu = () => {
    return (
      <div className='ms-4'>
        <div
          data-kt-menu-trigger='click'
          data-kt-menu-overflow='false'
          data-kt-menu-placement='bottom-start'
          className='cursor-pointer'
        >
          <KTSVG path='/media/gori/a1/menu.svg' className='svg-icon-1 text-primary ms-2' />
        </div>
        <div
          className='p-4 menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold fs-6 mw-200px'
          data-kt-menu='true'
        >
          <div className='menu-item'>
            <div
              className={clsx('menu-link', {
                'cursor-no-drop opacity-50': a1Batch?.status !== A1_CONSTANTS.A1_BATCH_STATUS.OPEN,
              })}
              onClick={() => {
                if (a1Batch?.status === A1_CONSTANTS.A1_BATCH_STATUS.OPEN) {
                  setShowModal((prev) => ({...prev, rename: true}))
                }
              }}
            >
              {intl.formatMessage({id: 'RENAME_A1_BATCH'})}
            </div>
          </div>
          <div className='menu-item'>
            <div
              className={clsx('menu-link', {
                'cursor-no-drop opacity-50': a1Batch?.status !== A1_CONSTANTS.A1_BATCH_STATUS.OPEN,
              })}
              onClick={() => {
                if (a1Batch?.status === A1_CONSTANTS.A1_BATCH_STATUS.OPEN) {
                  setShowModal((prev) => ({...prev, removeBatch: true}))
                }
              }}
            >
              {intl.formatMessage({id: 'REMOVE_A1_BATCH'})}
            </div>
          </div>
        </div>
      </div>
    )
  }
  // END: Title Page

  const orderIds = useMemo(() => {
    return {
      createAllLabel:
        find(statistics, {value: OrderStatus.OPEN})?.orders?.map((item) => item.id) ?? [],
      printAllLabel:
        find(statistics, {value: OrderStatus.SHIPPED})?.orders?.map((item) => item.id) ?? [],
    }
  }, [statistics])

  const handleCreateAllLabels = useCallback(() => {
    setDataModal((prev) => ({...prev, createAllLabel: orderIds.createAllLabel}))
    setShowModal((prev) => ({...prev, createAllLabel: true}))
  }, [orderIds.createAllLabel])

  const handleCloseCreateAllModal = useCallback(() => {
    setMeterChange(true)
    setShowModal((prev) => ({...prev, createAllLabel: false}))
    setTable()
    getA1Batch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePrintAllLabels = useCallback(async () => {
    const config = {cancelToken: newCancelToken()}
    const payload = {order_ids: orderIds.printAllLabel, a1_batch: a1Batch.name}

    try {
      setLoading((prev) => ({...prev, printAllLabel: true}))
      const response = await A1BatchService.printLabel(payload, config)
      if (response) {
        if (!response.label_url) {
          return toast.warning(
            intl.formatMessage({
              id: 'PRINT_LABELS_REFUNDED',
            })
          )
        }
        if (blockPopUp(response.label_url)) {
          toast.warning(
            intl.formatMessage({
              id: 'POP_UP_IS_BLOCKED',
            })
          )
        }
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setLoading((prev) => ({...prev, printAllLabel: false}))
    }
  }, [a1Batch?.name, intl, isCancel, newCancelToken, orderIds.printAllLabel])

  const handleUpdateJourneySuccess = useCallback(async () => {
    setShowModal((prev) => ({...prev, journeyInfo: false}))
    toast.success(intl.formatMessage({id: 'UPDATED_SUCCESSFULLY'}))
    await getA1Batch()
  }, [getA1Batch, intl])

  const handleCloseEditOrderInfo = useCallback(() => {
    setDataModal((prev) => ({...prev, editOrderInfo: {}}))
    setShowModal((prev) => ({...prev, editOrderInfo: false}))
  }, [])

  const handleUpdateOrderInfoSuccess = useCallback(async () => {
    handleCloseEditOrderInfo()
    await setTable()
  }, [handleCloseEditOrderInfo, setTable])

  return loading.first || isEmpty(a1Batch) ? (
    <SkeletonA1BatchDetail />
  ) : (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      {showModal.rename && (
        <A1BatchRenameModal
          show={showModal.rename}
          handleClose={() => setShowModal((prev) => ({...prev, rename: false}))}
        />
      )}
      {showModal.removeBatch && (
        <ConfirmActionModal
          show={showModal.removeBatch}
          title={intl.formatMessage({id: 'REMOVE_A1_BATCH'})}
          message={
            <span className='text-center'>
              <p>{intl.formatMessage({id: 'ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_A1_BATCH'})}</p>
              <p>
                {intl.formatMessage({
                  id: 'REMOVING_A1_BATCH_DOES_NOT_DELETE_THE_ORDERS_UPLOADED_INSIDE_A1_BATCH',
                })}
              </p>
            </span>
          }
          messagePrimary={intl.formatMessage({id: 'CONFIRM'})}
          messageSecondary={intl.formatMessage({id: 'CANCEL'})}
          handlePrimaryAction={handleRemoveA1Batch}
          handleSecondaryAction={() => setShowModal((prev) => ({...prev, removeBatch: false}))}
          handleClose={() => setShowModal((prev) => ({...prev, removeBatch: false}))}
          loadingPrimary={loading.removeBatch}
        />
      )}
      {showModal.journeyInfo && (
        <JourneyInformationModal
          show={showModal.journeyInfo}
          handleClose={() => setShowModal((prev) => ({...prev, journeyInfo: false}))}
          handleSuccess={handleUpdateJourneySuccess}
        />
      )}
      {showModal.createAllLabel && (
        <CreateAllLabelsModal
          show={showModal.createAllLabel}
          orderIds={dataModal.createAllLabel}
          handleClose={() => handleCloseCreateAllModal()}
        />
      )}
      {showModal.editOrderInfo && (
        <EditOrderInfo
          show={showModal.editOrderInfo}
          data={dataModal.editOrderInfo}
          handleClose={handleCloseEditOrderInfo}
          handleSuccess={handleUpdateOrderInfoSuccess}
        />
      )}
      <PageTitle>{intl.formatMessage({id: 'UPLOAD_A1'})}</PageTitle>
      <div className={clsx({'cursor-no-drop': loading.getA1Batch})}>
        <div className={clsx({'pe-none opacity-75': loading.getA1Batch})}>
          <div className='d-flex flex-wrap justify-content-between mb-4'>
            <div className='d-flex'>
              <ButtonBack className={'bg-page'} event={() => navigate('/a1-batches?status=open')} />
              {a1Batch?.created_at && (
                <div className='d-flex'>
                  <div className='fw-bolder'>
                    <span className='fs-3'>{a1Batch?.name}</span>
                    <div className='fs-6 mt-2 fw-bold'>
                      <span className='me-1'>
                        {convertUserTimeZone(
                          a1Batch.created_at,
                          currentUser,
                          process.env.REACT_APP_DATE_FORMAT
                        )}
                      </span>{' '}
                      <span className='text-gray-600'>
                        {convertUserTimeZone(
                          a1Batch.created_at,
                          currentUser,
                          process.env.REACT_APP_TIME_FORMAT
                        )}
                      </span>
                    </div>
                  </div>
                  {detailMenu()}
                </div>
              )}
            </div>
            {a1Batch?.journey_status === A1_CONSTANTS.OPTIONS_JOURNEY_STATUS.N_A.value[0] && (
              <>
                {!a1Batch.readied ? (
                  <Button
                    className='btn btn-primary'
                    label={intl.formatMessage({id: 'UPDATE_BATCH_STATUS'})}
                    event={() => setShowModal((prev) => ({...prev, journeyInfo: true}))}
                  />
                ) : (
                  <Button
                    className='btn btn-light-primary'
                    label={intl.formatMessage({id: 'CREATE_ALL_LABELS'})}
                    event={handleCreateAllLabels}
                  />
                )}
              </>
            )}
            {a1Batch?.journey_status !== A1_CONSTANTS.OPTIONS_JOURNEY_STATUS.N_A.value[0] && (
              <Button
                className={clsx('btn btn-sm btn-light-blue border border-blue', {
                  disabled: isEmpty(orderIds.printAllLabel),
                })}
                label={intl.formatMessage({id: 'PRINT_ALL_LABELS'})}
                loadingText={intl.formatMessage({id: 'PRINT_ALL_LABELS'})}
                loading={loading.printAllLabel}
                event={handlePrintAllLabels}
              />
            )}
          </div>
          <div className='d-flex flex-wrap mb-10'>
            <div className='col-12 col-xxl-6'>
              <JourneyInfoCard
                handleEditJourneyInfo={() => setShowModal((prev) => ({...prev, journeyInfo: true}))}
              />
            </div>
            <div className='col-12 col-xxl-6'>
              <JourneyStatusCard />
            </div>
          </div>
          <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
            <TableWrapper>
              <A1BatchesDetailFilter
                selectedList={{
                  selected: selectedListId,
                  selectedMaster: selectedMaster,
                  total: pagination?.total,
                }}
                statistics={statistics}
                reloadTable={() => {
                  setMeterChange(true)
                  setTable()
                  getA1Batch()
                }}
              />
              <TableBody>
                <Table
                  columns={columns}
                  data={tableData}
                  pagination={pagination}
                  tbodyClass='text-gray-600 fw-bold'
                  usePagination
                  useCheckBox
                  callBackSetData={(selected) => {
                    setSelectedMaster(selected?.length === pagination?.page_size)
                    setSelectedListId(selected)
                  }}
                />
              </TableBody>
              {isFeatureEnabled(FEATURES.HELP_CENTER_WIDGET) && (
                <HelpCenterWidget data={HELP_CENTER_WIDGET_PARAMS.BATCHES} />
              )}
            </TableWrapper>
          </CSSTransition>
        </div>
      </div>
    </>
  )
}

export {A1BatchesDetailPage}
