import clsx from 'clsx'
import {cloneDeep, compact, get, isEmpty, isEqual, map, uniqBy, uniqueId} from 'lodash'
import moment from 'moment'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {useIntl} from 'react-intl'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {CSSTransition} from 'react-transition-group'
import {
  CUSTOM_COLUMN_NAME,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  TABLE_KEY_COLUMN,
} from '../../../../_gori/constants'
import {FEATURES} from '../../../../_gori/constants/_features'
import {
  KTSVG,
  convertUserTimeZone,
  getShipmentFees,
  isFeatureEnabled,
  setLoadingActionTable,
  useDisabled,
  formatOmitLongText,
} from '../../../../_gori/helpers'
import {blockPopUp} from '../../../../_gori/helpers/BrowserHelpers'
import {FilterOrderSkeleton, TableSkeleton} from '../../../../_gori/helpers/components'
import {useMeter} from '../../../../_gori/helpers/components/MeterProvider'
import {useStatesGlobal} from '../../../../_gori/helpers/components/StatesGlobalProvider'
import {TooltipModal} from '../../../../_gori/helpers/components/TooltipModal'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import UseRoutesPermission from '../../../../_gori/hooks/UseRoutesPermission'
import useWindowDimensions from '../../../../_gori/hooks/useWindowDimensions'
import {
  Button,
  CarrierBadge,
  ConfirmActionSwal,
  DropdownButton,
  Table,
  TableBody,
  TableTabs,
  TableWrapper,
  ValidationErrorModal,
} from '../../../../_gori/partials/widgets'
import {ParcelBadge} from '../../../../_gori/partials/widgets/mixed/ParcelBadge'
import {showArrCustomColumns} from '../../../../_gori/utils/common'
import HttpStatusCode from '../../../api/HttpStatusCode'
import {useAuth} from '../../auth'
import {HelpCenterWidget} from '../../common/components/HelpCenterWidget'
import {
  FromToEditModal,
  NoticePrintPackingSlips,
  OrderChildModal,
  OrderFilter,
  PackagesTableModal,
  RatesModal,
  StoreMenu,
  TutorialCards,
  TrackingDetailModal,
} from '../../orders'
import SettingsService from '../../settings/core/_requests'
import {ShipmentRefund} from '../../shipments/core/_const'
import ShipmentService from '../../shipments/core/_requests'
import {HELP_CENTER_WIDGET_PARAMS, OrderStatus, OrderType, OrdersConfig} from '../core/_const'
import OrderHelper from '../core/_helpers'
import OrderService from '../core/_requests'
import {HazmatModal} from '../../dashboard'

export const AddressView = ({
  data,
  prefix = null,
  classNames = null,
}: {
  data: any
  prefix?: string | null
  classNames?: string | null
}) => {
  const company = get(data, prefix ? `${prefix}_company` : 'company')
  const firstName = get(data, prefix ? `${prefix}_first_name` : 'first_name', '')
  const lastName = get(data, prefix ? `${prefix}_last_name` : 'last_name', '')
  const fullName = compact([firstName, lastName]).join(' ')
  const city = get(data, prefix ? `${prefix}_city` : 'city', '')
  const state = get(data, prefix ? `${prefix}_state` : 'state', '')
  const location = compact([city, state]).join(', ')

  return (
    <span>
      <p className={clsx('m-0', classNames)}>{isEmpty(company) ? fullName : company}</p>
      <p className='m-0'>{location}</p>
    </span>
  )
}

export const TooltipAddress = ({
  row,
  dataLength,
  handleEdit,
}: {
  row: any
  dataLength: number
  handleEdit: () => void
}) => {
  const intl = useIntl()
  const isLastRow = row.index === dataLength - 1

  return (
    <TooltipModal
      className={clsx('d-flex flex-column', {
        'tooltip-modal--top': isLastRow,
        'tooltip-modal--bottom': !isLastRow,
      })}
      classNameTooltip={clsx('text-dark text-hover-primary', {
        'tooltip-modal__text--top': isLastRow,
        'tooltip-modal__text--bottom': !isLastRow,
      })}
      children={<AddressView classNames='text-gray-800' data={row.original} prefix='to' />}
      textTooltip={intl.formatMessage({id: 'EDIT_ADDRESS'})}
      handleEvent={handleEdit}
    />
  )
}

const OrderPage: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {height, width} = useWindowDimensions()
  const {routes} = UseRoutesPermission()
  const {setPageDisabled} = useDisabled()
  const {loadingSwitch, currentUser} = useAuth()
  const {statesGlobal, setHazmat} = useStatesGlobal()
  const {setMeterChange} = useMeter()
  const [searchParams, setSearchParams] = useSearchParams()
  const orderHelper = OrderHelper()
  const carriers = statesGlobal.carriers
  const columnsShow =
    statesGlobal?.manageColumns?.[
      CUSTOM_COLUMN_NAME?.ORDER_PAGE?.[searchParams.get('status')?.toUpperCase() || 'ALL']
    ]
  const {newCancelToken, isCancel} = useCancelToken()
  const [loadingSync, setLoadingSync] = useState(false)
  const [loadingFirst, setLoadingFirst] = useState<Boolean>(true)
  const [selectedListId, setSelectedListId] = useState<any>([])
  const [selectedMaster, setSelectedMaster] = useState<boolean>(false)
  const [integrationStores, setIntegrationStores] = useState<any>([])
  const [tableData, setTableData] = useState<any>([])
  const [pagination, setPagination] = useState<any>({})
  const [statistics, setStatistics] = useState([])
  const [validationErrors, setValidationErrors] = useState<any>()
  const [settings, setSettings] = useState<any>([])
  const [showModal, setShowModal] = useState<{
    confirmActionSwal: 'RESTORE' | 'REMOVE_HOLD' | undefined
    fromToEdit: boolean
    packageTable: boolean
    rateLabel: boolean
    createLabel: boolean
    outdated: boolean
    noticePrintPackingSlip: boolean
    trackingDetail: boolean
  }>({
    confirmActionSwal: undefined,
    fromToEdit: false,
    packageTable: false,
    rateLabel: false,
    createLabel: false,
    outdated: false,
    noticePrintPackingSlip: false,
    trackingDetail: false,
  })

  const [dataModal, setDataModal] = useState<any>({
    confirmActionSwal: null,
    fromToEdit: {},
    packageTable: {},
    rateLabel: {},
    createLabel: {},
    editOrder: {},
    noticePrintPackingSlip: {},
    trackingDetail: {},
  })

  const initFilterValue = useMemo(
    () => ({
      start: moment().subtract(29, 'days').startOf('day').format(),
      end: moment().endOf('day').format(),
    }),
    []
  )

  const setTable = useCallback(async () => {
    setPageDisabled(true)
    let package_type: any = []
    let package_id: any = []
    const _packageInfo = searchParams.get('package_info') || null
    if (_packageInfo) {
      _packageInfo.split(',').forEach((packageItem) => {
        const convertPackage = Number.parseInt(packageItem)
        if (isEqual(convertPackage, NaN)) {
          package_type.push(packageItem)
        } else {
          package_id.push(packageItem)
        }
      })
    }

    const config = {
      params: {
        page: searchParams.get('page') || DEFAULT_PAGE,
        page_size: searchParams.get('page_size') || DEFAULT_PAGE_SIZE,
        search_text: searchParams.get('search_text') || null,
        sort_by: searchParams.get('sort_by') || null,
        sort_direction: searchParams.get('sort_direction') || null,
        status: searchParams.get('status') || null,
        batch_id: searchParams.get('batch_id') || null,
        store_type: searchParams.get('store_type') || null,
        store_id: searchParams.get('store_id') || null,
        // BEGIN: Order filters
        date_from: searchParams.get('date_from') || initFilterValue.start,
        date_to: searchParams.get('date_to') || initFilterValue.end,
        batch: searchParams.get('batch') || null,
        a1_batch: searchParams.get('a1_batch') || null,
        ship_by_date_from: searchParams.get('ship_by_date_from') || null,
        ship_by_date_to: searchParams.get('ship_by_date_to') || null,
        customer: searchParams.get('customer') || null,
        order_ref: searchParams.get('order_ref') || null,
        item_sku: searchParams.get('item_sku') || null,
        item_name: searchParams.get('item_name') || null,
        service: searchParams.get('service') || null,
        preset_id: searchParams.get('preset_id') || null,
        assigned_id: searchParams.get('assigned_id') || null,
        package_type: package_type?.join(',') || null,
        package_id: package_id?.join(',') || null,
        tag_id: searchParams.get('tag_id') || null,
        // END: Order filters
      },
      cancelToken: newCancelToken(),
    }

    await OrderService.search(config)
      .then((response: any) => {
        let newOrder = Array.from(response.orders, (order: any) => {
          return {
            ...order,
            canCheck: true,
          }
        })
        setTableData(newOrder)
        setPagination(response.pagination)
        setStatistics(response.statistics)
      })
      .catch((error) => {
        if (isCancel(error)) return
      })
      .finally(() => {
        setPageDisabled(false)
        setLoadingFirst(false)
      })
  }, [initFilterValue, searchParams, setPageDisabled, isCancel, newCancelToken])

  const getIntegrationStores = useCallback(async () => {
    setPageDisabled(true)

    try {
      const response = await SettingsService.connections({cancelToken: newCancelToken()})
      if (response.connections?.shopify?.length > 0 && !showModal.outdated) {
        response.connections.shopify.forEach((store) => {
          if (store.outdated) {
            setShowModal((prev) => ({...prev, outdated: store.outdated}))
          }
        })
      }
      setIntegrationStores(response.connections)
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setPageDisabled(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancel, newCancelToken, setPageDisabled])

  const getSettings = useCallback(async () => {
    const settings = await SettingsService.settings({cancelToken: newCancelToken()})
    setSettings(settings)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCancelToken])

  useEffect(() => {
    if (loadingSwitch) return

    setTable()

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSwitch, setTable])

  useEffect(() => {
    if (loadingSwitch) return

    getIntegrationStores()

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSwitch, getIntegrationStores])

  useEffect(() => {
    if (loadingSwitch) return

    getSettings()

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSwitch, getSettings])

  const handleRemoveHoldOrder = useCallback(
    async (orderId: any) => {
      setTableData(setLoadingActionTable(tableData, [orderId], true))

      let payload = {
        order_ids: [orderId],
      }

      try {
        const res = await OrderService.removeHold(payload, {cancelToken: newCancelToken()})
        if (res) {
          toast.success(intl.formatMessage({id: 'REMOVED_HOLD_ORDER_SUCCESSFULLY'}))
          setTable()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setTableData(setLoadingActionTable(tableData, [orderId], false))
      }
    },
    [intl, isCancel, newCancelToken, setTable, tableData]
  )

  const handleRestoreOrder = useCallback(
    async (orderId: any) => {
      setTableData(setLoadingActionTable(tableData, [orderId], true))
      let payload = {
        order_ids: [orderId],
      }

      try {
        const res = await OrderService.restore(payload, {cancelToken: newCancelToken()})
        if (res) {
          toast.success(intl.formatMessage({id: 'RESTORED_ORDER_SUCCESSFULLY'}))
          setTable()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setTableData(setLoadingActionTable(tableData, [orderId], false))
      }
    },
    [intl, isCancel, newCancelToken, setTable, tableData]
  )

  const handleViewOrder = useCallback(
    (orderId: any) => {
      navigate('/orders/' + orderId)
    },
    [navigate]
  )

  const handleEditModalRate = useCallback((data) => {
    const _valueOrder = Object.entries(cloneDeep(data)).reduce(
      (initValue, [key, value]: [any, any]) => {
        switch (true) {
          case key.includes('international_option'):
            initValue.international_option = value
            break
          case key.includes('insurance_option'):
            initValue.insurance_option = value
            break
          case key.includes('signature_confirmation'):
            initValue.signature_confirmation = value
            break
          case key.includes('from_'):
            initValue.from[key] = value
            break
          case key.includes('to_'):
            initValue.to[key] = value
            break
          case key === 'parcels':
            initValue.parcels = value[0]
            break
          case key === 'service':
            initValue.service = `${data.carrier}_${data.service}`
            break
          case key === 'id':
            initValue.id = value
            break
          case key === 'preset_id':
            initValue.preset_id = data.preset_id
            break
          default:
            break
        }

        return initValue
      },
      {
        international_option: {},
        insurance_option: {},
        signature_confirmation: null,
        from: {},
        to: {},
        parcels: {},
        service: '',
        id: '',
        preset_id: null,
      }
    )
    setDataModal((prev) => ({...prev, rateLabel: _valueOrder}))
    setShowModal((prev) => ({...prev, rateLabel: true}))
  }, [])

  const handlePrintLabel = useCallback(
    async (dataOrder: any) => {
      const shipment = dataOrder?.latest_shipment
      const isRefunded =
        shipment?.refund?.status === ShipmentRefund.SUBMITTED ||
        shipment?.refund?.status === ShipmentRefund.REFUNDED
      if (isRefunded) {
        toast.warning(
          intl.formatMessage({
            id: 'PRINT_LABEL_REFUNDED',
          })
        )
        return
      }
      setTableData(setLoadingActionTable(tableData, [dataOrder.id], true))
      const config = {cancelToken: newCancelToken()}
      const data = {
        order_ids: [dataOrder.id],
      }
      await OrderService.print(data, config)
        .then((response) => {
          if (blockPopUp(response.label_url)) {
            toast.warning(
              intl.formatMessage({
                id: 'POP_UP_IS_BLOCKED',
              })
            )
          }
        })
        .catch((error: any) => {
          if (isCancel(error)) return
          setValidationErrors(error?.response)
        })
      setTableData(setLoadingActionTable(tableData, [dataOrder.id], false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCancel, newCancelToken, intl, tableData]
  )

  const handlePrintPackingSlip = useCallback(
    async (dataOrder: any) => {
      const {
        packing_slip: {disable},
      } = settings
      if (disable) {
        const configNoticePackingSlip = {
          title: 'PRINT_PACKING_SLIP',
          content: 'DISABLE_PACKING_SLIPS_MESSAGE',
        }
        setDataModal((prev) => ({...prev, noticePrintPackingSlip: configNoticePackingSlip}))
        setShowModal((prev) => ({...prev, noticePrintPackingSlip: true}))
        return
      }

      setTableData(setLoadingActionTable(tableData, [dataOrder.id], true))
      try {
        let payload = {
          order_id: dataOrder.id,
        }
        const res = await ShipmentService.storePackingSlip(payload, {
          cancelToken: newCancelToken(),
        })
        if (res) {
          if (blockPopUp(res.packing_slip_url)) {
            toast.warning(intl.formatMessage({id: 'POP_UP_IS_BLOCKED'}))
          }
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setTableData(setLoadingActionTable(tableData, [dataOrder.id], false))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCancel, newCancelToken, intl, tableData]
  )

  const handleCopy = useCallback(
    async (id) => {
      if (!id) return
      setTableData(setLoadingActionTable(tableData, [id], true))
      try {
        const {order} = await OrderService.get(id, {cancelToken: newCancelToken()})
        if (order) {
          navigate('/orders/create', {state: {dataCopy: {...order, id: null}}})
        }
      } catch (error) {
        if (isCancel(error)) return
      } finally {
        setTableData(setLoadingActionTable(tableData, [id], false))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCancel, navigate, newCancelToken, tableData]
  )

  const handleGetRate = useCallback(
    async (data) => {
      const dataParcels = orderHelper.buildRateParcels(
        `${data.carrier}_${data.service}`,
        data.parcels
      )
      try {
        setTableData(setLoadingActionTable(tableData, [data.id], true, 'isLoadingRate'))
        const res = await OrderService.estimateRate(
          {
            order_id: data?.id,
            shipment: {
              carrier: data?.carrier ?? null,
              service: data?.service ?? null,
              from_address: Object.entries(OrdersConfig.SHIPPING).reduce(
                (fromData, [key, value]) => {
                  fromData[value.value] = data?.[`from_${value.value}`]
                  return fromData
                },
                {}
              ),
              to_address: Object.entries(OrdersConfig.SHIPPING).reduce((toData, [key, value]) => {
                toData[value.value] = data?.[`to_${value.value}`]
                return toData
              }, {}),
              parcel: dataParcels,
            },
          },
          {cancelToken: newCancelToken()}
        )
        if (res) {
          setTable()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setTableData(setLoadingActionTable(tableData, [data.id], false, 'isLoadingRate'))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCancel, newCancelToken, orderHelper, setTable, tableData]
  )

  const columns = useMemo(() => {
    let colShipDate: any = {
      id: 'shipped_date',
      Header: intl.formatMessage({id: 'SHIPPED_DATE'}),
      accessor: 'shipped_date',
      headerClassName: 'min-w-125px',
      cellClassName: 'text-wrap',
      isVisible: false,
      Cell: ({row}: {row: any}) => {
        return (
          !isEmpty(row.original.shipped_date) &&
          convertUserTimeZone(
            row.original.shipped_date,
            currentUser,
            process.env.REACT_APP_DATE_FORMAT
          )
        )
      },
    }
    let colHoldByDate: any = {
      id: 'hold_until_date',
      Header: intl.formatMessage({id: 'HOLD_BY_DATE'}),
      accessor: 'hold_until_date',
      headerClassName: 'min-w-150px',
      cellClassName: 'text-wrap',
      isVisible: false,
      Cell: ({row}: {row: any}) => {
        return (
          !isEmpty(row.original?.hold_until_date) &&
          convertUserTimeZone(
            row.original?.hold_until_date,
            currentUser,
            process.env.REACT_APP_DATE_FORMAT
          )
        )
      },
    }
    let colCancelledDate: any = {
      id: 'cancelled_at',
      Header: intl.formatMessage({id: 'CANCELLED_DATE'}),
      accessor: 'cancelled_at',
      headerClassName: 'min-w-150px',
      cellClassName: 'text-wrap',
      Cell: ({row}: {row: any}) => {
        return (
          !isEmpty(row.original?.cancelled_at) &&
          convertUserTimeZone(
            row.original?.cancelled_at,
            currentUser,
            process.env.REACT_APP_DATE_FORMAT
          )
        )
      },
    }
    let colShipBy: any = {
      id: 'ship_by_date',
      Header: intl.formatMessage({id: 'SHIP_BY'}),
      accessor: 'ship_by_date',
      headerClassName: 'min-w-100px',
      cellClassName: 'text-wrap',
      Cell: ({row}: {row: any}) => {
        return (
          !isEmpty(row.original?.ship_by_date) &&
          convertUserTimeZone(
            row.original?.ship_by_date,
            currentUser,
            process.env.REACT_APP_DATE_FORMAT
          )
        )
      },
    }
    let colStatus: any = {
      id: 'status',
      Header: intl.formatMessage({id: 'STATUS'}),
      accessor: 'status',
      headerClassName: 'min-w-175px text-center',
      cellClassName: 'text-center',
      Cell: ({row}: {row: any}) => {
        return (
          <span
            className={`order-status-badge-${row.original?.status?.replaceAll(
              '_',
              '-'
            )} px-4 py-2 rounded-pill`}
          >
            {intl.formatMessage({id: row.original.status.toUpperCase()})}
          </span>
        )
      },
    }
    let colA1: any = {
      id: 'a1_batch',
      Header: 'A1',
      headerClassName: 'min-w-100px text-center',
      cellClassName: 'text-center',
      Cell: ({row}: {row: any}) => {
        return (
          !isEmpty(row.original.a1_batch) && (
            <KTSVG path='media/gori/a1/logo_order.svg' className='text-primary svg-icon-1' />
          )
        )
      },
    }

    switch (searchParams.get('status')) {
      case 'open':
      case 'awaiting_payment':
        colShipDate = null
        colHoldByDate = null
        colCancelledDate = null
        colStatus = null
        break
      case 'shipped':
        colHoldByDate = null
        colCancelledDate = null
        colShipBy = null
        colStatus = null
        break
      case 'on_hold':
        colShipDate = null
        colCancelledDate = null
        colShipBy = null
        colStatus = null
        break
      case 'cancelled':
        colShipDate = null
        colHoldByDate = null
        colShipBy = null
        colStatus = null
        break
    }

    if (!currentUser?.settings.a1_user) {
      colA1 = null
    }

    const _columns = [
      {
        id: TABLE_KEY_COLUMN.CAN_CHECK,
        Header: intl.formatMessage({id: 'ID'}),
        accessor: 'canCheck',
      },
      {
        id: 'created_at',
        Header: intl.formatMessage({id: 'ORDER_DATE'}),
        accessor: 'created_at',
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          return convertUserTimeZone(
            row.original?.created_at,
            currentUser,
            process.env.REACT_APP_DATE_FORMAT
          )
        },
      },
      {
        id: 'code',
        Header: 'BAE ID',
        accessor: 'code',
        headerClassName: 'min-w-150px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          return (
            <div
              className={clsx(
                'text-gray-800 mb-1 d-inline',
                routes.ORDERS_UPDATE.hasPermission || routes.ORDERS_VIEW.hasPermission
                  ? 'text-hover-primary cursor-pointer'
                  : 'cursor-no-drop'
              )}
              onClick={() =>
                routes.ORDERS_UPDATE.hasPermission || routes.ORDERS_VIEW.hasPermission
                  ? handleViewOrder(row.original.id)
                  : false
              }
            >
              {row.original.code}
              <span className='m-1 rounded-pill badge badge-pill badge-light'>
                {row.original.children.length > 0 && intl.formatMessage({id: 'COMBINED'})}
                {!isEmpty(row.original.split_from) &&
                  row.original.children.length === 0 &&
                  intl.formatMessage({id: 'SPLIT'})}
              </span>
            </div>
          )
        },
      },
      colShipDate,
      colHoldByDate,
      colCancelledDate,
      colShipBy,
      colA1,
      {
        id: 'batch',
        Header: intl.formatMessage({id: 'BATCH'}),
        accessor: 'batch',
        headerClassName: 'min-w-125px w-250px',
        Cell: ({row}: {row: any}) => {
          return !isEmpty(row.original.a1_batch) && currentUser?.settings.a1_user ? (
            <div
              className={clsx(
                'text-gray-800 mb-1 p-4 bg-light-info text-hover-primary cursor-pointer'
              )}
              onClick={() =>
                navigate(
                  `/a1-batches/${row.original.a1_batch.id}${
                    searchParams.get('status') ? `?status=${searchParams.get('status')}` : ''
                  }`
                )
              }
            >
              {row.original.a1_batch?.name}
            </div>
          ) : (
            <div
              className={clsx('text-gray-800 mb-1 p-4', {
                'text-hover-primary cursor-pointer':
                  routes.BATCHES_VIEW.hasPermission && row.original?.batches?.[0]?.id,
                'cursor-no-drop': !routes.BATCHES_VIEW.hasPermission,
              })}
              onClick={() =>
                routes.BATCHES_VIEW.hasPermission &&
                row.original?.batches?.[0]?.id &&
                navigate(
                  `/batches/detail/${row.original.batches[0]?.id}${
                    searchParams.get('status') ? `?status=${searchParams.get('status')}` : ''
                  }`
                )
              }
            >
              {row.original.batches[0]?.name}
            </div>
          )
        },
      },
      {
        id: 'customer',
        Header: intl.formatMessage({id: 'CUSTOMER'}),
        accessor: 'customer',
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap mw-150px',
        Cell: (data) => {
          const row = data.row
          const isTooltipVisible =
            row.original.status === OrderStatus.OPEN &&
            !row.original?.a1_batch_id &&
            routes.ORDERS_UPDATE.hasPermission

          const handleEdit = () => {
            setDataModal((prev: any) => ({...prev, fromToEdit: row.original}))
            setShowModal((prev: any) => ({...prev, fromToEdit: true}))
          }

          return isTooltipVisible ? (
            <TooltipAddress row={row} dataLength={data.rows.length} handleEdit={handleEdit} />
          ) : (
            <>
              {row.original?.latest_shipment ? (
                <AddressView
                  classNames='text-gray-800'
                  data={row.original.latest_shipment}
                  prefix='to'
                />
              ) : (
                <AddressView classNames='text-gray-800' data={row.original} prefix='to' />
              )}
            </>
          )
        },
      },
      colStatus,
      {
        id: 'package_info',
        Header: intl.formatMessage({id: 'PACKAGE_INFO'}),
        headerClassName: 'min-w-100px text-center',
        cellClassName: 'text-center',
        Cell: (data) => {
          const row = data.row
          return row.original.status === OrderStatus.OPEN &&
            !row.original?.a1_batch_id &&
            routes.ORDERS_UPDATE.hasPermission ? (
            <TooltipModal
              className={clsx({
                'tooltip-modal--top': data.rows.length - 1 === row.index,
                'tooltip-modal--bottom': data.rows.length - 1 !== row.index,
              })}
              classNameTooltip={clsx('text-dark text-hover-primary w-150px', {
                'tooltip-modal__text--top': data.rows.length - 1 === row.index,
                'tooltip-modal__text--bottom': data.rows.length - 1 !== row.index,
              })}
              children={
                <>
                  {row.original.parcels.length > 0
                    ? row.original.parcels.map((parcel: any) => (
                        <ParcelBadge key={uniqueId('parcel')} values={parcel} />
                      ))
                    : row.original.status === OrderStatus.OPEN && (
                        <div className='flex-shrink-0'>
                          <Button className='btn border-active btn-white btn-sm text-gray-700'>
                            <i className='fas fa-plus' />
                            {intl.formatMessage({id: 'ADD_PACKAGE_INFO'})}
                          </Button>
                        </div>
                      )}
                </>
              }
              textTooltip={intl.formatMessage({id: 'EDIT_PACKAGE_INFO'})}
              handleEvent={() => {
                setDataModal((prev) => ({...prev, packageTable: row.original}))
                setShowModal((prev) => ({...prev, packageTable: true}))
              }}
            />
          ) : (
            <>
              {row.original?.latest_shipment
                ? row.original?.latest_shipment?.parcels.map((parcel: any) => (
                    <ParcelBadge key={uniqueId('parcel')} values={parcel} />
                  ))
                : row.original.parcels.length > 0
                ? row.original.parcels.map((parcel: any) => (
                    <ParcelBadge key={uniqueId('parcel')} values={parcel} />
                  ))
                : row.original.status === OrderStatus.OPEN &&
                  routes.ORDERS_UPDATE.hasPermission && (
                    <div className='flex-shrink-0'>
                      <Button className='btn border-active btn-white btn-sm text-gray-700'>
                        <i className='fas fa-plus' />
                        {intl.formatMessage({id: 'ADD_PACKAGE_INFO'})}
                      </Button>
                    </div>
                  )}
            </>
          )
        },
      },
      {
        id: 'total_weight',
        Header: intl.formatMessage({id: 'WEIGHT'}),
        headerClassName: 'min-w-100px text-center',
        accessor: 'total_weight',
        cellClassName: 'text-center',
        Cell: (data) => {
          const row = data.row
          return (
            <>
              {row.original?.latest_shipment
                ? row.original?.latest_shipment?.parcels.map((parcel: any) => (
                    <span>{parcel.weight + ' ' + parcel.weight_unit}</span>
                  ))
                : row.original.parcels.length > 0
                ? row.original.parcels.map((parcel: any) => (
                    <span>{parcel.weight + ' ' + parcel.weight_unit}</span>
                  ))
                : ''}
            </>
          )
        },
      },
      {
        id: 'rates',
        Header: intl.formatMessage({id: 'RATES'}),
        headerClassName: 'min-w-50px text-center',
        cellClassName: 'text-center',
        Cell: (data) => {
          const row = data.row
          let sum = 0
          let service = carriers?.[row.original.carrier + '_' + row.original.service] ?? []
          if (row.original?.latest_shipment) {
            sum = getShipmentFees(row.original?.latest_shipment?.fees?.items)
            service =
              carriers?.[
                row.original?.latest_shipment?.carrier +
                  '_' +
                  row.original?.latest_shipment?.service
              ] ?? []
          } else if (row.original?.estimated_rate) {
            sum = row.original?.estimated_rate
          }
          const isCheapest = row.original?.is_cheapest_rate ?? false

          return row.original.status === OrderStatus.OPEN && routes.ORDERS_UPDATE.hasPermission ? (
            row.original.carrier ? (
              <TooltipModal
                className={clsx({
                  'tooltip-modal--top': data.rows.length - 1 === row.index,
                  'tooltip-modal--bottom': data.rows.length - 1 !== row.index,
                })}
                classNameTooltip={clsx('text-dark text-hover-primary w-150px', {
                  'tooltip-modal__text--top': data.rows.length - 1 === row.index,
                  'tooltip-modal__text--bottom': data.rows.length - 1 !== row.index,
                })}
                children={
                  <>
                    <CarrierBadge
                      handleGetRate={() => handleGetRate(row.original)}
                      loadingRate={row.original.isLoadingRate}
                      service={service}
                      isCheapest={isCheapest}
                      {...((row.original?.latest_shipment || row.original?.estimated_rate) && {
                        cost: sum,
                      })}
                    />
                  </>
                }
                textTooltip={intl.formatMessage({id: 'EDIT_RATE'})}
                handleEvent={() => {
                  handleEditModalRate(row.original)
                }}
              />
            ) : (
              <div className='flex-shrink-0'>
                <Button
                  className='btn border-active btn-white btn-sm text-dark text-hover-primary'
                  event={() => {
                    handleEditModalRate(row.original)
                  }}
                >
                  <i className='fas fa-sync' />
                  {intl.formatMessage({id: 'GET_RATES'})}
                </Button>
              </div>
            )
          ) : (
            <>
              {row.original.carrier && (
                <CarrierBadge
                  service={service}
                  isCheapest={isCheapest}
                  {...((row.original?.latest_shipment || row.original?.estimated_rate) && {
                    cost: sum,
                  })}
                />
              )}
            </>
          )
        },
      },
      {
        id: 'out_for_delivery',
        Header: intl.formatMessage({id: 'OUT_FOR_DELIVERY'}),
        headerClassName: 'min-w-150px text-center',
        cellClassName: 'text-center',
        Cell: (data) => {
          const row = data.row
          const ShipmentTrackingStatus = row.original?.latest_shipment?.status_label ?? null
          const ShipmentTracking = row.original?.latest_shipment?.shipment_tracking ?? null
          return (
            <div>
              {ShipmentTrackingStatus ? (
                <div className='justify-content-center'>
                  <span className='fw-bolder'>{ShipmentTrackingStatus}</span>
                  <br />
                  {ShipmentTracking && (
                    <span
                      className='cursor-pointer text-decoration-underline text-muted mt-4'
                      onClick={() => {
                        setShowModal((prev) => ({...prev, trackingDetail: true}))
                        setDataModal((prev) => ({...prev, trackingDetail: ShipmentTracking}))
                      }}
                    >
                      {intl.formatMessage({id: 'SEE_DETAIL'})}
                      {'>'}
                    </span>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          )
        },
      },
      {
        id: 'tracking_number',
        Header: intl.formatMessage({id: 'TRACKING_NUMBER'}),
        headerClassName: 'min-w-100px text-center',
        cellClassName: 'text-center',
        Cell: (data) => {
          if (isEmpty(carriers)) return null
          const row = data.row
          let service = carriers[row.original.carrier + '_' + row.original.service] ?? []
          const trackingNumber = row.original?.latest_shipment
            ? row.original?.latest_shipment?.tracking_number
            : null

          return (
            <div>
              {trackingNumber ? (
                <div className='d-flex justify-content-center'>
                  <div className='align-self-center'>
                    <span
                      className='text-primary cursor-pointer'
                      onClick={() => {
                        if (blockPopUp(service?.visuals?.tracking_url + trackingNumber)) {
                          toast.warning(intl.formatMessage({id: 'POP_UP_IS_BLOCKED'}))
                        }
                      }}
                    >
                      {trackingNumber}
                    </span>
                  </div>
                  <CopyToClipboard
                    text={trackingNumber}
                    onCopy={() => toast.success(intl.formatMessage({id: 'COPIED'}))}
                  >
                    <div>
                      <div className='btn btn-active-color-primary btn-icon btn-sm btn-outline-light'>
                        <KTSVG path='/media/gori/account/copy.svg' className='svg-icon' />
                      </div>
                    </div>
                  </CopyToClipboard>
                </div>
              ) : (
                ''
              )}
            </div>
          )
        },
      },
      {
        id: 'reference1',
        Header: intl.formatMessage({id: 'ORDER_REF'}),
        accessor: 'reference1',
        headerClassName: 'text-wrap min-w-125px',
        Cell: ({row}: {row: any}) => {
          return <>{row.original.reference1}</>
        },
      },
      {
        id: 'item_name',
        Header: intl.formatMessage({id: 'ITEM_NAME'}),
        accessor: 'item_name',
        headerClassName: 'text-wrap min-w-125px',
        Cell: ({row}: {row: any}) => {
          const arr = map(row.original.order_items, 'title')
          return (
            <div>
              {arr.map((item, index) => (
                <div key={index}>{formatOmitLongText(item)}</div>
              ))}
            </div>
          )
        },
      },
      {
        id: 'item_sku',
        Header: intl.formatMessage({id: 'ITEM_SKU'}),
        accessor: 'item_sku',
        headerClassName: 'text-wrap min-w-125px',
        Cell: ({row}: {row: any}) => {
          const arr = map(uniqBy(row.original.order_items, 'sku'), 'sku')

          return (
            <div>
              {arr.map((item, index) => (
                <div key={index}>{formatOmitLongText(item)}</div>
              ))}
            </div>
          )
        },
      },
      {
        id: 'assigned_name',
        Header: intl.formatMessage({id: 'ASSIGNED'}),
        accessor: 'assigned_name',
        headerClassName: 'min-w-150px text-center',
        cellClassName: 'text-center',
        Cell: ({row}: {row: any}) => {
          if (!isEmpty(row.original.assigned_name)) {
            return (
              <span className={`  assigned-badge- px-4 py-2 rounded-pill`}>
                {row.original.assigned_name}
              </span>
            )
          } else {
            return <span className={`assigned-badge px-4 py-2 rounded-pill`}></span>
          }
        },
      },
      {
        id: 'tag',
        Header: intl.formatMessage({id: 'TAG'}),
        headerClassName: 'min-w-150px',
        Cell: (data) => {
          const row = data.row
          return (
            <div className='d-flex'>
              {row.original.tags.map((tag, idx) => (
                <TooltipModal
                  className={clsx('me-2', {
                    'tooltip-modal--top': data.rows.length - 1 === row.index,
                    'tooltip-modal--bottom': data.rows.length - 1 !== row.index,
                  })}
                  classNameTooltip={clsx('text-dark mt-2', {
                    'tooltip-modal__text--top': data.rows.length - 1 === row.index,
                    'tooltip-modal__text--bottom': data.rows.length - 1 !== row.index,
                  })}
                  children={
                    <KTSVG
                      key={idx}
                      path='/media/gori/orders/tag.svg'
                      className='svg-icon-2'
                      style={{color: tag.color}}
                    />
                  }
                  textTooltip={tag.name}
                  handleEvent={() => {}}
                />
              ))}
            </div>
          )
        },
      },
      {
        id: 'store_name',
        Header: intl.formatMessage({id: 'STORE'}),
        accessor: 'store_name',
        headerClassName: 'text-wrap min-w-125px',
        Cell: ({row}: {row: any}) => {
          return !isEmpty(row.original?.store?.store_name)
            ? row.original?.store?.store_name
            : intl.formatMessage({id: 'SHIPBAE'})
        },
      },
      {
        id: TABLE_KEY_COLUMN.ACTIONS,
        Header: intl.formatMessage({id: 'ACTIONS'}),
        headerClassName: 'min-w-150px text-center fixed-action',
        cellClassName: 'text-center fixed-action',
        Cell: ({row}: {row: any}) => {
          return (
            <div>
              {
                <>
                  <div
                    className={clsx({
                      'cursor-no-drop': row.original.isLoading,
                    })}
                    id={'group' + row.index}
                  >
                    <Button
                      className={clsx('btn btn-primary btn-sm', {
                        'pe-none': row.original.isLoading,
                      })}
                      event={() => {
                        if (
                          row.original.status === OrderStatus.OPEN &&
                          routes.SHIPMENTS_CREATE.hasPermission
                        ) {
                          setDataModal((prev) => ({...prev, createLabel: row.original.id}))
                          setShowModal((prev) => ({...prev, createLabel: true}))
                        } else if (
                          row.original.status === OrderStatus.CANCELLED &&
                          routes.ORDERS_CANCEL.hasPermission
                        ) {
                          setDataModal((prev) => ({...prev, confirmActionSwal: row.original.id}))
                          setShowModal((prev) => ({...prev, confirmActionSwal: 'RESTORE'}))
                        } else if (
                          row.original.status === OrderStatus.ON_HOLD &&
                          routes.ORDERS_HOLD.hasPermission
                        ) {
                          setDataModal((prev) => ({...prev, confirmActionSwal: row.original.id}))
                          setShowModal((prev) => ({...prev, confirmActionSwal: 'REMOVE_HOLD'}))
                        } else {
                          handleViewOrder(row.original.id)
                        }
                      }}
                    >
                      {row.original.status === OrderStatus.OPEN &&
                      routes.SHIPMENTS_CREATE.hasPermission
                        ? intl.formatMessage({id: 'CREATE_LABEL'})
                        : row.original.status === OrderStatus.CANCELLED &&
                          routes.ORDERS_CANCEL.hasPermission
                        ? intl.formatMessage({id: 'RESTORE'})
                        : row.original.status === OrderStatus.ON_HOLD &&
                          routes.ORDERS_HOLD.hasPermission
                        ? intl.formatMessage({id: 'REMOVE_HOLD'})
                        : intl.formatMessage({id: 'VIEW'})}
                    </Button>
                  </div>
                </>
              }
            </div>
          )
        },
      },
      {
        id: TABLE_KEY_COLUMN.SUB_ACTIONS,
        cellClassName: 'fixed-column',
        Cell: ({row}: {row: any}) => {
          const isEditable = [OrderStatus.OPEN, OrderStatus.ON_HOLD].includes(row.original.status)

          const listDropDownBtn = [
            {
              label: intl.formatMessage({id: 'VIEW_ORDER'}),
              action: () => {
                navigate(`/orders/${row.original.id}`)
              },
              className: 'cursor-pointer',
              hidden: !isEditable || !routes.ORDERS_UPDATE.hasPermission,
            },
            {
              label: intl.formatMessage({id: 'RESHIP'}),
              action: () => {
                setDataModal((prev) => ({...prev, createLabel: row.original.id}))
                setShowModal((prev) => ({...prev, createLabel: true}))
              },
              className: 'cursor-pointer',
              hidden:
                row.original.status !== OrderStatus.SHIPPED ||
                !routes.SHIPMENTS_CREATE.hasPermission,
            },
            {
              label: intl.formatMessage({id: 'PRINT_LABEL'}),
              action: () => {
                handlePrintLabel(row.original)
              },
              className: 'cursor-pointer',
              hidden: row.original.status !== OrderStatus.SHIPPED,
            },
            {
              label: intl.formatMessage({id: 'PRINT_PACKING_SLIP'}),
              action: () => {
                handlePrintPackingSlip(row.original)
              },
              className: 'cursor-pointer',
              hidden:
                !routes.SHIPMENTS_PACKING_SLIP.hasPermission || !isEmpty(row.original?.refund),
            },
            {
              label: intl.formatMessage({id: 'COPY'}),
              action: () => {
                handleCopy(row.original.id)
              },
              className: 'cursor-pointer',
              hidden: !routes.ORDERS_CREATE.hasPermission,
            },
          ]

          return (
            <DropdownButton
              loading={row.original.isLoading}
              list={listDropDownBtn}
              className='d-flex justify-content-center'
            />
          )
        },
      },
    ]
    return _columns.filter((col) => col)
  }, [
    intl,
    searchParams,
    currentUser,
    routes,
    handleViewOrder,
    navigate,
    carriers,
    handleGetRate,
    handleEditModalRate,
    handlePrintLabel,
    handlePrintPackingSlip,
    handleCopy,
  ])

  const customColumns = useMemo(() => {
    if (columnsShow) {
      return showArrCustomColumns(columns, columnsShow)
    } else {
      return cloneDeep(columns)
        .filter((item) => item)
        .map((item: any) => {
          item.show = true
          switch (searchParams.get('status')) {
            case null: //all
              if (
                item.id === 'shipped_date' ||
                item.id === 'hold_until_date' ||
                item.id === 'cancelled_at' ||
                item.id === 'ship_by_date' ||
                item.id === 'item_name' ||
                item.id === 'item_sku'
              ) {
                item.show = false
              }
              break
            case 'open':
            case 'awaiting_payment':
              if (
                item.id === 'shipped_date' ||
                item.id === 'hold_until_date' ||
                item.id === 'cancelled_at' ||
                item.id === 'status' ||
                item.id === 'item_name' ||
                item.id === 'item_sku'
              ) {
                item.show = false
              }
              break
            case 'shipped':
              if (
                item.id === 'hold_until_date' ||
                item.id === 'cancelled_at' ||
                item.id === 'ship_by_date' ||
                item.id === 'status' ||
                item.id === 'item_name' ||
                item.id === 'item_sku'
              ) {
                item.show = false
              }
              break
            case 'on_hold':
              if (
                item.id === 'shipped_date' ||
                item.id === 'cancelled_at' ||
                item.id === 'ship_by_date' ||
                item.id === 'status' ||
                item.id === 'item_name' ||
                item.id === 'item_sku'
              ) {
                item.show = false
              }
              break
            case 'cancelled':
              if (
                item.id === 'shipped_date' ||
                item.id === 'hold_until_date' ||
                item.id === 'ship_by_date' ||
                item.id === 'status' ||
                item.id === 'item_name' ||
                item.id === 'item_sku'
              ) {
                item.show = false
              }
              break
          }
          return item
        })
    }
  }, [columns, columnsShow, searchParams])

  const handleFilterStore = useCallback(
    (data: any) => {
      searchParams.delete('batch_id')
      searchParams.delete('date_from')
      searchParams.delete('date_to')
      searchParams.set('page', DEFAULT_PAGE)
      if (data) {
        searchParams.set('store_type', data.store_type)
        searchParams.set('store_id', data?.id ?? '')
      } else {
        searchParams.delete('store_type')
        searchParams.delete('store_id')
      }
      setSearchParams(searchParams)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams]
  )

  const handleModalSuccess = useCallback(
    (key: 'createLabel' | 'fromToEdit' | 'packageTable' | 'rateLabel') => {
      switch (key) {
        case 'createLabel':
          setMeterChange(true)
          toast.success(intl.formatMessage({id: 'CREATE_LABEL_SUCCESSFULLY'}))
          break
        default:
          toast.success(intl.formatMessage({id: 'UPDATED_SUCCESSFULLY'}))
          break
      }
      setShowModal((prev) => ({...prev, [key]: false}))
      setTable()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl, setMeterChange, setTable]
  )

  const handleSyncOrders = useCallback(async () => {
    try {
      setLoadingSync(true)
      setPageDisabled(true)
      let storeType = searchParams.get('store_type')
      let storeId = searchParams.get('store_id')
      const promises: Promise<any>[] = []
      promises.push(
        OrderService.syncOrders(
          {
            store_id: storeId,
            store_type: storeType,
            status: OrderStatus.OPEN,
          },
          {cancelToken: newCancelToken()}
        )
      )
      if (storeType !== OrderType.SHOPIFY || storeType !== OrderType.EBAY) {
        promises.push(
          OrderService.syncOrders(
            {
              store_id: storeId,
              store_type: storeType,
              status: OrderStatus.CANCELLED,
            },
            {cancelToken: newCancelToken()}
          )
        )
      }
      if (storeType !== OrderType.SHOPIFY || storeType !== OrderType.EBAY) {
        promises.push(
          OrderService.syncOrders(
            {
              store_id: storeId,
              store_type: storeType,
              status: OrderStatus.SHIPPED,
            },
            {cancelToken: newCancelToken()}
          )
        )
      }
      const response = await Promise.all(promises)

      if (response[0]) {
        if (
          searchParams.get('page') !== '1' ||
          searchParams.get('search_text') ||
          searchParams.get('date_from') ||
          searchParams.get('date_to')
        ) {
          searchParams.set('page', DEFAULT_PAGE)
          searchParams.delete('search_text')
          searchParams.delete('date_from')
          searchParams.delete('date_to')
          setSearchParams(searchParams)
        } else {
          setTable()
        }
        toast.success(intl.formatMessage({id: response[0].message}))
      }
    } catch (error: any) {
      if (isCancel(error)) return
      if (error?.response?.status === HttpStatusCode.EXPECTATION_FAILED) {
        const errorMessages = {
          [OrderType.TIKTOK]: 'ACCESS_TOKEN_IS_EXPIRED_PLEASE_REFRESH_IT',
          [OrderType.SHIPSTATION]: 'INVALID_API_KEY',
        }

        const storeType = searchParams.get('store_type')
        const errorMessage = storeType && errorMessages[storeType]

        if (errorMessage) {
          toast.error(intl.formatMessage({id: errorMessage}))
          navigate('/settings/connections')
        }
      } else {
        toast.error(intl.formatMessage({id: 'SYNC_ORDER_FAILED'}))
      }
    } finally {
      setLoadingSync(false)
      setPageDisabled(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, isCancel, newCancelToken, searchParams, setSearchParams, setTable])

  const handleConfirmActionSwal = useCallback(() => {
    switch (showModal.confirmActionSwal) {
      case 'REMOVE_HOLD':
        handleRemoveHoldOrder(dataModal.confirmActionSwal)
        break
      case 'RESTORE':
        handleRestoreOrder(dataModal.confirmActionSwal)
        break
    }
    setDataModal((prev) => ({...prev, confirmActionSwal: null}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  const hasThirdPartyStore = useMemo(() => {
    const storeType = searchParams.get('store_type')
    const storeId = searchParams.get('store_id')
    const isNotShipBae = storeType !== OrderType.SHIPBAE

    return (storeType || storeId) && isNotShipBae
  }, [searchParams])

  return (
    <>
      <ConfirmActionSwal
        show={!!showModal.confirmActionSwal}
        title={intl.formatMessage({id: 'RESTORE'})}
        message={intl.formatMessage({id: 'ARE_YOU_SURE'})}
        messageCancel={intl.formatMessage({id: 'NO'})}
        handleCallBack={handleConfirmActionSwal}
        handleClose={() => setShowModal((prev) => ({...prev, confirmActionSwal: undefined}))}
      />
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}

      {showModal.outdated && routes.ADMINS.hasPermission && (
        <ConfirmActionSwal
          show={showModal.outdated}
          title={intl.formatMessage({id: 'OUTDATED_SHOPIFY_APP'})}
          message={intl.formatMessage({id: 'OUTDATED_SHOPIFY_APP'})}
          messageCancel={intl.formatMessage({id: 'NO'})}
          handleCallBack={() => navigate('/settings/connections/shopify')}
          handleClose={() => setShowModal((prev) => ({...prev, outdated: false}))}
        />
      )}

      {showModal.fromToEdit && (
        <FromToEditModal
          show={showModal.fromToEdit}
          data={dataModal.fromToEdit}
          handleClose={() => setShowModal((prev) => ({...prev, fromToEdit: false}))}
          handleToEditSuccess={() => handleModalSuccess('fromToEdit')}
        />
      )}

      {showModal.packageTable && (
        <PackagesTableModal
          show={showModal.packageTable}
          data={dataModal.packageTable}
          handleClose={() => setShowModal((prev) => ({...prev, packageTable: false}))}
          handlePackagesTableEditSuccess={() => handleModalSuccess('packageTable')}
        />
      )}

      {showModal.rateLabel && (
        <RatesModal
          show={showModal.rateLabel}
          data={dataModal.rateLabel}
          handleClose={() => setShowModal((prev) => ({...prev, rateLabel: false}))}
          handleEditRateLabelSuccess={() => handleModalSuccess('rateLabel')}
        />
      )}

      {showModal.createLabel && (
        <OrderChildModal
          show={showModal.createLabel}
          handleClose={() => {
            setTable()
            setShowModal((prev) => ({...prev, createLabel: false}))
            setDataModal((prev) => ({...prev, createLabel: null}))
          }}
          handleSuccess={(url) => {
            if (blockPopUp(url)) {
              toast.warning(
                intl.formatMessage({
                  id: 'POP_UP_IS_BLOCKED',
                })
              )
            }
            handleModalSuccess('createLabel')
          }}
          parentId={dataModal.createLabel}
        />
      )}

      {showModal.noticePrintPackingSlip && (
        <NoticePrintPackingSlips
          show={showModal.noticePrintPackingSlip}
          handleClose={() => setShowModal((prev) => ({...prev, noticePrintPackingSlip: false}))}
          configModal={dataModal.noticePrintPackingSlip}
        />
      )}

      {showModal.trackingDetail && (
        <TrackingDetailModal
          show={showModal.trackingDetail}
          handleClose={() => setShowModal((prev) => ({...prev, trackingDetail: false}))}
          data={dataModal.trackingDetail}
        />
      )}

      {!routes.SYSTEM_ADMIN.hasPermission && statesGlobal.hazmat && currentUser && (
        <HazmatModal show={statesGlobal.hazmat} handleClose={() => setHazmat(false)} />
      )}

      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        {loadingFirst ? (
          <>
            <div>
              <div className='d-flex justify-content-between mb-4'>
                <div className='col-5 placeholder-wave'>
                  <span className='btn col-3 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
                  <span className='btn col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
                </div>
              </div>
              <div className='bg-white bg-opacity-50 p-5'>
                <div className='col-9 d-flex align-items-center '>
                  {Array.from({length: 4}).map((item, index) => (
                    <div className='col-2 placeholder-wave' key={index}>
                      <span className='col-9 placeholder placeholder-lg rounded-2 bg-secondary' />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <TableWrapper>
              {isFeatureEnabled(FEATURES.HELP_CENTER_WIDGET) && (
                <HelpCenterWidget data={HELP_CENTER_WIDGET_PARAMS.ORDER} />
              )}
              <FilterOrderSkeleton />
              <TableBody>
                <TableSkeleton />
              </TableBody>
            </TableWrapper>
          </>
        ) : (
          <>
            {isFeatureEnabled(FEATURES.TUTORIAL) && <TutorialCards />}
            <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
              <div className='d-flex justify-content-between mb-4'>
                <div className='d-flex flex-wrap gap-3'>
                  {!hasThirdPartyStore && routes.ORDERS_CREATE.hasPermission && (
                    <Link
                      to='/orders/create'
                      className='btn btn-white btn-active-light-primary border'
                    >
                      <KTSVG path='/media/gori/orders/create_order.svg' className='me-2' />
                      {intl.formatMessage({id: 'CREATE_ORDER'})}
                    </Link>
                  )}
                  {routes.BATCHES_VIEW.hasPermission && (
                    <Link to='/batches' className='btn btn-white btn-active-light-primary border'>
                      <KTSVG path='/media/gori/orders/upload_csv.svg' className='me-2 svg-icon-2' />
                      {intl.formatMessage({id: 'UPLOAD_BATCH'})}
                    </Link>
                  )}
                  {hasThirdPartyStore && !isEmpty(searchParams.get('store_id')) && (
                    <Button
                      disabled={loadingSync}
                      className={'btn btn-white btn-active-light-primary border'}
                      event={handleSyncOrders}
                    >
                      <KTSVG
                        path='/media/gori/orders/sync.svg'
                        className='me-2 svg-icon-2'
                        svgClassName={clsx({spin: loadingSync})}
                      />
                      {intl.formatMessage({id: 'SYNC_ORDERS'})}
                    </Button>
                  )}
                </div>
              </div>
            </CSSTransition>
            <TableTabs
              dataTabs={statistics}
              keyCheckActive='status'
              classNameChildTabs={clsx('col-auto', {
                'mb-3 mt-5': !(width >= 1600 || height >= 1600),
              })}
              children={
                <TableWrapper className='rounded-top-left-0-dt'>
                  <OrderFilter
                    selectedList={{
                      selected: selectedListId,
                      selectedMaster: selectedMaster,
                      total: pagination?.total,
                    }}
                    statistics={statistics}
                    reloadTable={() => {
                      setMeterChange(true)
                      setTable()
                    }}
                    dataSettings={settings}
                  />
                  <TableBody className='table-scroll'>
                    <Table
                      columns={customColumns}
                      data={tableData}
                      pagination={pagination}
                      tbodyClass='text-gray-600 fw-bold'
                      usePagination
                      useCheckBox={!isEmpty(tableData)}
                      callBackSetData={(selected) => {
                        setSelectedMaster(selected?.length === pagination?.page_size)
                        setSelectedListId(selected)
                      }}
                      customColumn={{
                        show: true,
                        name: CUSTOM_COLUMN_NAME?.ORDER_PAGE?.[
                          searchParams.get('status')?.toUpperCase() || 'ALL'
                        ],
                      }}
                    />
                  </TableBody>
                  {isFeatureEnabled(FEATURES.HELP_CENTER_WIDGET) && (
                    <HelpCenterWidget data={HELP_CENTER_WIDGET_PARAMS.ORDER} />
                  )}
                </TableWrapper>
              }
              childrenTab={
                isFeatureEnabled(FEATURES.MARKETPLACE) ? (
                  <StoreMenu
                    data={integrationStores}
                    labelButton={intl.formatMessage({id: 'STORES'})}
                    handleSubmit={handleFilterStore}
                  />
                ) : null
              }
            />
          </>
        )}
      </CSSTransition>
    </>
  )
}

export {OrderPage}
