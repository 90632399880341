import {startCase} from 'lodash'
import React from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'

type ErrorItem = {
  row: string | number
  error: string[]
}

type Props = {
  show: boolean
  handleClose: () => void
  errors: Record<string, ErrorItem>
}

const FileErrorModal: React.FC<Props> = ({show, handleClose, errors}) => {
  const intl = useIntl()

  const renderError = (error: string, index: number) => {
    const [input, messageId, max] = error.split('|')

    return (
      <li key={index} className='fw-bolder text-danger'>
        {intl.formatMessage(
          {id: messageId || input},
          {
            input: input ? startCase(input) : '',
            max: max || '',
          }
        )}
      </li>
    )
  }

  const renderErrors = (errorList: string[]) => {
    return errorList?.map((error, index) => renderError(error, index))
  }

  return (
    <Modal
      id='gori_file_modal_error'
      tabIndex={-1}
      aria-hidden='true'
      centered
      dialogClassName='mw-400px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <Modal.Header closeButton>
          <Modal.Title bsPrefix='fw-bolder fs-1'>{intl.formatMessage({id: 'ERRORS'})}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='vh-75 scroll-y bg-light-danger'>
          {errors &&
            Object.values(errors).map((item, index) => (
              <div key={index}>
                <div className='col-12'>
                  {item?.error && (
                    <div className='fw-bolder'>
                      {intl.formatMessage({id: 'ROW'})}: {parseInt(item?.row as string)}
                    </div>
                  )}
                </div>
                <div className='col-auto'>{renderErrors(item?.error || item)}</div>
              </div>
            ))}
        </Modal.Body>
      </div>
    </Modal>
  )
}

export {FileErrorModal}
